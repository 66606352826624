import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getPhotos } from 'helpers/backendHelper'

const initialState = {
    photos: [],
    status: 'idle',
    error: null
}

export const photoGallerySlice = createSlice({
    name: 'photoGallery',
    initialState,
    reducers: {
        photosAdded: {
            reducer(state, action) {
                state.photos.push(action.payload.photos.photo)
            },
            prepare(title, content, userId) {
                // omit prepare logic
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPhotos.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchPhotos.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched photoGallerys to the array
                state.photos = action.payload.photos.photo
            })
            .addCase(fetchPhotos.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

// Action creators are generated for each case reducer function
export const { photosAdded } = photoGallerySlice.actions

export default photoGallerySlice.reducer

export const selectAllPhotos = state => state.photoGallery.photos

export const fetchPhotos = createAsyncThunk('photos/fetchPhotos', getPhotos)