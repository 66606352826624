import React, { useEffect, useState } from "react"
// import { Container, Row, Col } from "reactstrap"
import { Link } from 'react-router-dom';
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

import iconImg1 from '../../assets/footer/f-icon-1.png';
import iconImg2 from '../../assets/footer/f-icon-2.png';
import iconImg3 from '../../assets/footer/f-icon-3.png';
import iconImg4 from '../../assets/footer/f-icon-4.png';
import iconImg5 from '../../assets/footer/f-icon-5.png';
import TwitterContainer from './TwitterContainer';
import footerCss from './footer.module.css';
import { Backdrop, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { postNewsLetterSubscription } from "store/newsLetterSubscriptions/newsLetterSubscriptionsSlice";

const Footer = () => {

  const MySwal = withReactContent(Swal)
  const [submittingData, setSubmittingData] = useState(false);
  const { newsLetterSubscriptions, newsLetterSubscriptionStatus, newsLetterSubscriptionError } = useSelector(state => ({
    newsLetterSubscriptions: state.newsLetterSubscriptions.newsLetterSubscriptions,
    newsLetterSubscriptionStatus: state.newsLetterSubscriptions.status,
    newsLetterSubscriptionError: state.newsLetterSubscriptions.error
  }));
  const [subscriberEmail, setSubscriberEmail] = useState("")
  const [contactName, setContactName] = useState("")
  const [contactEmail, setContactEmail] = useState("")
  const [contactMessage, setContactMessage] = useState("")

  const dispatch = useDispatch()

  const subscribe = () => {
    if (newsLetterSubscriptionStatus === 'idle') {
      if (subscriberEmail.length > 0 && validateEmail(subscriberEmail)) {
        setSubmittingData(true)
        dispatch(postNewsLetterSubscription({
          email: subscriberEmail,
          active: true
        }))
      } else {
        resetStates()
        MySwal.fire({
          title: (<h5>Please Enter a Valid Email Address!</h5>),
          text: '',
          icon: "error",
          timer: 6000,
          customClass: {
          },
        })
      }
    }
  }

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  }

  const resetStates = () => {
    setSubmittingData(false)
    setSubscriberEmail("")
    setContactName("")
    setContactEmail("")
    setContactMessage("")
    document.getElementById("subscriberEmail").value = ""
    // document.getElementById("contactName").value = ""
    // document.getElementById("contactEmail").value = ""
    // document.getElementById("contactMessage").value = ""
  }

  useEffect(() => {

    const handleSuccess = (success, callback = null) => {
      if (success.status === "success") {
        MySwal.fire({
          title: (<h4>Success</h4>),
          html: (<h5>{success.message}</h5>),
          icon: "success",
          timer: 4000,
          customClass: {
            container: "my-swal",
          },
        }).then(() => {
          resetStates()
          if (callback) callback()
        })
      } else {
        MySwal.fire({
          title: '',
          html: (<h5>{success.message}</h5>),
          icon: "warning",
          timer: 6000,
          customClass: {
            container: "my-swal",
          },
        }).then(() => {
          resetStates()
          if (callback) callback()
        })
      }
    }

    const handleError = (error, callback = null) => {
      MySwal.fire({
        title: (<h4>Something went wrong</h4>),
        html: (<h5>{error}</h5>),
        icon: "error",
        timer: 6000,
        customClass: {
          container: "my-swal",
        },
      }).then(() => {
        resetStates()
        if (callback) callback()
      })
    }

    if (submittingData && newsLetterSubscriptionStatus === 'idle') {
      (newsLetterSubscriptionError == null || newsLetterSubscriptionError.length === 0) && Object.keys(newsLetterSubscriptions).length > 0
        ? handleSuccess(newsLetterSubscriptions, () => { })
        : handleError(newsLetterSubscriptionError, () => { })
    }
  }, [newsLetterSubscriptionStatus, submittingData])


  return (
    <>
      <div className={footerCss.youload_footer_section}>
        <div className="container-fluid">
          <div className="row mx-3">
            <div className="col-lg-6 col-sm-12">
              <div className={footerCss.footer_follow_links}>
                <h6 className={footerCss.footer_title_text}>Follow us on: </h6>
                <div className={footerCss.footer_social_links}>
                  <Link to='#'
                    onClick={(e) => {
                      window.location.href = "mailto:youlead@youlead.africa";
                      e.preventDefault();
                    }} target="_blank">
                    <span>
                      <img src={iconImg1} alt="" />
                    </span>
                  </Link>
                  {/* <span> 
                                <a className="twitter-timeline" data-height="500" data-theme="dark" href="https://twitter.com/OneYoungAfrica?ref_src=twsrc%5Etfw" target='_blank'>
                                    <span className={footerCss.bg_icon_fo}> 
                                        <img src={iconImg2}  alt="" />
                                    </span> 
                                </a>
                            </span> */}
                  <a href='https://twitter.com/OneYoungAfrica' target='_blank' rel="noreferrer" >
                    <span className={footerCss.bg_icon_fo}>
                      <img src={iconImg2} alt="" />
                    </span>
                  </a>
                  <a href='https://www.facebook.com/OneYoungAfrica/' target='_blank' rel="noreferrer" >
                    <span className={footerCss.bg_icon_fo}>
                      <img src={iconImg3} alt="" />
                    </span>
                  </a>
                  <a href='https://www.instagram.com/oneyoungafrica/?hl=en' target='_blank' rel="noreferrer" >
                    <span className={footerCss.bg_icon_fo}>
                      <img src={iconImg4} alt="" />
                    </span>
                  </a>
                  <a href='https://www.linkedin.com/company/youlead-afrika/?trk=public_profile_experience-item_profile-section-card_subtitle-click&originalSubdomain=rw' target='_blank' rel="noreferrer" >
                    <span className={footerCss.bg_icon_fo}>
                      <img src={iconImg5} alt="" />
                    </span>
                  </a>
                  {/* <Link to='/'>
                                <span> 
                                    <img src={iconImg6} alt="" />
                                </span> 
                            </Link>  */}
                </div>
                <div className={footerCss.twitter_embedded + ' mt-2'}>
                  {/* <img src={footerImg} className='img-fluid mt-3' alt="" /> */}
                  <TwitterContainer />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className={footerCss.footer_contact_us}>
                <h4 className='text-center'>Contact us</h4>
                <div className={footerCss.contact_us_div}>
                  <div>
                    <h6 className={footerCss.contact_sub_title}>Email</h6>
                    <p className='fw-bold'>youlead@youlead.africa</p>
                  </div>
                  <div>
                    MS Training Centre for Development Cooperation
                    <br />
                    P O Box 254 USA River - Arusha, Tanzania,
                    <br />
                    Arusha, Tanzania
                  </div>
                </div>
                <a href="https://portal.youlead.africa/public/contact-us"><button className="btn btn-md btn-primary" style={{ backgroundColor: '#690808', borderColor: '#690808' }}>Get in touch</button></a>
                <hr className={footerCss.footer_hr} />
                <div>
                  <h4 className={footerCss.contact_sub_title_two}>Subscribe to our newsletter</h4>
                  <div className={footerCss.contact_input}>
                    <input className="text-light" type="email" id="subscriberEmail" defaultValue={subscriberEmail} onKeyUp={(event) => { setSubscriberEmail(event.target.value) }} placeholder='Enter your email' />
                  </div>
                </div>
                <div>
                  <button className="btn btn-md btn-primary" style={{ backgroundColor: '#690808', borderColor: '#690808' }} onClick={() => { subscribe() }}>Subscribe</button>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-3">
              <div className={footerCss.footer_get_in_touch}>
                <h4 className='text-center'>Get in touch</h4>
                <form className='mt-4'>
                  <div className={footerCss.contact_input}>
                    <input className="text-light" type="text" placeholder='Name' id="contactName" defaultValue={contactName} onKeyUp={(event) => { setContactName(event.target.value) }} />
                  </div>
                  <div className={footerCss.contact_input}>
                    <input className="text-light" type="email" placeholder='Email' id="contactEmail" defaultValue={contactEmail} onKeyUp={(event) => { setContactEmail(event.target.value) }} />
                  </div>
                  <div className={footerCss.contact_input}>
                    <textarea className="text-light" name="" placeholder='Message' id="contactMessage" defaultValue={contactMessage} onKeyUp={(event) => { setContactMessage(event.target.value) }}></textarea>
                  </div>
                  <div className='text-end'>
                    <button className={footerCss.footer_btn} onClick={() => contactYoulead()}>Send</button>
                  </div>
                </form>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className={footerCss.youload_copyright}>
        <p className='text-center text-light'>Copyright &copy; 2024 YouLead Africa</p>
      </div>

      <Backdrop sx={{ color: "#fff", zIndex: 99998 }} open={newsLetterSubscriptionStatus === 'loading'}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <CircularProgress color="inherit" />
          <span className="mt-2">Please wait...</span>
        </div>
      </Backdrop>
    </>
  );
}

export default Footer
