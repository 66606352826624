import { get, post, getLiveCall } from "./apiHelper"
import * as url from "./urlHelper"

export const getEvents = () => get(url.getEvents)
export const getSpeakers = () => get(url.getSpeakers)
export const getEventReports = () => get(url.getEventReports)
export const getResearchPublications = () => get(url.getResearchPublications)
export const getBlogs = () => get(url.getBlogs)
export const getProjects = () => get(url.getProjects)
export const postNewsLetterSubscriptions = (data) => post(url.postNewsLetterSubscriptions, data)
export const getPhotos = (credentials) => getLiveCall(`${url.getPhotoGallery}&api_key=${credentials.apiKey}
&user_id=${credentials.userId}&format=json&nojsoncallback=1&per_page=500`)
export const getVideos = (credentials) => getLiveCall(`${url.getVideoGallery}?key=${credentials.apiKey}
&channelId=${credentials.channelId}&part=snippet,id&order=date&maxResults=30`)