import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Card as JoyCard, CardContent as JoyCardContent, CardCover as JoyCardCover, Typography as JoyTypography } from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import {
    Alert,
    AlertTitle,
    Box,
    Card, CardContent,
    CardMedia, Grid,
    Typography,
    useMediaQuery, useTheme
} from '@mui/material';
import waves from 'assets/images/home/paint.svg';
import champion1 from 'assets/images/projects/champion-1.png';
import champion10 from 'assets/images/projects/champion-10.png';
import champion11 from 'assets/images/projects/champion-11.png';
import champion12 from 'assets/images/projects/champion-12.png';
import champion13 from 'assets/images/projects/champion-13.png';
import champion14 from 'assets/images/projects/champion-14.png';
import champion15 from 'assets/images/projects/champion-15.png';
import champion16 from 'assets/images/projects/champion-16.png';
import champion17 from 'assets/images/projects/champion-17.png';
import champion18 from 'assets/images/projects/champion-18.png';
import champion19 from 'assets/images/projects/champion-19.png';
import champion2 from 'assets/images/projects/champion-2.png';
import champion20 from 'assets/images/projects/champion-20.png';
import champion3 from 'assets/images/projects/champion-3.png';
import champion4 from 'assets/images/projects/champion-4.png';
import champion5 from 'assets/images/projects/champion-5.png';
import champion6 from 'assets/images/projects/champion-6.png';
import champion7 from 'assets/images/projects/champion-7.png';
import champion8 from 'assets/images/projects/champion-8.png';
import champion9 from 'assets/images/projects/champion-9.png';
import icoyaca1 from 'assets/images/projects/icoyaca1.png';
import icoyaca2 from 'assets/images/projects/icoyaca2.png';
import icoyaca5 from 'assets/images/projects/icoyaca5.png';
import banner from 'assets/images/projects/icoyacaPage.png';
import member1 from 'assets/images/projects/member-1.png';
import member2 from 'assets/images/projects/member-2.png';
import member3 from 'assets/images/projects/member-3.png';
import member4 from 'assets/images/projects/member-4.png';
import React from 'react';

const Icoyaca = () => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
    const isXl = useMediaQuery(theme.breakpoints.up('xl'));

    let width = '100%';  // Default

    if (isXs) {
        width = '100%';
    } else if (isSm) {
        width = '100%';
    } else if (isMd) {
        width = '80%';
    } else if (isLg) {
        width = '70%';
    } else if (isXl) {
        width = '60%';
    }

    React.useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className="App">
            <div className="container mt-5 pt-3">
                <CssVarsProvider>
                    <JoyCard sx={{ minHeight: '400px', width: '100%' }}>
                        <JoyCardCover>
                            <img
                                src={banner}
                                srcSet={banner}
                                loading="lazy"
                                alt=""
                                style={{
                                    objectFit: 'cover',
                                    objectPosition: 'top center', // Keeps the top of the image in view
                                }}
                            />
                        </JoyCardCover>
                        <JoyCardCover
                            sx={{
                                background:
                                    'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                            }}
                        />
                        <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                            <JoyTypography level="title-lg" textColor="#fff" sx={{ fontSize: 'xx-large' }}>
                                Independent Continental Youth Advisory Council on AfCFTA
                            </JoyTypography>
                            <JoyTypography
                                textColor="neutral.200" sx={{ fontSize: 'x-large' }}
                            >
                                (ICOYACA)
                            </JoyTypography>
                        </JoyCardContent>
                    </JoyCard>
                </CssVarsProvider>
            </div>
            <div className="container">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: { xs: 'wrap', sm: 'wrap', md: 'wrap', lg: 'nowrap' } }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify',
                                    backgroundImage: `url(${waves})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center'
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography variant='h5' className='mt-3' style={{
                                            textAlign: 'center', color: '#690808'
                                        }} >
                                            <b>ICOYACA</b>
                                        </Typography>
                                        <Typography variant='span' className='mt-3' style={{
                                            textAlign: 'center', color: '#690808'
                                        }} >
                                            <b>Officially launched on the 9th November 2021</b>
                                        </Typography>
                                        <Typography variant='span' className='mt-3' style={{
                                            textAlign: 'center', color: '#690808'
                                        }} >
                                            by <b>H.E Dr. Jakaya Mrisho Kikwete</b>, former President of the United Republic of Tanzania
                                        </Typography>
                                        <Typography className='mt-3' style={{
                                            textAlign: 'center'
                                        }} >
                                            who doubles as Patron of YouLead, during the Youlead Africa 2021 Summit in the presence of Hon. Dr. Peter Mathuki, EAC Secretary General, AfCFTA Secretariat Senior Officials and youth leaders from various AU member states.
                                        </Typography>
                                    </CardContent>
                                </Box>
                                <CardMedia
                                    className='pe-3 pt-3 pb-4'
                                    component="img"
                                    sx={{ width: '50%' }}
                                    image={icoyaca1}
                                    alt=""
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className="container">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <div sx={{ display: 'flex' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify',
                                    // backgroundImage: `url(${waves})`,
                                    // backgroundSize: 'cover',
                                    // backgroundRepeat: 'no-repeat',
                                    // backgroundPosition: 'center'
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography variant='h5' className='mt-3 mx-4' style={{
                                            textAlign: 'center', color: '#690808'
                                        }} >
                                            <b>National Chapters</b>
                                        </Typography>
                                        <Typography variant='span' className='mt-3 mx-4' style={{
                                            textAlign: 'center'
                                        }} >The Council has established 54 Country Chapters and is engaging 10 youth in all the 54 African Union member state that have signed and/or ratified the AfCFTA.
                                        </Typography>

                                        <div className='mt-4 d-flex justify-content-center' style={{ width: '100%' }}>
                                            <img
                                                src={icoyaca2}
                                                srcSet={icoyaca2}
                                                loading="lazy"
                                                alt=""
                                                className='img-fluid'
                                            />
                                        </div>
                                    </CardContent>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className="container mb-5">
                <Typography variant='h5' className='mt-5 mb-3 mx-4' style={{
                    textAlign: 'center', color: '#690808'
                }} >
                    <b>Meet Our Council</b>
                </Typography>
                <Grid container direction="row" spacing={3} style={{
                    textAlign: 'justify'
                }}>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={member1}
                                        srcSet={member1}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Emolot Allan David
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Chairperson
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={member2}
                                        srcSet={member2}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Edith Njage
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Vice Chairperson
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={member3}
                                        srcSet={member3}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Blondel Abdallah Katongola
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Secretary General
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%', textAlign: 'start' }}>
                                <JoyCardCover>
                                    <img
                                        src={member4}
                                        srcSet={member4}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Diana Majala
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Administrative & Information Asst. Office of the Secretary General
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                </Grid>
            </div>
            <div className="container">
                <Typography variant='h5' className='mt-3 mx-4' style={{
                    textAlign: 'center', color: '#690808'
                }} >
                    <b>Our Key Initiatives</b>
                </Typography>
                <Timeline position="right" sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.2,
                    },
                }} className='ms-0' >
                    <TimelineItem className='ms-0'>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="primary"
                            className='d-none d-md-block'
                        >
                            <h6 className='d-none d-md-block'><b>AfCFTA Youth Engage Series</b></h6>
                        </TimelineOppositeContent>
                        <TimelineSeparator
                            className='d-none d-md-block'>
                            <TimelineConnector />
                            <TimelineDot color='primary'>
                                <EventAvailableIcon />
                            </TimelineDot>
                            <h6 style={{ color: '#690808' }} className='d-md-none'><b>AfCFTA Youth Engage Series</b></h6>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px' }}>
                            <Alert severity="success">
                                <AlertTitle style={{ textAlign: 'start' }}><b>AfCFTA Youth Engage Series</b></AlertTitle>
                                <hr />
                                <Typography className='' style={{ textAlign: 'justify' }} >
                                    This is an online engagement where experts in trade and young business leaders are hosted to share their knowledge on the AfCFTA and experiences trading in the African Continent and the world.
                                </Typography>
                            </Alert>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem className='ms-0'>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="primary"
                            className='d-none d-md-block'
                        >
                            <h6 className='d-none d-md-block'><b>The AfCFTA Youth Day</b></h6>
                        </TimelineOppositeContent>
                        <TimelineSeparator
                            className='d-none d-md-block'>
                            <TimelineConnector />
                            <TimelineDot color='primary'>
                                <EventAvailableIcon />
                            </TimelineDot>
                            <h6 style={{ color: '#690808' }} className='d-md-none'><b>The AfCFTA Youth Day</b></h6>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px' }}>
                            <Alert severity="success">
                                <AlertTitle style={{ textAlign: 'start' }}><b>The AfCFTA Youth Day</b></AlertTitle>
                                <hr />
                                <Typography className='' style={{ textAlign: 'justify' }} >
                                    This is an initiative aimed at celebrating the AfCFTA Youth Champions working tirelessly to champion Youth Inclusion in the AfCFTA. It will be celebrated every year on 9th November which also marks the day ICOYACA was established.
                                </Typography>
                            </Alert>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem className='ms-0'>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="primary"
                            className='d-none d-md-block'
                        >
                            <h6 className='d-none d-md-block'><b> AfCFTA Women and Youth in Trade Online Academy</b></h6>
                        </TimelineOppositeContent>
                        <TimelineSeparator
                            className='d-none d-md-block'>
                            <TimelineConnector />
                            <TimelineDot color='primary'>
                                <EventAvailableIcon />
                            </TimelineDot>
                            <h6 style={{ color: '#690808' }} className='d-md-none'><b> AfCFTA Women and Youth in Trade Online Academy</b></h6>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px' }}>
                            <Alert severity="success">
                                <AlertTitle style={{ textAlign: 'start' }}><b> AfCFTA Women and Youth in Trade Online Academy</b></AlertTitle>
                                <hr />
                                <Typography className='' style={{ textAlign: 'justify' }} >
                                    This is an online academy we hope to establish. One that will ensure continuous education of our women and youth in trade about the African Continental Free Trade Area, Market Entry Procedures, among others.
                                </Typography>
                                <hr />
                                <Typography className='' style={{ textAlign: 'justify' }} >
                                    The information shared will be structured and localised to enable uneducated women and youth in trade to understand the AfCFTA. It will also be open to use by various organisations that will want to capacitate their staff with AfCFTA knowledge including Human Rights Defenders.
                                </Typography>
                            </Alert>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem className='ms-0'>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="primary"
                            className='d-none d-md-block'
                        >
                            <h6 className='d-none d-md-block'><b>The Model AfCFTA Negotiations</b></h6>
                        </TimelineOppositeContent>
                        <TimelineSeparator
                            className='d-none d-md-block'>
                            <TimelineConnector />
                            <TimelineDot color='primary'>
                                <EventAvailableIcon />
                            </TimelineDot>
                            <h6 style={{ color: '#690808' }} className='d-md-none'><b>The Model AfCFTA Negotiations</b></h6>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px' }}>
                            <Alert severity="success">
                                <AlertTitle style={{ textAlign: 'start' }}><b>The Model AfCFTA Negotiations</b></AlertTitle>
                                <hr />
                                <Typography className='' style={{ textAlign: 'justify' }} >
                                    As a way of capacitating youth, the Council hopes to organise simulation Model AfCFTA Negotiations and the objective is to;
                                    The information shared will be structured and localised to enable uneducated women and youth in trade to understand the AfCFTA. It will also be open to use by various organisations that will want to capacitate their staff with AfCFTA knowledge including Human Rights Defenders.
                                </Typography>
                                <Typography className='' style={{ textAlign: 'justify' }} >
                                    <Alert severity="success">
                                        <AlertTitle style={{ textAlign: 'start' }}><b>Deepen the youths’ understanding of the AfCFTA negotiation process, priorities and functions</b></AlertTitle>
                                    </Alert>
                                    <Alert severity="success">
                                        <AlertTitle style={{ textAlign: 'start' }}><b>Rain a cohort of young business leaders who will serve as trainers on AfCFTA at the country level.</b></AlertTitle>
                                    </Alert>
                                    <Alert severity="success">
                                        <AlertTitle style={{ textAlign: 'start' }}><b>Create an inclusive environment for consultations for the upcoming protocol on women and youth in Trade.</b></AlertTitle>
                                    </Alert>
                                    <Alert severity="success">
                                        <AlertTitle style={{ textAlign: 'start' }}><b>Formulate and deploy a comprehensive and targeted approach to community peer-to-peer AfCFTA engagement, and a reporting mechanism to the AfCFTA Secretariat.</b></AlertTitle>
                                    </Alert>
                                </Typography>
                            </Alert>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem className='ms-0'>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="primary"
                            className='d-none d-md-block'
                        >
                            <h6 className='d-none d-md-block'><b> Regional Consultations on the Protocol on Women and Youth in Trade</b></h6>
                        </TimelineOppositeContent>
                        <TimelineSeparator
                            className='d-none d-md-block'>
                            <TimelineConnector />
                            <TimelineDot color='primary'>
                                <EventAvailableIcon />
                            </TimelineDot>
                            <h6 style={{ color: '#690808' }} className='d-md-none'><b> Regional Consultations on the Protocol on Women and Youth in Trade</b></h6>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px' }}>
                            <Alert severity="success">
                                <AlertTitle style={{ textAlign: 'start' }}><b> Regional Consultations on the Protocol on Women and Youth in Trade</b></AlertTitle>
                                <hr />
                                <Typography className='' style={{ textAlign: 'justify' }} >
                                    At the invitation of the AfCFTA Secretariat, the Council has and is actively participating in all regional consultative meeting on the Protocol of Women and Youth in Trade organized by the AfCFTA Secretariat and partners. </Typography>
                            </Alert>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            </div >
            <div className="container">
                <CssVarsProvider>
                    <JoyCard sx={{ minHeight: '400px', width: '100%' }}>
                        <JoyCardCover>
                            <img
                                src={icoyaca5}
                                srcSet={icoyaca5}
                                loading="lazy"
                                alt=""
                                style={{
                                    objectFit: 'cover',
                                    objectPosition: 'top center', // Keeps the top of the image in view
                                }}
                            />
                        </JoyCardCover>
                        <JoyCardCover
                            sx={{
                                background:
                                    'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                            }}
                        />
                        <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                            <JoyTypography level="title-lg" textColor="#fff">
                                The council with support from United Nations Economic Commission for Africa (UNECA) will be conducting regional consultations on the Protocol of Women and Youth in Trade
                            </JoyTypography>
                        </JoyCardContent>
                    </JoyCard>
                </CssVarsProvider>

                <Typography variant='h5' className='mt-5 mb-3 mx-4' style={{
                    textAlign: 'center', color: '#690808'
                }} >
                    <b>East Africa Chapters</b>
                </Typography>
                <Grid container direction="row" spacing={3} style={{
                    textAlign: 'justify'
                }}>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion1}
                                        srcSet={champion1}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Emnet Mesfin
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Ethiopia Country Champion
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion2}
                                        srcSet={champion2}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        John Walugembe
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Uganda Country Champion
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion3}
                                        srcSet={champion3}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Bimenyimana Beny
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Rwanda Country Champion
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion4}
                                        srcSet={champion4}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Gola Boyoi
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        South Sudan Country Champion
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                </Grid>

                <Typography variant='h5' className='mt-5 mb-3 mx-4' style={{
                    textAlign: 'center', color: '#690808'
                }} >
                    <b>North Africa Chapters</b>
                </Typography>
                <Grid container direction="row" spacing={3} style={{
                    textAlign: 'justify'
                }}>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion5}
                                        srcSet={champion5}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Hassan Ali Ghazaly
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Egypt Country Champion
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion6}
                                        srcSet={champion6}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Amine Amine
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Morocco Country Champion
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion7}
                                        srcSet={champion7}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Manel Ben Ammar
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Tunisia Country Champion
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion8}
                                        srcSet={champion8}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Sanad M. Alfakhry
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Lybia Country Champion
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                </Grid>

                <Typography variant='h5' className='mt-5 mb-3 mx-4' style={{
                    textAlign: 'center', color: '#690808'
                }} >
                    <b>Western Africa Chapters</b>
                </Typography>
                <Grid container direction="row" spacing={3} style={{
                    textAlign: 'justify'
                }}>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion9}
                                        srcSet={champion9}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Khadim Diop
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Senegal Country Champion
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion10}
                                        srcSet={champion10}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Felicia D. Pitt
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Liberia Country Champion
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion11}
                                        srcSet={champion11}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Lucien Aguegue
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Benin Country Champion
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion12}
                                        srcSet={champion12}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Brice Romaric Kone
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Ivory Coast Country Champion
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                </Grid>

                <Typography variant='h5' className='mt-5 mb-3 mx-4' style={{
                    textAlign: 'center', color: '#690808'
                }} >
                    <b>Southern Africa Chapters</b>
                </Typography>
                <Grid container direction="row" spacing={3} style={{
                    textAlign: 'justify'
                }}>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion13}
                                        srcSet={champion13}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Dingiswayo Jere
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Malawi Country Champion
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion14}
                                        srcSet={champion14}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Ruth Nawakwi
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Zambia Country Champion
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion15}
                                        srcSet={champion15}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Trisha Hoareau
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Seychelles Country Champion
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion16}
                                        srcSet={champion16}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Victor Baatweng
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Botswana Country Champion
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                </Grid>

                <Typography variant='h5' className='mt-5 mb-3 mx-4' style={{
                    textAlign: 'center', color: '#690808'
                }} >
                    <b>Central Africa Chapters & Other Champions</b>
                </Typography>
                <Grid className='mb-5' container direction="row" spacing={3} style={{
                    textAlign: 'justify'
                }}>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion17}
                                        srcSet={champion17}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Essame Bruno
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Cameroon Country Champion
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion18}
                                        srcSet={champion18}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Lagrange Fidele
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Gabon Country Champion
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion19}
                                        srcSet={champion19}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Rabby Gelson
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Angola Country Champion
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion20}
                                        srcSet={champion20}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Lintle Fosa
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Lesotho Deputy Country Champion
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                </Grid>
            </div>
        </div >
    );
}

export default Icoyaca;
