import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getBlogs } from 'helpers/backendHelper'

const initialState = {
    blogs: [],
    status: 'idle',
    error: null
}

export const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        blogsAdded: {
            reducer(state, action) {
                state.blogs.push(action.payload)
            },
            prepare(title, content, userId) {
                // omit prepare logic
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchBlogs.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchBlogs.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched blogs to the array
                state.blogs = action.payload
            })
            .addCase(fetchBlogs.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

// Action creators are generated for each case reducer function
export const { blogsAdded } = blogSlice.actions

export default blogSlice.reducer

export const selectAllBlogs = state => state.blog.blogs

export const selectBlogById = (state, blogId) =>
    state.blog.blogs.find(blog => blog.id === blogId)

export const fetchBlogs = createAsyncThunk('blogs/fetchBlogs', getBlogs)