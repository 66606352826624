import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import {
    Card as JoyCard, CardContent as JoyCardContent, CardCover as JoyCardCover, Typography as JoyTypography
} from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import {
    Alert,
    AlertTitle,
    Box,
    Card, CardContent,
    CardMedia, Grid,
    Typography,
    useMediaQuery, useTheme
} from '@mui/material';
import icoyaca1 from 'assets/images/eaypn/forum-2.png';
import banner from 'assets/images/eaypn/peace.png';
import icoyaca2 from 'assets/images/eaypn/purpose.png';
import waves from 'assets/images/home/paint.svg';
import champion1 from 'assets/images/eaypn/cmember-1.png';
import champion2 from 'assets/images/eaypn/cmember-2.png';
import champion3 from 'assets/images/eaypn/cmember-3.png';
import champion4 from 'assets/images/eaypn/cmember-4.png';
import champion5 from 'assets/images/eaypn/cmember-5.png';
import champion6 from 'assets/images/eaypn/cmember-6.png';
import champion7 from 'assets/images/eaypn/cmember-7.png';
import React from 'react';

const Eaypn = () => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
    const isXl = useMediaQuery(theme.breakpoints.up('xl'));

    let width = '100%';  // Default

    if (isXs) {
        width = '100%';
    } else if (isSm) {
        width = '100%';
    } else if (isMd) {
        width = '80%';
    } else if (isLg) {
        width = '70%';
    } else if (isXl) {
        width = '60%';
    }

    React.useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className="App">
            <div className="container mt-5 pt-3">
                <CssVarsProvider>
                    <JoyCard sx={{ minHeight: '400px', width: '100%' }}>
                        <JoyCardCover>
                            <img
                                src={banner}
                                srcSet={banner}
                                loading="lazy"
                                alt=""
                                style={{
                                    objectFit: 'cover',
                                    objectPosition: 'top center', // Keeps the top of the image in view
                                }}
                            />
                        </JoyCardCover>
                        <JoyCardCover
                            sx={{
                                background:
                                    'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                            }}
                        />
                        <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                            <JoyTypography level="title-lg" sx={{ fontSize: 'xx-large' }} textColor="#fff">
                                East Africa Youth Peace Network
                            </JoyTypography>
                            <JoyTypography
                                textColor="neutral.200" sx={{ fontSize: 'x-large' }}
                            >
                                (EAYPN)
                            </JoyTypography>
                        </JoyCardContent>
                    </JoyCard>
                </CssVarsProvider>
            </div>
            <div className="container">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: { xs: 'wrap', sm: 'wrap', md: 'wrap', lg: 'nowrap' } }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify',
                                    backgroundImage: `url(${waves})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center'
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography variant='h5' className='mt-3' style={{
                                            textAlign: 'center', color: '#690808'
                                        }} >
                                            <b>EAYPN</b>
                                        </Typography>
                                        <Typography variant='span' className='mt-3' style={{
                                            textAlign: 'center', color: '#690808'
                                        }} >
                                            <b>Introduced at the annual YouLead Summit in 2020</b>
                                        </Typography>
                                        <Typography variant='span' className='mt-3' style={{
                                            textAlign: 'center', color: '#690808'
                                        }} >
                                            by <b>YouLead Africa</b>
                                        </Typography>
                                    </CardContent>
                                </Box>
                                <CardMedia
                                    className='pe-3 pt-3 pb-4'
                                    component="img"
                                    sx={{ width: '50%' }}
                                    image={icoyaca2}
                                    alt=""
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className="container">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <div sx={{ display: 'flex' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify',
                                    // backgroundImage: `url(${waves})`,
                                    // backgroundSize: 'cover',
                                    // backgroundRepeat: 'no-repeat',
                                    // backgroundPosition: 'center'
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography variant='h5' className='mt-3 mx-4' style={{
                                            textAlign: 'center', color: '#690808'
                                        }} >
                                            <b>About The Forum</b>
                                        </Typography>
                                        <Typography variant='span' className='mt-3 mx-4' style={{
                                            textAlign: 'justify'
                                        }} >
                                            The forum is dedicated to providing an opportunity for the youth to have their voice heard
                                            and strengthen their ability to engage in peace and security, to contribute towards
                                            building an effective, well-coordinated, and responsive youth sector that acts in
                                            solidarity within the region.
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className="container">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: { xs: 'wrap', sm: 'wrap', md: 'wrap', lg: 'nowrap' } }}>

                                <CardMedia
                                    className='pe-3 pt-3 pb-4'
                                    component="img"
                                    sx={{ width: '50%' }}
                                    image={icoyaca1}
                                    alt=""
                                /> <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify',
                                    backgroundImage: `url(${waves})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center'
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography variant='span' className='mt-3' style={{
                                            textAlign: 'justify'
                                        }} >
                                            The discussions focused on the vulnerability of youth in conflicts in the region and the
                                            role youth can play in peace building, ensuring sustainable security and stability in
                                            East Africa and attracted participants from across the East Africa Community countries
                                            as well as Peace networks outside the region.
                                        </Typography>
                                        <Typography variant='span' className='mt-3' style={{
                                            textAlign: 'justify'
                                        }} >
                                            One of the key outcomes from the Peace forum was the East Africa Youth Peace Network
                                            (EAYPN). A collective of young peacebuilders and youth-led or youth serving organizations
                                            working in peace building and conflict resolution in the region.
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className="container">
                <Typography variant='h5' className='mt-3 mx-4' style={{
                    textAlign: 'center', color: '#690808'
                }} >
                    <b>Purpose of EAYPN</b>
                </Typography>
                <Timeline position="right" sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.2,
                    },
                }} className='ms-0' >
                    <TimelineItem className='ms-0'>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="primary"
                            className='d-none d-md-block'
                        >
                        </TimelineOppositeContent>
                        <TimelineSeparator
                            className='d-none d-md-block'>
                            <TimelineConnector />
                            <TimelineDot color='primary'>
                                <EventAvailableIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px' }}>
                            <Alert severity="success">
                                <AlertTitle style={{ textAlign: 'start' }}><b>To provide a platform for young peace builders and actors in Youth Peace and Security to network, share experiences and best practices, reflect and learn from each other’s interventions.</b></AlertTitle>
                            </Alert>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem className='ms-0'>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="primary"
                            className='d-none d-md-block'
                        >
                        </TimelineOppositeContent>
                        <TimelineSeparator
                            className='d-none d-md-block'>
                            <TimelineConnector />
                            <TimelineDot color='primary'>
                                <EventAvailableIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px' }}>
                            <Alert severity="success">
                                <AlertTitle style={{ textAlign: 'start' }}><b>To consolidate training opportunities and ensure targeted reach of capacity building for mainstream actors in the Youth Peace and Security Sector.</b></AlertTitle>
                            </Alert>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem className='ms-0'>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="primary"
                            className='d-none d-md-block'
                        >
                        </TimelineOppositeContent>
                        <TimelineSeparator
                            className='d-none d-md-block'>
                            <TimelineConnector />
                            <TimelineDot color='primary'>
                                <EventAvailableIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px' }}>
                            <Alert severity="success">
                                <AlertTitle style={{ textAlign: 'start' }}><b>To mobilize a broad constituency and leverage resources for joint advocacy on matters Youth, Peace and Security in the East Africa Community and the African continent as a whole.</b></AlertTitle>
                            </Alert>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            </div >

            <div className="container">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <div sx={{ display: 'flex' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify',
                                    // backgroundImage: `url(${waves})`,
                                    // backgroundSize: 'cover',
                                    // backgroundRepeat: 'no-repeat',
                                    // backgroundPosition: 'center'
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography variant='h5' className='mt-3 mx-4' style={{
                                            textAlign: 'center', color: '#690808'
                                        }} >
                                            <b>The steering committee</b>
                                        </Typography>
                                        <Typography variant='span' className='mt-3 mx-4' style={{
                                            textAlign: 'center'
                                        }} >
                                            The steering committee of the EAYPN is composed of 6 representives for each EAC Member States, a representative of YouLead Africa, a representatives from the EAC Youth Ambassadors Platform as well as the AU Youth Peace Ambassador for East Africa.
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className="container mb-5">
                <Grid container direction="row" spacing={3} style={{
                    textAlign: 'justify'
                }}>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion1}
                                        srcSet={champion1}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Anne Maua
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Chairperson of the Steering Committee
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion2}
                                        srcSet={champion2}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Wani Stephen
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Country Representative South Sudan
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion3}
                                        srcSet={champion3}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Elias Odhiambo
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        EACYAP Representative
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion4}
                                        srcSet={champion4}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Yves Jacket
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Country Representative Burundi
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion5}
                                        srcSet={champion5}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Robert Muhangi
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Country Representative Uganda
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion6}
                                        srcSet={champion6}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Robert Gisa
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Country Representative Rwanda
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CssVarsProvider>
                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                <JoyCardCover>
                                    <img
                                        src={champion7}
                                        srcSet={champion7}
                                        loading="lazy"
                                        alt=""
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'top center', // Keeps the top of the image in view
                                        }}
                                    />
                                </JoyCardCover>
                                <JoyCardCover
                                    sx={{
                                        background:
                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                    }}
                                />
                                <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                                    <JoyTypography level="title-lg" textColor="#fff">
                                        Caliph Makwita
                                    </JoyTypography>
                                    <JoyTypography
                                        textColor="neutral.300"
                                    >
                                        Country Representative Tanzania
                                    </JoyTypography>
                                </JoyCardContent>
                            </JoyCard>
                        </CssVarsProvider>
                    </Grid>
                </Grid>
            </div>
        </div >
    );
}

export default Eaypn;
