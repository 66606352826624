import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardCover from '@mui/joy/CardCover';
import Typography from '@mui/joy/Typography';
import { CssVarsProvider } from '@mui/joy/styles';
import { Box, Grid } from '@mui/material';
import * as React from 'react';

import team1 from 'assets/images/team/team-1.png';
import team2 from 'assets/images/team/team-2.png';
import team3 from 'assets/images/team/team-3.png';
import team5 from 'assets/images/team/team-5.png';
import team6 from 'assets/images/team/team-6.png';
import team8 from 'assets/images/team/team-8.png';
import team9 from 'assets/images/team/team-9.png';

const Team = () => {

    React.useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <CssVarsProvider>
            <div className="App container my-5 py-3">
                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{ textAlign: 'start' }}>
                    <CardContent>
                        <div className='my-4 d-flex justify-content-center' style={{ width: '100%', color: '#690808' }}>
                            <h1><b>Meet The Team</b></h1>
                        </div>
                        <Grid container direction="row" spacing={5} style={{ width: '100%' }}>
                            <Grid item lg={4} md={6} sm={6} xs={12} style={{ width: '100%' }}>
                                <Card sx={{ minHeight: '400px', width: '100%' }}>
                                    <CardCover>
                                        <img
                                            src={team2}
                                            srcSet={team2}
                                            loading="lazy"
                                            alt=""
                                            style={{
                                                objectFit: 'cover',
                                                objectPosition: 'top center', // Keeps the top of the image in view
                                            }}
                                        />
                                    </CardCover>
                                    <CardCover
                                        sx={{
                                            background:
                                                'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                        }}
                                    />
                                    <CardContent sx={{ justifyContent: 'flex-end' }}>
                                        <Typography level="title-lg" textColor="#fff">
                                            Sodfa Daaji
                                        </Typography>
                                        <Typography
                                            textColor="neutral.300"
                                        >
                                            Program Director
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12} style={{ width: '100%' }}>
                                <Card sx={{ minHeight: '400px', width: '100%' }}>
                                    <CardCover>
                                        <img
                                            src={team9}
                                            srcSet={team9}
                                            loading="lazy"
                                            alt=""
                                            style={{
                                                objectFit: 'cover',
                                                objectPosition: 'top center', // Keeps the top of the image in view
                                            }}
                                        />
                                    </CardCover>
                                    <CardCover
                                        sx={{
                                            background:
                                                'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                        }}
                                    />
                                    <CardContent sx={{ justifyContent: 'flex-end' }}>
                                        <Typography level="title-lg" textColor="#fff">
                                            Beatrice Marandu
                                        </Typography>
                                        <Typography
                                            textColor="neutral.300"
                                        >
                                            Projects Coordinator
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12} style={{ width: '100%' }}>
                                <Card sx={{ minHeight: '400px', width: '100%' }}>
                                    <CardCover>
                                        <img
                                            src={team8}
                                            srcSet={team8}
                                            loading="lazy"
                                            alt=""
                                            style={{
                                                objectFit: 'cover',
                                                objectPosition: 'top center', // Keeps the top of the image in view
                                            }}
                                        />
                                    </CardCover>
                                    <CardCover
                                        sx={{
                                            background:
                                                'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                        }}
                                    />
                                    <CardContent sx={{ justifyContent: 'flex-end' }}>
                                        <Typography level="title-lg" textColor="#fff">
                                            Fiona Kemigisha
                                        </Typography>
                                        <Typography
                                            textColor="neutral.300"
                                        >
                                            Regional Program Associate <br /> Digital Content
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12} style={{ width: '100%' }}>
                                <Card sx={{ minHeight: '400px', width: '100%' }}>
                                    <CardCover>
                                        <img
                                            src={team5}
                                            srcSet={team5}
                                            loading="lazy"
                                            alt=""
                                            style={{
                                                objectFit: 'cover',
                                                objectPosition: 'top center', // Keeps the top of the image in view
                                            }}
                                        />
                                    </CardCover>
                                    <CardCover
                                        sx={{
                                            background:
                                                'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                        }}
                                    />
                                    <CardContent sx={{ justifyContent: 'flex-end' }}>
                                        <Typography level="title-lg" textColor="#fff">
                                            Ivan Sebastian
                                        </Typography>
                                        <Typography
                                            textColor="neutral.300"
                                        >
                                            Regional Program Associate <br /> External & Public Engagement
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12} style={{ width: '100%' }}>
                                <Card sx={{ minHeight: '400px', width: '100%' }}>
                                    <CardCover>
                                        <img
                                            src={team1}
                                            srcSet={team1}
                                            loading="lazy"
                                            alt=""
                                            style={{
                                                objectFit: 'cover',
                                                objectPosition: 'top center', // Keeps the top of the image in view
                                            }}
                                        />
                                    </CardCover>
                                    <CardCover
                                        sx={{
                                            background:
                                                'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                        }}
                                    />
                                    <CardContent sx={{ justifyContent: 'flex-end' }}>
                                        <Typography level="title-lg" textColor="#fff">
                                            Reagan Muyinda
                                        </Typography>
                                        <Typography
                                            textColor="neutral.300"
                                        >
                                            Regional Consortium Coordinator &<br />Alumni Engagement
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12} style={{ width: '100%' }}>
                                <Card sx={{ minHeight: '400px', width: '100%' }}>
                                    <CardCover>
                                        <img
                                            src={team3}
                                            srcSet={team3}
                                            loading="lazy"
                                            alt=""
                                            style={{
                                                objectFit: 'cover',
                                                objectPosition: 'top center', // Keeps the top of the image in view
                                            }}
                                        />
                                    </CardCover>
                                    <CardCover
                                        sx={{
                                            background:
                                                'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                        }}
                                    />
                                    <CardContent sx={{ justifyContent: 'flex-end' }}>
                                        <Typography level="title-lg" textColor="#fff">
                                            Yona Siyongwana
                                        </Typography>
                                        <Typography
                                            textColor="neutral.300"
                                        >
                                            Project Associate <br /> Lead(H)er Fellowship
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item lg={4} md={0} sm={0} xs={12} style={{ width: '100%' }}>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12} style={{ width: '100%' }}>
                                <Card sx={{ minHeight: '400px', width: '100%' }}>
                                    <CardCover>
                                        <img
                                            src={team6}
                                            srcSet={team6}
                                            loading="lazy"
                                            alt=""
                                            style={{
                                                objectFit: 'cover',
                                                objectPosition: 'top center', // Keeps the top of the image in view
                                            }}
                                        />
                                    </CardCover>
                                    <CardCover
                                        sx={{
                                            background:
                                                'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                        }}
                                    />
                                    <CardContent sx={{ justifyContent: 'flex-end' }}>
                                        <Typography level="title-lg" textColor="#fff">
                                            Juma Emmanuel Erassy
                                        </Typography>
                                        <Typography
                                            textColor="neutral.300"
                                        >
                                            Regional Program Associate <br /> &nbsp;
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Box>
            </div>
        </CssVarsProvider>
    );
}

export default Team;
