import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import {
    Card as JoyCard, CardContent as JoyCardContent, CardCover as JoyCardCover, Typography as JoyTypography
} from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { SelfImprovement } from '@mui/icons-material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {
    Alert,
    AlertTitle,
    Button,
    Avatar,
    Box,
    Card, CardContent,
    CardMedia, Divider, Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Step,
    StepButton,
    Stepper,
    useMediaQuery, useTheme
} from '@mui/material';
import waves from 'assets/images/home/paint.svg';
import champion1 from 'assets/images/projects/champion-1.png';
import champion10 from 'assets/images/projects/champion-10.png';
import champion11 from 'assets/images/projects/champion-11.png';
import champion12 from 'assets/images/projects/champion-12.png';
import champion13 from 'assets/images/projects/champion-13.png';
import champion14 from 'assets/images/projects/champion-14.png';
import champion15 from 'assets/images/projects/champion-15.png';
import champion16 from 'assets/images/projects/champion-16.png';
import champion17 from 'assets/images/projects/champion-17.png';
import champion18 from 'assets/images/projects/champion-18.png';
import champion19 from 'assets/images/projects/champion-19.png';
import champion2 from 'assets/images/projects/champion-2.png';
import champion20 from 'assets/images/projects/champion-20.png';
import champion3 from 'assets/images/projects/champion-3.png';
import champion4 from 'assets/images/projects/champion-4.png';
import champion5 from 'assets/images/projects/champion-5.png';
import champion6 from 'assets/images/projects/champion-6.png';
import champion7 from 'assets/images/projects/champion-7.png';
import champion8 from 'assets/images/projects/champion-8.png';
import champion9 from 'assets/images/projects/champion-9.png';
import icoyaca1 from 'assets/images/afybc/rational.png';
import icoyaca2 from 'assets/images/afybc/business.png';
import icoyaca5 from 'assets/images/projects/icoyaca5.png';
import banner from 'assets/images/afybc/header-img.png';
import React from 'react';

const Afybc = () => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
    const isXl = useMediaQuery(theme.breakpoints.up('xl'));

    let width = '100%';  // Default

    if (isXs) {
        width = '100%';
    } else if (isSm) {
        width = '100%';
    } else if (isMd) {
        width = '80%';
    } else if (isLg) {
        width = '70%';
    } else if (isXl) {
        width = '60%';
    }

    React.useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const steps = ['Initiatives', 'Partners', 'Team', 'Volunteers'];
    const [activeStepAlt, setActiveStepAlt] = React.useState(0);
    const [completed, setCompleted] = React.useState({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStepAlt === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNextStep = () => {
        const newActiveStepAlt =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStepAlt + 1;
        setActiveStepAlt(newActiveStepAlt);
    };

    const handleBackStep = () => {
        setActiveStepAlt((prevActiveStepAlt) => prevActiveStepAlt - 1);
    };

    const handleStep = (step) => () => {
        setActiveStepAlt(step);
    };

    return (
        <div className="App">
            <div className="container mt-5 pt-3">
                <CssVarsProvider>
                    <JoyCard sx={{ minHeight: '400px', width: '100%' }}>
                        <JoyCardCover>
                            <img
                                src={banner}
                                srcSet={banner}
                                loading="lazy"
                                alt=""
                                style={{
                                    objectFit: 'cover',
                                    objectPosition: 'top center', // Keeps the top of the image in view
                                }}
                            />
                        </JoyCardCover>
                        <JoyCardCover
                            sx={{
                                background:
                                    'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                            }}
                        />
                        <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                            <JoyTypography level="title-lg" textColor="#fff" sx={{ fontSize: 'xx-large' }}>
                                Afrikan Youth Business Council
                            </JoyTypography>
                            <JoyTypography
                                textColor="neutral.200" sx={{ fontSize: 'x-large' }}
                            >
                                (AfYBC)
                            </JoyTypography>
                        </JoyCardContent>
                    </JoyCard>
                </CssVarsProvider>
            </div>
            <div className="container">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: { xs: 'wrap', sm: 'wrap', md: 'wrap', lg: 'nowrap' } }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify',
                                    backgroundImage: `url(${waves})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center'
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography variant='h5' className='mt-3' style={{
                                            textAlign: 'center', color: '#690808'
                                        }} >
                                            <b>AFYBC</b>
                                        </Typography>
                                        <Typography variant='span' className='mt-3' style={{
                                            textAlign: 'justify', color: '#690808'
                                        }} >
                                            <b>The Afrikan Youth Business Council is the continental apex body for youth-led private
                                                sector entities, institutions & associations in Africa advocating for a youth friendly
                                                business policy environment in general and making AfCFTA Promises to African youth a
                                                reality in particular.</b>
                                        </Typography>
                                    </CardContent>
                                </Box>
                                <CardMedia
                                    className='pe-3 pt-3 pb-4'
                                    component="img"
                                    sx={{ width: '50%' }}
                                    image={icoyaca1}
                                    alt=""
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className="container">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <div sx={{ display: 'flex' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify',
                                    // backgroundImage: `url(${waves})`,
                                    // backgroundSize: 'cover',
                                    // backgroundRepeat: 'no-repeat',
                                    // backgroundPosition: 'center'
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography variant='h5' className='mt-3 mx-4' style={{
                                            textAlign: 'center', color: '#690808'
                                        }} >
                                            <b>Our Theory of Change</b>
                                        </Typography>
                                        <Typography variant='span' className='mt-3 mx-4' style={{
                                            textAlign: 'justify'
                                        }} >
                                            On one hand, Africa, the world’s youngest continent, grapples with youth unemployment.
                                            Youth led entrepreneurship and enterprise innovation is one sure way to address this
                                            challenge - creating jobs, housing and feeding Africa’s booming youth population. The
                                            existing economic policy and institutional frameworks do not offer an adequately enabling
                                            environment and incentives required for youth-led entrepreneurship and innovation to
                                            yield the desired results. Unfortunately, youth cannot wait any longer, it is now or
                                            never! Youth-led enterprises need to be better recognised and supported to be more
                                            productive and create jobs that youth urgently and desperately need.
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className="container">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: { xs: 'wrap', sm: 'wrap', md: 'wrap', lg: 'nowrap' } }}>
                                <CardMedia
                                    className='pe-3 pt-3 pb-4'
                                    component="img"
                                    sx={{ width: '50%' }}
                                    image={icoyaca2}
                                    alt=""
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify',
                                    backgroundImage: `url(${waves})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography variant='h5' className='mt-3' style={{
                                            textAlign: 'justify', color: '#690808'
                                        }} >
                                            <b>Vision</b>
                                        </Typography>
                                        <Typography variant='span' className='mt-3' style={{
                                            textAlign: 'justify', color: '#690808'
                                        }} >
                                            <b>To see Africa’s young people as active players in Intra-African Trade.</b>
                                        </Typography>
                                        <hr />
                                        <Typography variant='h5' className='mt-3' style={{
                                            textAlign: 'justify', color: '#690808'
                                        }} >
                                            <b>Mission</b>
                                        </Typography>
                                        <Typography variant='span' className='mt-3' style={{
                                            textAlign: 'justify', color: '#690808'
                                        }} >
                                            <b>To make AfCFTA work for youth-led businesses</b>
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className="container">
                <Typography variant='h5' className='mt-3 mx-4' style={{
                    textAlign: 'center', color: '#690808'
                }} >
                    <b>Our Objectives</b>
                </Typography>
                <Timeline position="right" sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.2,
                    },
                }} className='ms-0' >
                    <TimelineItem className='ms-0'>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="primary"
                            className='d-none d-md-block'
                        >
                        </TimelineOppositeContent>
                        <TimelineSeparator
                            className='d-none d-md-block'>
                            <TimelineConnector />
                            <TimelineDot color='primary'>
                                <EventAvailableIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px' }}>
                            <Alert severity="success">
                                <AlertTitle style={{ textAlign: 'start' }}><b>Be the continental voice of youth in private sector, promote youth led trade and enterprise</b></AlertTitle>
                            </Alert>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem className='ms-0'>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="primary"
                            className='d-none d-md-block'
                        >
                        </TimelineOppositeContent>
                        <TimelineSeparator
                            className='d-none d-md-block'>
                            <TimelineConnector />
                            <TimelineDot color='primary'>
                                <EventAvailableIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px' }}>
                            <Alert severity="success">
                                <AlertTitle style={{ textAlign: 'start' }}><b>Advocate for a youth friendly economic policy environment that facilitates the growth of African youth business interests.</b></AlertTitle>
                            </Alert>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem className='ms-0'>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="primary"
                            className='d-none d-md-block'
                        >
                        </TimelineOppositeContent>
                        <TimelineSeparator
                            className='d-none d-md-block'>
                            <TimelineConnector />
                            <TimelineDot color='primary'>
                                <EventAvailableIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px' }}>
                            <Alert severity="success">
                                <AlertTitle style={{ textAlign: 'start' }}><b> Resource mobilization for youth led-businesses and startups for investment financing, growth and access to markets.</b></AlertTitle>
                            </Alert>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem className='ms-0'>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="primary"
                            className='d-none d-md-block'
                        >
                        </TimelineOppositeContent>
                        <TimelineSeparator
                            className='d-none d-md-block'>
                            <TimelineConnector />
                            <TimelineDot color='primary'>
                                <EventAvailableIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px' }}>
                            <Alert severity="success">
                                <AlertTitle style={{ textAlign: 'start' }}><b>Build capacity and ensure participation of youth-led SMEs in the African and the global economy.</b></AlertTitle>
                            </Alert>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem className='ms-0'>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="primary"
                            className='d-none d-md-block'
                        >
                        </TimelineOppositeContent>
                        <TimelineSeparator
                            className='d-none d-md-block'>
                            <TimelineConnector />
                            <TimelineDot color='primary'>
                                <EventAvailableIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px' }}>
                            <Alert severity="success">
                                <AlertTitle style={{ textAlign: 'start' }}><b> Platform for networking, cooperation, learning and exchange of experiences and knowledge transfer.</b></AlertTitle>
                            </Alert>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            </div >
            <div className="container">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <div sx={{ display: 'flex' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify',
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography variant='h5' className='mt-3 mx-4' style={{
                                            textAlign: 'center', color: '#690808'
                                        }} >
                                            <b>Membership Profile </b>
                                        </Typography>
                                        <Grid container direction="row" spacing={3} style={{
                                            textAlign: 'justify'
                                        }}>
                                            <Grid item sm={6} xs={12}>
                                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                                    <ListItem alignItems="flex-start">
                                                        <ListItemAvatar >
                                                            <Avatar sx={{ bgcolor: '#690808' }} alt="1" src="null" />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary="Age range: 18-39 years old"
                                                        />
                                                    </ListItem>
                                                    <Divider variant="inset" component="li" />
                                                    <ListItem alignItems="flex-start">
                                                        <ListItemAvatar >
                                                            <Avatar sx={{ bgcolor: '#690808' }} alt="2" src="null" />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary="Region: Africa"
                                                        />
                                                    </ListItem>
                                                    <Divider variant="inset" component="li" />
                                                    <ListItem alignItems="flex-start">
                                                        <ListItemAvatar >
                                                            <Avatar sx={{ bgcolor: '#690808' }} alt="3" src="null" />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary="Business type: Youth-led private sector entities, institutions & associations in Africa"
                                                        />
                                                    </ListItem>
                                                    <Divider variant="inset" component="li" />
                                                    <ListItem alignItems="flex-start">
                                                        <ListItemAvatar >
                                                            <Avatar sx={{ bgcolor: '#690808' }} alt="4" src="null" />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary="Business stage: Startups, SMEs, and growing businesses"
                                                        />
                                                    </ListItem>
                                                    <Divider variant="inset" component="li" />
                                                    <ListItem alignItems="flex-start">
                                                        <ListItemAvatar >
                                                            <Avatar sx={{ bgcolor: '#690808' }} alt="5" src="null" />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary="Industry: All industries are welcome, with a focus on sectors with high growth potential under AfCFTA"
                                                        />
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <Grid item sm={6} xs={12}>
                                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                                    <ListItem alignItems="flex-start">
                                                        <ListItemAvatar >
                                                            <Avatar sx={{ bgcolor: '#690808' }} alt="6" src="null" />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary="Business mission: Members should share AfYBC's mission of advocating for youth-friendly economic and trade policies, promoting fair trade and fair competition, and supporting connectivity and free movement for youth-led enterprises."
                                                        />
                                                    </ListItem>
                                                    <Divider variant="inset" component="li" />
                                                    <ListItem alignItems="flex-start">
                                                        <ListItemAvatar >
                                                            <Avatar sx={{ bgcolor: '#690808' }} alt="7" src="null" />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary="Willingness to engage: Members should be willing to engage in capacity-building programs, advocacy efforts, and networking opportunities offered by AfYBC."
                                                        />
                                                    </ListItem>
                                                    <Divider variant="inset" component="li" />
                                                    <ListItem alignItems="flex-start">
                                                        <ListItemAvatar >
                                                            <Avatar sx={{ bgcolor: '#690808' }} alt="8" src="null" />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary="Commitment to AfCFTA: Members should be committed to taking advantage of the opportunities offered by AfCFTA and contributing to its success. "
                                                        />
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className="container">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <div sx={{ display: 'flex' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify',
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography variant='h5' className='mt-3 mx-4' style={{
                                            textAlign: 'center', color: '#690808'
                                        }} >
                                            <b>Member benefits </b>
                                        </Typography>
                                        <div className='d-flex justify-content-center flex-wrap'>
                                            <span className='btn btn-md btn-dark me-3 mt-3' style={{ backgroundColor: '#690808' }}>1. Information on growth and funding opportunities</span>
                                            <span className='btn btn-md btn-dark me-3 mt-3' style={{ backgroundColor: '#690808' }}>2. Capacity-building</span>
                                            <span className='btn btn-md btn-dark me-3 mt-3' style={{ backgroundColor: '#690808' }}>3. Advocacy and policy support</span>
                                            <span className='btn btn-md btn-dark me-3 mt-3' style={{ backgroundColor: '#690808' }}>4. Market access and trade facilitation</span>
                                            <span className='btn btn-md btn-dark me-3 mt-3' style={{ backgroundColor: '#690808' }}>5. Brand promotion and visibility</span>
                                        </div>
                                    </CardContent>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className="container my-3 pt-3">
                <div className='mb-4 d-flex justify-content-center' style={{ width: '100%', color: '#690808' }}>
                    <h5><b>Learn More</b></h5>
                </div>
                <Box sx={{ width: '100%', borderLeft: '1px solid #690808', borderBottom: '3px solid #690808', borderRight: '1px solid #690808' }}>
                    <Stepper nonLinear activeStep={activeStepAlt} sx={{ flexWrap: 'wrap' }}>
                        {steps.map((label, index) => (
                            <Step key={label} completed={completed[index]}>
                                <StepButton color="inherit" onClick={handleStep(index)}>
                                    <b style={{ color: '#690808' }}>{label}</b>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                                {
                                    activeStepAlt === 0 ? (
                                        <>
                                            <div className="container">
                                                <Typography variant='h5' className='mt-3 mx-4' style={{
                                                    textAlign: 'center', color: '#690808'
                                                }} >
                                                    <b>Our Initiatives</b>
                                                </Typography>
                                                <Timeline position="right" sx={{
                                                    [`& .${timelineOppositeContentClasses.root}`]: {
                                                        flex: 0.2,
                                                    },
                                                }} className='ms-0' >
                                                    <TimelineItem className='ms-0'>
                                                        <TimelineOppositeContent
                                                            sx={{ m: 'auto 0' }}
                                                            align="right"
                                                            variant="body2"
                                                            color="primary"
                                                            className='d-none d-md-block'
                                                        >
                                                            <h6 className='d-none d-md-block'><b>August - October 2023</b></h6>
                                                        </TimelineOppositeContent>
                                                        <TimelineSeparator
                                                            className='d-none d-md-block'>
                                                            <TimelineConnector />
                                                            <TimelineDot color='primary'>
                                                                <EventAvailableIcon />
                                                            </TimelineDot>
                                                            <h6 style={{ color: '#690808' }} className='d-md-none'><b>August - October 2023</b></h6>
                                                            <TimelineConnector />
                                                        </TimelineSeparator>
                                                        <TimelineContent sx={{ py: '12px' }}>
                                                            <Alert severity="success">
                                                                <AlertTitle style={{ textAlign: 'start' }}><b>Tech Transformation Initiative (TTI)</b></AlertTitle>
                                                                <hr />
                                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                                    The Tech Transformation Initiative is a 3-month program designed to help members of AfYBC transform their businesses through the adoption of technology. The program will provide members with the necessary knowledge and tools to leverage technology to improve their business processes, increase efficiency, and drive growth.
                                                                </Typography>
                                                            </Alert>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                    <TimelineItem className='ms-0'>
                                                        <TimelineOppositeContent
                                                            sx={{ m: 'auto 0' }}
                                                            align="right"
                                                            variant="body2"
                                                            color="primary"
                                                            className='d-none d-md-block'
                                                        >
                                                            <h6 className='d-none d-md-block'><b>July  - August 2023</b></h6>
                                                        </TimelineOppositeContent>
                                                        <TimelineSeparator
                                                            className='d-none d-md-block'>
                                                            <TimelineConnector />
                                                            <TimelineDot color='primary'>
                                                                <EventAvailableIcon />
                                                            </TimelineDot>
                                                            <h6 style={{ color: '#690808' }} className='d-md-none'><b>July  - August 2023</b></h6>
                                                            <TimelineConnector />
                                                        </TimelineSeparator>
                                                        <TimelineContent sx={{ py: '12px' }}>
                                                            <Alert severity="success">
                                                                <AlertTitle style={{ textAlign: 'start' }}><b>Sustainable Futures Initiative</b></AlertTitle>
                                                                <hr />
                                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                                    The Sustainable Futures Initiative is a 2-month online program designed for members of the Afrikan Youth Business Council (AfYBC) who are committed to building sustainable and socially responsible businesses. The program aims to equip young entrepreneurs with the knowledge and tools needed to adopt sustainable business practices, promote environmental responsibility, and integrate Environmental, Social, and Governance (ESG) principles into their business operations.
                                                                </Typography>
                                                            </Alert>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                    <TimelineItem className='ms-0'>
                                                        <TimelineOppositeContent
                                                            sx={{ m: 'auto 0' }}
                                                            align="right"
                                                            variant="body2"
                                                            color="primary"
                                                            className='d-none d-md-block'
                                                        >
                                                            <h6 className='d-none d-md-block'><b>Year-Round</b></h6>
                                                        </TimelineOppositeContent>
                                                        <TimelineSeparator
                                                            className='d-none d-md-block'>
                                                            <TimelineConnector />
                                                            <TimelineDot color='primary'>
                                                                <EventAvailableIcon />
                                                            </TimelineDot>
                                                            <h6 style={{ color: '#690808' }} className='d-md-none'><b> Year-Round</b></h6>
                                                            <TimelineConnector />
                                                        </TimelineSeparator>
                                                        <TimelineContent sx={{ py: '12px' }}>
                                                            <Alert severity="success">
                                                                <AlertTitle style={{ textAlign: 'start' }}><b> AfYBC Youth Connect </b></AlertTitle>
                                                                <hr />
                                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                                    The AfCFTA Youth Connect is a year-round initiative designed to facilitate and enhance the participation of young entrepreneurs in the African Continental Free Trade Area (AfCFTA). The program aims to connect youth-led businesses with AfCFTA authorities and regulators in their countries, providing them with access to information, benefits, and in-country contacts available under AfCFTA. The initiative seeks to help young entrepreneurs grow and expand their businesses across borders, thereby contributing to economic growth and sustainable development in Africa.
                                                                </Typography>
                                                            </Alert>
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                </Timeline>
                                                <Alert severity="success">
                                                    <AlertTitle style={{ textAlign: 'start' }}><b>Eligibility for our Initiatives</b></AlertTitle>
                                                    <hr />
                                                    <Typography className='mt-3' style={{ textAlign: 'justify' }} >
                                                        <ul>
                                                            <li>Membership: Only active members of the AfYBC who have paid their membership fees and are in good standing with the organization will be eligible to participate.</li>
                                                            <li>Business Type: Participants must own or manage a business that is involved in a sector that can benefit from technology adoption, such as e-commerce, fintech, agribusiness, manufacturing, logistics, or any other sector that is relevant to the AfCFTA.</li>
                                                            <li>Commitment: Participants must be committed to completing the 2-month program and implementing the knowledge and tools they acquire during the program to transform their businesses.</li>
                                                            <li>Technology Infrastructure: Participants must have access to the necessary infrastructure such as computers, internet connectivity, and other relevant equipment that is required to implement the technology solutions that will be covered during the program.</li>
                                                            <li>Language proficiency: Participants must be proficient in the language of instruction, which is English.</li>
                                                            <li>Commitment to AfCFTA: Applicants should be committed to taking advantage of the opportunities offered by AfCFTA and contributing to its success.</li>
                                                        </ul>
                                                    </Typography>
                                                </Alert>
                                            </div >
                                        </>
                                    ) : activeStepAlt === 1 ? (
                                        <>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>Partner With Us</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    We invite potential partners to join us in our mission to empower African youth-led businesses through our three initiatives: AfYBC Tech Transformation Initiative, Sustainable Futures Initiative, and AfYBC Youth Connect Initiative. As a partner, you have the opportunity to contribute to the growth and development of African businesses and entrepreneurs by adding value to our members and supporting the implementation of our initiatives. Our focus on technology, sustainability, and connecting young entrepreneurs with AfCFTA authorities and regulators provides a unique opportunity for partners to align with their values and make a meaningful impact on the continent. Join us in building a vibrant and thriving business community in Africa
                                                </Typography>
                                            </Alert>
                                        </>
                                    ) : activeStepAlt === 2 ? (
                                        <>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>Coming Soon.....</b></AlertTitle>
                                            </Alert>
                                        </>
                                    ) : activeStepAlt === 3 ? (
                                        <>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>Join the AfYBC Team and make a difference in youth entrepreneurship in Africa</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    The Afrikan Youth Business Council (AfYBC) is a pan-African organization that works to promote youth entrepreneurship and economic development on the continent. We are currently looking for passionate and committed volunteers to join our team and help us achieve our mission.
                                                </Typography>
                                                <Typography className='mt-3' style={{ textAlign: 'justify' }} >
                                                    As a volunteer with AfYBC, you will have the opportunity to:
                                                    <ul>
                                                        <li>Contribute to the development of youth entrepreneurship in Africa</li>
                                                        <li>Gain valuable skills and experience in areas such as project management, communications, and advocacy</li>
                                                        <li>Network with other young entrepreneurs and leaders across the continent</li>
                                                        <li>Make a meaningful impact on the lives of young people in your community and beyond</li>
                                                    </ul>
                                                </Typography>
                                                <Typography className='mt-3' style={{ textAlign: 'justify' }} >
                                                    We are looking for volunteers who:
                                                    <ul>
                                                        <li>Are passionate about youth entrepreneurship and economic development in Africa</li>
                                                        <li>Have a strong commitment to our mission and values</li>
                                                        <li>Have excellent communication and interpersonal skills</li>
                                                        <li>Are willing to dedicate time and effort to support our activities and initiatives</li>
                                                    </ul>
                                                </Typography>
                                                <Typography className='mt-3' style={{ textAlign: 'justify' }} >
                                                    Volunteer opportunities with AfYBC include:
                                                    <ul>
                                                        <li>Project management: helping to plan and execute our capacity-building programs, advocacy campaigns, and other initiatives</li>
                                                        <li>Communications and marketing: supporting our social media, website, and other communications channels to promote our work and engage with our audience</li>
                                                        <li>Research and data analysis: conducting research and data analysis on issues related to youth entrepreneurship and economic development in Africa</li>
                                                        <li>Event management: helping to organize and coordinate our events, workshops, and conferences</li>
                                                        <li>Fundraising and partnership development: identifying and pursuing funding opportunities and partnerships to support our mission and activities</li>
                                                    </ul>
                                                </Typography>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    To apply, please send your CV or resume and a brief cover letter explaining your interest in volunteering with AfYBC and the skills and experience you can bring to our team.
                                                </Typography>
                                            </Alert>
                                        </>
                                    ) : null
                                }
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', py: 2 }}>
                                <Button size="small" onClick={handleBackStep} sx={{ mr: 1 }} disabled={activeStepAlt === 0}>
                                    <b>
                                        {theme.direction === 'rtl' ? (
                                            <KeyboardArrowRight />
                                        ) : (
                                            <KeyboardArrowLeft />
                                        )}
                                        Back
                                    </b>
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button
                                    size="small"
                                    onClick={handleNextStep}
                                    sx={{ mr: 1 }}
                                >
                                    <b>
                                        Next
                                        {theme.direction === 'rtl' ? (
                                            <KeyboardArrowLeft />
                                        ) : (
                                            <KeyboardArrowRight />
                                        )}
                                    </b>
                                </Button>
                            </Box>
                        </React.Fragment>
                    </div>
                </Box>
            </div>
        </div >
    );
}

export default Afybc;
