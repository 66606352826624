import { Backdrop, Box, CircularProgress, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import visionMission from 'assets/images/home/Page-Heager.png';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPhotos } from 'store/photoGallery/photoGallerySlice';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const PhotoGallery = () => {
    const [expanded, setExpanded] = React.useState({});
    const photos = useSelector(state => state.photoGallery.photos)
    const photoStatus = useSelector(state => state.photoGallery.status)
    // const error = useSelector(state => state.photoGallery.error)
    const dispatch = useDispatch()

    const apiKey = "8b303ffcfe44b49356627cd726be62df";
    const userId = "194655740@N08";

    React.useEffect(() => {
        if (photoStatus === 'idle') {
            dispatch(fetchPhotos({ userId, apiKey }))
        }
    }, [photoStatus, dispatch])

    React.useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className="App">
            <Box>
                <Grid className=' mt-4 py-5' container direction="row" spacing={3} style={{
                    textAlign: 'justify',
                    backgroundImage: `url(${visionMission})`,
                }}>
                    <Grid item md={6}></Grid>
                    <Grid item md={6} sm={12}>
                        <Box className='my-5 ms-5 pb-3 d-flex flex-column text-light' sx={{
                            background: 'linear-gradient(to top, rgba(0,0,0,0.2), rgba(0,0,0,0)), linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 300px)'
                        }}>
                            <h1 className='mt-2 me-5 text-center'>YouLead Gallery</h1>
                            <Typography className='me-5' style={{ textAlign: 'center' }} >
                                A taste of our Events.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ width: '100%', minHeight: 393 }}>
                    <Masonry className='ps-2' columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 6 }} sx={{ width: '100%' }} spacing={2}>
                        {photos && photos.map((photo) => (
                            <Item key={photo.id} >
                                <img
                                    src={`https://farm${photo.farm}.staticflickr.com/${photo.server}/${photo.id}_${photo.secret}.jpg`}
                                    alt={photo.title}
                                    loading="lazy"
                                    style={{
                                        objectFit: 'cover',
                                        objectPosition: 'top center', // Keeps the top of the image in view,
                                        width: '100%'
                                    }}
                                />
                            </Item>
                        ))}
                    </Masonry>
                </Box>
            </Box>

            <Backdrop
                sx={{ color: '#fff', zIndex: 99998 }}
                open={photoStatus === 'loading'}
            >
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <CircularProgress color="inherit" />
                    <span className="mt-2">Please wait...</span>
                </div>
            </Backdrop>
        </div>
    );
}
export default PhotoGallery;