import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import {
    Card as JoyCard, CardContent as JoyCardContent, CardCover as JoyCardCover, Typography as JoyTypography
} from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import {
    Alert,
    AlertTitle,
    Box,
    Card, CardContent,
    CardHeader,
    CardMedia,
    Grid,
    IconButton,
    Typography,
    useMediaQuery, useTheme
} from '@mui/material';
import waves from 'assets/images/home/paint.svg';
import { API_URL } from 'helpers/apiHelper';
import { formatDateOnly } from 'helpers/utilityHelpers';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ProjectsSingle = () => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
    const isXl = useMediaQuery(theme.breakpoints.up('xl'));

    let width = '100%';  // Default

    if (isXs) {
        width = '100%';
    } else if (isSm) {
        width = '100%';
    } else if (isMd) {
        width = '80%';
    } else if (isLg) {
        width = '70%';
    } else if (isXl) {
        width = '60%';
    }
    const navigate = useNavigate();

    React.useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const selectedProject = useSelector(state => state.project.selectedProject)

    React.useEffect(() => {
        if (!selectedProject || Object.keys(selectedProject).length === 0) {
            navigate('/projects');
        }
    }, [selectedProject]);

    return (
        <div className="App">
            <div className="container mt-5 pt-3">
                <CssVarsProvider>
                    <JoyCard sx={{ minHeight: '400px', width: '100%' }}>
                        <JoyCardCover>
                            <img
                                src={API_URL + selectedProject.image}
                                srcSet={API_URL + selectedProject.image}
                                loading="lazy"
                                alt=""
                                style={{
                                    objectFit: 'cover',
                                    objectPosition: 'top center', // Keeps the top of the image in view
                                }}
                            />
                        </JoyCardCover>
                        <JoyCardCover
                            sx={{
                                background:
                                    'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                            }}
                        />
                        <JoyCardContent sx={{ justifyContent: 'flex-end' }}>
                            <JoyTypography level="title-lg" textColor="#fff" sx={{ fontSize: 'xx-large' }}>
                                {selectedProject.title}
                            </JoyTypography>
                            {selectedProject.shortName !== null ? (
                                <JoyTypography
                                    textColor="neutral.200" sx={{ fontSize: 'x-large' }}
                                >
                                    ({selectedProject.shortName})
                                </JoyTypography>
                            ) : null}
                            <JoyTypography textColor="neutral.200" sx={{ fontSize: 'x-large' }} >
                                <b>{formatDateOnly(selectedProject.projectStartDate)} {selectedProject.projectEndDate !== null ? ' to ' + formatDateOnly(selectedProject.projectEndDate) : null}</b>
                            </JoyTypography>
                        </JoyCardContent>
                    </JoyCard>
                </CssVarsProvider>
            </div>
            <div className="container">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: { xs: 'wrap', sm: 'wrap', md: 'wrap', lg: 'nowrap' } }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify',
                                    backgroundImage: `url(${waves})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center'
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        {selectedProject.shortName !== null ? (
                                            <>
                                                <Typography variant='h5' style={{
                                                    textAlign: 'center', color: '#690808'
                                                }} >
                                                    <b>{selectedProject.shortName}</b>
                                                </Typography>
                                            </>
                                        ) : null}
                                        <Typography variant='span' className='mt-3' style={{
                                            textAlign: 'justify', color: '#690808'
                                        }} >
                                            <div dangerouslySetInnerHTML={{ __html: selectedProject.summary }}></div>
                                        </Typography>
                                    </CardContent>
                                </Box>
                                <CardMedia
                                    className='pe-3 pt-3 pb-4'
                                    component="img"
                                    sx={{ width: '50%' }}
                                    image={API_URL + selectedProject.image}
                                    alt=""
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className="container">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <div sx={{ display: 'flex' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify',
                                    // backgroundImage: `url(${waves})`,
                                    // backgroundSize: 'cover',
                                    // backgroundRepeat: 'no-repeat',
                                    // backgroundPosition: 'center'
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography variant='h5' className='mt-3 mx-4' style={{
                                            textAlign: 'center', color: '#690808'
                                        }} >
                                            <b>Project Detail</b>
                                        </Typography>
                                        <Typography variant='span' className='mt-3 mx-4' style={{
                                            textAlign: 'justify'
                                        }} >
                                            <div dangerouslySetInnerHTML={{ __html: selectedProject.detail }}></div>
                                        </Typography>
                                        {selectedProject && selectedProject.fileUploadPath !== null ? (
                                            <div className='d-flex mb-2'>
                                                <Card sx={{ minWidth: '50%', textAlign: 'start' }}>
                                                    <CardHeader
                                                        action={
                                                            <a href={API_URL + selectedProject.fileUploadPath} target='_blank' download={selectedProject?.fileUploadPath?.split('/').at(-1)}>
                                                                <IconButton aria-label="settings">
                                                                    <FontAwesomeIcon color='#690808' icon={faDownload} />
                                                                </IconButton> <span>Download</span>
                                                            </a>
                                                        }
                                                        title={<h5 className='' style={{ color: '#690808' }}><b>Available Downloads</b></h5>}
                                                        subheader={selectedProject.fileName}
                                                    />
                                                </Card>
                                            </div>
                                        ) : null}
                                        {selectedProject && selectedProject.vision !== null ? (
                                            <div className='d-flex mb-2'>
                                                <Card sx={{ minWidth: '50%', textAlign: 'start' }}>
                                                    <CardHeader
                                                        title={<h5 className='' style={{ color: '#690808' }}><b>Vision</b></h5>}
                                                        subheader={selectedProject.vision}
                                                    />
                                                </Card>
                                            </div>
                                        ) : null}
                                        {selectedProject && selectedProject.mission !== null ? (
                                            <div className='d-flex'>
                                                <Card sx={{ minWidth: '50%', textAlign: 'start' }}>
                                                    <CardHeader
                                                        title={<h5 className='' style={{ color: '#690808' }}><b>Mission</b></h5>}
                                                        subheader={selectedProject.mission}
                                                    />
                                                </Card>
                                            </div>
                                        ) : null}
                                    </CardContent>
                                </Box>
                            </div>
                        </Grid>
                    </Grid >
                </Box >
            </div >
            {
                selectedProject && selectedProject.projectObjectives && selectedProject.projectObjectives.length > 0 ? (
                    <div className="container">
                        <hr />
                        <Typography variant='h5' className='mt-3 mx-4' style={{
                            textAlign: 'center', color: '#690808'
                        }} >
                            <b>Our Objectives</b>
                        </Typography>
                        <Timeline position="right" sx={{
                            [`& .${timelineOppositeContentClasses.root}`]: {
                                flex: 0.2,
                            },
                        }} className='ms-0' >
                            {selectedProject.projectObjectives.map(objective => {
                                return (
                                    <TimelineItem className='ms-0'>
                                        <TimelineOppositeContent
                                            sx={{ m: 'auto 0' }}
                                            align="right"
                                            variant="body2"
                                            color="primary"
                                            className='d-none d-md-block'
                                        >
                                        </TimelineOppositeContent>
                                        <TimelineSeparator
                                            className='d-none d-md-block'>
                                            <TimelineConnector />
                                            <TimelineDot color='primary'>
                                                <EventAvailableIcon />
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px' }}>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>{objective.name}</b></AlertTitle>
                                            </Alert>
                                        </TimelineContent>
                                    </TimelineItem>
                                )
                            })}
                        </Timeline>
                    </div >
                ) : null
            }
            <hr />
            {
                selectedProject && selectedProject.projectTimeline && selectedProject.projectTimeline.length > 0 ? (
                    <div className="container">
                        <Typography variant='h5' className='mt-3 mx-4' style={{
                            textAlign: 'center', color: '#690808'
                        }} >
                            <b>Project Activities</b>
                        </Typography>

                        <div className="container">
                            <Timeline position="right" sx={{
                                [`& .${timelineOppositeContentClasses.root}`]: {
                                    flex: 0.2,
                                },
                            }} className='ms-0' >
                                {selectedProject.projectTimeline.map(timeline => {
                                    return (
                                        <TimelineItem className='ms-0'>
                                            <TimelineOppositeContent
                                                sx={{ m: 'auto 0' }}
                                                align="right"
                                                variant="body2"
                                                color="primary"
                                                className='d-none d-md-block'
                                            >
                                                <h6 className='d-none d-md-block'><b>{timeline.period}</b></h6>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator
                                                className='d-none d-md-block'>
                                                <TimelineConnector />
                                                <TimelineDot color='primary'>
                                                    <EventAvailableIcon />
                                                </TimelineDot>
                                                <h6 style={{ color: '#690808' }} className='d-md-none'><b>{timeline.period}</b></h6>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ py: '12px' }}>
                                                <Alert severity="success">
                                                    <AlertTitle style={{ textAlign: 'start' }}><b>{timeline.title}</b></AlertTitle>
                                                    <hr />
                                                    {timeline.detail != null ? (
                                                        <Typography className='' style={{ textAlign: 'justify' }} >
                                                            {timeline.detail}
                                                        </Typography>) : null}
                                                </Alert>
                                            </TimelineContent>
                                        </TimelineItem>
                                    )
                                })}
                            </Timeline>
                        </div >
                    </div>
                ) : null
            }

            {
                selectedProject && selectedProject.projectImages && selectedProject.projectImages.length > 0 ? (
                    <div className="container mb-5">
                        <hr />
                        <Typography variant='h5' className='mt-5 mb-3 mx-4' style={{
                            textAlign: 'center', color: '#690808'
                        }} >
                            <b>Gallery</b>
                        </Typography>
                        <Grid container direction="row" spacing={3} style={{
                            textAlign: 'justify'
                        }}>
                            {selectedProject.projectImages.map(image => {
                                return (
                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                        <CssVarsProvider>
                                            <JoyCard sx={{ minHeight: '300px', width: '100%' }}>
                                                <JoyCardCover>
                                                    <img
                                                        src={API_URL + image.image}
                                                        srcSet={API_URL + image.image}
                                                        loading="lazy"
                                                        alt=""
                                                        style={{
                                                            objectFit: 'cover',
                                                            objectPosition: 'top center', // Keeps the top of the image in view
                                                        }}
                                                    />
                                                </JoyCardCover>
                                                <JoyCardCover
                                                    sx={{
                                                        background:
                                                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                                    }}
                                                />
                                            </JoyCard>
                                        </CssVarsProvider>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </div>
                ) : null
            }
        </div >
    );
}

export default ProjectsSingle;
