import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NearbyError } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Backdrop, Box, CardActions, CardContent, CircularProgress, Collapse, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import visionMission from 'assets/images/home/Page-Heager.png';
import { API_URL } from 'helpers/apiHelper';
import { formatDateOnly } from 'helpers/utilityHelpers';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchResearchPublications } from 'store/researchPublication/researchPublicationSlice';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const ResearchPublications = () => {
    const [expanded, setExpanded] = React.useState({});
    const researchPublications = useSelector(state => state.researchPublication.researchPublications)
    const researchPublicationStatus = useSelector(state => state.researchPublication.status)
    // const error = useSelector(state => state.researchPublication.error)
    const dispatch = useDispatch()

    React.useEffect(() => {
        if (researchPublicationStatus === 'idle') {
            dispatch(fetchResearchPublications())
        }
    }, [researchPublicationStatus, dispatch])

    React.useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (researchPublications && researchPublications.length > 0) {
            let obj = {}
            researchPublications.map((researchPublication) => {
                obj['researchPublication' + researchPublication.id] = false
            })
            setExpanded({ ...obj });
        }
    }, [researchPublications])

    const handleExpandClick = (researchPublicationId) => {
        let obj = { ...expanded }
        Object.keys(obj).map((key) => {
            if (key === ('researchPublication' + researchPublicationId)) {
                obj[key] = obj[key] !== true
            } else {
                obj[key] = false
            }
        })
        setExpanded({ ...obj });
    };

    return (
        <div className="App">
            <Box>
                <Grid className=' mt-4 py-5' container direction="row" spacing={3} style={{
                    textAlign: 'justify',
                    backgroundImage: `url(${visionMission})`,
                }}>
                    <Grid item md={6}></Grid>
                    <Grid item md={6} sm={12}>
                        <Box className='my-5 ms-5 pb-3 d-flex flex-column text-light' sx={{
                            background: 'linear-gradient(to top, rgba(0,0,0,0.2), rgba(0,0,0,0)), linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 300px)'
                        }}>
                            <h1 className='mt-2 me-5 text-center'>YouLead Research Publications</h1>
                            <Typography className='me-5' style={{ textAlign: 'center' }} >
                                Get to know what we have put out there.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                {researchPublications && researchPublications.length > 0 ? (
                    <Grid className=' mt-2 mb-5 mx-2' container direction="row" spacing={3}>
                        {researchPublications.map((researchPublication, index) => {
                            return (
                                <Grid item lg={4} md={6} sm={12}>
                                    <Card sx={{ maxWidth: '100%', textAlign: 'start' }}>
                                        <CardHeader
                                            action={
                                                <a href={API_URL + researchPublication.fileUploadPath} target='_blank' download={researchPublication.fileUploadPath.split('/').at(-1)}>
                                                    <IconButton aria-label="settings">
                                                        <FontAwesomeIcon color='#690808' icon={faDownload} />
                                                    </IconButton>
                                                </a>
                                            }
                                            title={<h5 className='' style={{ color: '#690808' }}><b>{researchPublication.title}</b></h5>}
                                            subheader={formatDateOnly(researchPublication.publicationDate)}
                                        />
                                        <CardMedia
                                            component="img"
                                            className='px-5'
                                            sx={{
                                                maxHeight: '550px',
                                            }}
                                            image={API_URL + researchPublication.image}
                                            alt="researchPublication Banner"
                                        />
                                        {/* <CardContent>
                                            <Typography variant="body2" color="text.secondary">
                                                This impressive paella is a perfect party dish and a fun meal to cook
                                                together with your guests. Add 1 cup of frozen peas along with the mussels,
                                                if you like.
                                            </Typography>
                                        </CardContent> */}
                                        <CardActions disableSpacing style={{ borderTop: '2px solid grey' }}>
                                            <h6 className='ms-3' style={{ color: '#690808' }}>
                                                <b>By: {researchPublication.publisherNames}</b>
                                            </h6>
                                            <ExpandMore
                                                expand={expanded['researchPublication' + researchPublication.id]}
                                                onClick={() => { handleExpandClick(researchPublication.id) }}
                                                aria-expanded={expanded['researchPublication' + researchPublication.id]}
                                                aria-label="show more"
                                            >
                                                <h6 className='btn btn-sm' style={{ backgroundColor: '#690808', color: 'white' }}>
                                                    <ExpandMoreIcon />
                                                </h6>
                                            </ExpandMore>
                                        </CardActions>
                                        <Collapse in={expanded['researchPublication' + researchPublication.id]} timeout="auto" unmountOnExit>
                                            <CardContent>
                                                <hr className='mt-0' />
                                                <div dangerouslySetInnerHTML={{ __html: researchPublication.summary }}>
                                                </div>
                                                <div className='d-flex justify-content-end align-items-end'>
                                                    <ExpandMore
                                                        expand={expanded['researchPublication' + researchPublication.id]}
                                                        onClick={() => { handleExpandClick(researchPublication.id) }}
                                                        aria-expanded={expanded['researchPublication' + researchPublication.id]}
                                                        aria-label="show more"
                                                    >
                                                        <h6 className='badge' style={{ backgroundColor: '#690808' }}><ExpandMoreIcon /></h6>
                                                    </ExpandMore>
                                                </div>
                                            </CardContent>
                                        </Collapse>
                                    </Card>
                                </Grid>
                                // <TimelineItem key={index} className='ms-0'>
                                //     <TimelineOppositeContent
                                //         sx={{ m: 'auto 0' }}
                                //         align="right"
                                //         variant="body2"
                                //         color="primary"
                                //     >
                                //         <h6 className='d-none d-md-block'><b>{formatDate(researchPublication.createdTimestamp)}</b></h6>
                                //     </TimelineOppositeContent>
                                //     <TimelineSeparator>
                                //         <TimelineConnector />
                                //         <TimelineDot color='primary'>
                                //             <EventAvailableIcon />
                                //         </TimelineDot>
                                //         <h6 style={{ color: '#690808' }} className='d-md-none'><b>{formatDate(researchPublication.createdTimestamp)}</b></h6>
                                //         <TimelineConnector />
                                //     </TimelineSeparator>
                                //     <TimelineContent sx={{ py: '12px', px: 2 }}>
                                //         <Card sx={{ maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '70%' }, borderBottom: '3px solid #690808', borderRight: '1px solid #690808', borderLeft: '1px solid #690808', borderTop: '1px solid #690808' }} style={{
                                //             backgroundImage: `url(${waves})`,
                                //         }}>
                                //             <CardHeader
                                //                 className='ms-0 ps-0 mb-0 pb-0'
                                //                 // avatar={
                                //                 //     <Avatar className='d-none d-md-block ms-3' sx={{ bgcolor: '#690808' }} aria-label="recipe">
                                //                 //         {/* <img src={logo} className='img-fluid' alt="" /> */}
                                //                 //     </Avatar>
                                //                 // }
                                //                 title={<h5 className='ms-3' style={{ color: '#690808' }}><b>{researchPublication.title}</b></h5>}
                                //                 subheader={<>
                                //                     <div className='ms-3 d-flex justify-content-between align-items-center'>
                                //                         <div className='d-flex justify-content-between align-items-center flex-wrap'>
                                //                             <div className='me-5'>
                                //                                 <h6><b><span style={{ fontSize: 'smaller', fontWeight: '800' }}>Published By:</span></b></h6>
                                //                                 <h6><b><span className='text-success mt-2'>{(researchPublication.publisherNames)}</span></b></h6>
                                //                                 <h6><b><span className='text-danger mt-2'>{(researchPublication.publisherTitle)}</span></b></h6>
                                //                             </div>
                                //                             {/* <h6 style={{ color: '#690808' }}><b>{researchPublication.numberOfApplicants} Applicants</b></h6> */}
                                //                         </div>
                                //                     </div>
                                //                 </>}
                                //             />
                                //             {/* <CardContent className='d-lg-none'>
                                //                 <div className='d-flex justify-content-center align-items-center flex-wrap'>
                                //                     <div>
                                //                         {compareDates(new Date(), researchPublication.researchPublication.applicationCloseTimestamp) > 0 ? (
                                //                             <button className='btn btn-sm btn-danger'>Applications Closed</button>
                                //                         ) : (
                                //                             <button className='btn btn-sm btn-success' style={{ backgroundColor: '#690808' }} onClick={() =>
                                //                                 window.open('https://portal.youlead.africa/public/researchPublications', '_blank')}>Apply Now</button>
                                //                         )}
                                //                     </div>
                                //                 </div>
                                //             </CardContent> */}
                                //             <CardMedia
                                //                 component="img"
                                //                 className='px-5'
                                //                 sx={{
                                //                     maxHeight: '550px',
                                //                     // borderBottom: '1px solid #690808',
                                //                     // borderLeft: '1px solid #690808', 
                                //                     // borderRadius:'2%',
                                //                     // background:
                                //                     //     'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 500px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 550px)',
                                //                 }}
                                //                 // height="250"
                                //                 image={API_URL + researchPublication.image}
                                //                 alt="researchPublication Banner"
                                //             />
                                //             <CardActions disableSpacing>
                                //                 <IconButton aria-label="Instagram" color='primary' onClick={() =>
                                //                     window.open('https://www.instagram.com/oneyoungafrica/?hl=en', '_blank')}>
                                //                     <Instagram />
                                //                 </IconButton>
                                //                 <IconButton aria-label="Youtube" color='primary' onClick={() =>
                                //                     window.open('https://www.youtube.com/@youleadafrica', '_blank')}>
                                //                     <YouTube />
                                //                 </IconButton>
                                //                 <IconButton aria-label="Twitter" color='primary' onClick={() =>
                                //                     window.open('https://twitter.com/OneYoungAfrica?ref_src=twsrc%5Etfw', '_blank')}>
                                //                     <Twitter />
                                //                 </IconButton>
                                //                 <IconButton aria-label="Facebook" color='primary' onClick={() =>
                                //                     window.open('https://www.facebook.com/OneYoungAfrica/', '_blank')}>
                                //                     <Facebook />
                                //                 </IconButton>
                                //                 <IconButton aria-label="Email" color='primary' onClick={() =>
                                //                     window.open('mailto:youlead@youlead.africa', '_blank')}>
                                //                     <AlternateEmail />
                                //                 </IconButton>
                                //                 <ExpandMore
                                //                     expand={expanded['researchPublication' + researchPublication.id]}
                                //                     onClick={() => { handleExpandClick(researchPublication.id) }}
                                //                     aria-expanded={expanded['researchPublication' + researchPublication.id]}
                                //                     aria-label="show more"
                                //                 >
                                //                     <h6 className='btn btn-sm' style={{ backgroundColor: '#690808', color: 'white' }}>{expanded['researchPublication' + researchPublication.id] === false ? (<span className='d-none d-md-inline'>Read more</span>) : null} <ExpandMoreIcon /></h6>
                                //                 </ExpandMore>
                                //             </CardActions>
                                //             <Collapse in={expanded['researchPublication' + researchPublication.id]} timeout="auto" unmountOnExit>
                                //                 <CardContent style={{
                                //                     backgroundImage: `url(${wavesGrey})`,
                                //                 }}>
                                //                     <hr className='mt-0' />
                                //                     <Typography paragraph dangerouslySetInnerHTML={{ __html: researchPublication.summary }}>
                                //                     </Typography>
                                //                     <div className='d-flex justify-content-end align-items-end'>
                                //                         <ExpandMore
                                //                             expand={expanded['researchPublication' + researchPublication.id]}
                                //                             onClick={() => { handleExpandClick(researchPublication.id) }}
                                //                             aria-expanded={expanded['researchPublication' + researchPublication.id]}
                                //                             aria-label="show more"
                                //                         >
                                //                             <h6 className='badge' style={{ backgroundColor: '#690808' }}><ExpandMoreIcon /></h6>
                                //                         </ExpandMore>
                                //                     </div>
                                //                 </CardContent>
                                //             </Collapse>
                                //         </Card>
                                //     </TimelineContent>
                                // </TimelineItem>
                            )
                        })
                        }
                    </Grid>
                ) : (
                    <Card variant="elevation" >
                        <div className="my-5 d-flex flex-column justify-content-center align-items-center">
                            <NearbyError className='mt-5 mb-3' fontSize='large' />
                            <h5><b className="text-danger">No Research Publications To Show At The Moment.</b></h5>
                        </div>
                    </Card>
                )}
            </Box>

            <Backdrop
                sx={{ color: '#fff', zIndex: 99998 }}
                open={researchPublicationStatus === 'loading'}
            >
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <CircularProgress color="inherit" />
                    <span className="mt-2">Please wait...</span>
                </div>
            </Backdrop>
        </div >
    );
}
export default ResearchPublications;