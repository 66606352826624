import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { postNewsLetterSubscriptions } from 'helpers/backendHelper'

const initialState = {
    newsLetterSubscriptions: {},
    status: 'idle',
    error: null
}

export const newsLetterSubscriptionsSlice = createSlice({
    name: 'newsLetterSubscriptions',
    initialState,
    reducers: {
        newsLetterSubscriptionsAdded: {
            reducer(state, action) {
                state.newsLetterSubscriptions = (action.payload)
            },
            prepare(title, content, userId) {
                // omit prepare logic
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(postNewsLetterSubscription.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(postNewsLetterSubscription.fulfilled, (state, action) => {
                state.status = 'idle'
                // Add any fetched newsLetterSubscriptions to the array
                state.newsLetterSubscriptions = action.payload
            })
            .addCase(postNewsLetterSubscription.rejected, (state, action) => {
                state.status = 'idle'
                state.error = action.error.message
            })
    }
})

// Action creators are generated for each case reducer function
export const { newsLetterSubscriptionsAdded } = newsLetterSubscriptionsSlice.actions

export default newsLetterSubscriptionsSlice.reducer

export const postNewsLetterSubscription = createAsyncThunk('newsLetterSubscriptions/postNewsLetterSubscription', async (data) => postNewsLetterSubscriptions(data))