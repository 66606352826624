import React, { useState } from 'react';
import YouTube from 'react-youtube';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';

const YouTubePlayer = ({ videoId, videoPlayList = [], autoplay = 0, controls = 1, mute = false, loop = 0, disablekb = 0 }) => {
    const [player, setPlayer] = useState(null);
    const [isMuted, setIsMuted] = useState(mute);
    const opts = {
        width: '100%',
        height: '100%',
        playerVars: {
            autoplay: autoplay,
            controls: controls,
            mute: isMuted ? 1 : 0,
            loop: loop,
            modestbranding: 1,
            rel: 0,
            showinfo: 0,
            disablekb: disablekb,
            playlist: videoPlayList
        },
    };

    const onReady = (event) => {
        let ytp = event.target
        setPlayer(ytp);
    };

    const toggleMute = () => {
        if (player) {
            isMuted ? player.unMute() : player.mute();
            setIsMuted(!isMuted);
        }
    };

    return (
        <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
            <YouTube
                videoId={videoId}
                opts={opts}
                onReady={onReady}
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            />
            <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                <button className='btn btn-md btn-light' onClick={toggleMute} style={{ margin: '10px', minWidth: '115px' }}>
                    {isMuted ? (
                        <span style={{ color: '#560101' }}><VolumeDownIcon /> Un-Mute</span>
                    ) : (
                        <span style={{ color: '#560101' }}><VolumeOffIcon /> Mute</span>
                    )}
                </button>
            </div>
        </div>
    );
}

export default YouTubePlayer;
