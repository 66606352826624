import { SelfImprovement } from '@mui/icons-material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card, CardContent, CardMedia, Grid,
    MobileStepper,
    Step,
    StepButton,
    Stepper,
    Typography,
    useMediaQuery, useTheme
} from '@mui/material';
import visionMission from 'assets/images/home/Vision-Mission.png';
import youLeadBackgroundFull from 'assets/images/home/YouLeadBackground.png';
import waves from 'assets/images/home/waves-opacity-20.svg';
import coverner1 from 'assets/images/summit/coverner-1.png';
import coverner2 from 'assets/images/summit/coverner-2.png';
import coverner3 from 'assets/images/summit/coverner-3.png';
import coverner4 from 'assets/images/summit/coverner-4.png';
import partners from 'assets/images/summit/partners.png';
import partners2 from 'assets/images/summit/partners2.png';
import summit from 'assets/images/summit22/summit.png';
import YouTubePlayer from 'common/youtubePlayer';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const YouLeadSummit22 = () => {
    const dispatch = useDispatch()
    const refs = useRef([]);

    const addToRefs = (el) => {
        if (el && !refs.current.includes(el)) {
            refs.current.push(el);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate__animated', 'animate__fadeInRight');
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1,
            }
        );

        refs.current.forEach((ref) => {
            observer.observe(ref);
        });

        return () => {
            refs.current.forEach((ref) => {
                observer.unobserve(ref);
            });
        };
    }, []);

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
    const isXl = useMediaQuery(theme.breakpoints.up('xl'));

    let width = '100%';  // Default
    let widthImages = '100%';  // Default

    if (isXs) {
        width = '100%';
        widthImages = '100%';
    } else if (isSm) {
        width = '90%';
        widthImages = '100%';
    } else if (isMd) {
        width = '70%';
        widthImages = '60%';
    } else if (isLg) {
        width = '60%';
        widthImages = '40%';
    } else if (isXl) {
        width = '50%';
        widthImages = '40%';
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = 4;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const steps = ['12th December 2022', '13th December 2022', '14th December 2022', '15th December 2022', '16th December 2022'];
    const [activeStepAlt, setActiveStepAlt] = React.useState(0);
    const [completed, setCompleted] = React.useState({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStepAlt === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNextStep = () => {
        const newActiveStepAlt =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStepAlt + 1;
        setActiveStepAlt(newActiveStepAlt);
    };

    const handleBackStep = () => {
        setActiveStepAlt((prevActiveStepAlt) => prevActiveStepAlt - 1);
    };

    const handleStep = (step) => () => {
        setActiveStepAlt(step);
    };

    return (
        <div className="App">
            <div className="container mt-5 pt-3">
                <img src={summit} className='img-fluid animate__animated animate__fadeInDown' alt="" style={{ width: '100%' }} />
            </div>
            <Box>
                <Grid className='my-0' container direction="row" spacing={3} style={{
                    textAlign: 'justify',
                    backgroundImage: `url(${visionMission})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}>
                    <Grid item md={5}></Grid>
                    <Grid item md={7} sm={12}>
                        <div className='my-5 ms-5 d-flex flex-column text-light' >
                            <div>
                                <Typography variant='h5' className='me-5 mb-3' style={{ textAlign: 'justify' }} >
                                    <b>EAC's YouLead Summit is back!</b>
                                </Typography>
                                <Typography className='me-5' style={{ textAlign: 'justify' }} >
                                    H.E Jakaya Kikwete, former President of Tanzania, also Patron of
                                    YouLead, H.E. Fatoumata Tambajang, former Vice-President in the
                                    Republic of The Gambia, Hon. Dr. Peter Mathuki, Secretary-General of
                                    the East African Community, Ms. Makena Mwobobia, Executive Director,
                                    MS Training Centre for Development Cooperation, Ms. Angelina Ngalula,
                                    Board Chairperson, East African Business Council are convening the 6th
                                    EAC YouLead Summit 2022 from the 12th to the 16th of December 2022 at
                                    the EAC Headquarters in Arusha Tanzania. The EAC Heads of State are
                                    expected to grace the summit.
                                </Typography>
                                <Typography className='me-5 mt-3' style={{ textAlign: 'justify' }} >
                                    The theme for the YouLead Summit 2022 is:
                                </Typography>
                                <Typography variant='h5' className='me-5 mt-3' style={{ textAlign: 'justify' }} >
                                    <b>From Policies To Results, Equality, Education, Digital Access & Future of Work</b>
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <div className="container">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <div sx={{ display: 'flex' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify',
                                    // backgroundImage: `url(${waves})`,
                                    // backgroundSize: 'cover',
                                    // backgroundRepeat: 'no-repeat',
                                    // backgroundPosition: 'center'
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <div className='mt-3 d-flex justify-content-center' style={{ width: '100%', color: '#690808' }}>
                                            <h5><b>WELCOME MESSAGES</b></h5>
                                        </div>
                                        <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
                                            <AutoPlaySwipeableViews
                                                interval={6000}
                                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                index={activeStep}
                                                onChangeIndex={handleStepChange}
                                                enableMouseEvents
                                            >
                                                {/* <div > */}
                                                <Card ref={addToRefs} sx={{
                                                    display: 'flex', flexWrap: {
                                                        xs: 'wrap', sm: 'wrap', md: 'wrap',
                                                        lg: 'nowrap', xl: 'nowrap'
                                                    },
                                                    backgroundImage: `url(${youLeadBackgroundFull})`,
                                                    color: 'white'
                                                }}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                                        textAlign: 'justify'
                                                    }}>
                                                        <CardContent sx={{ flex: '1 0 auto' }} className='ms-3'>
                                                            <div className='mt-3 d-flex flex-column justify-content-start' style={{ width: '100%' }}>
                                                                <h4><b>H.E. Dr. Jakaya Mrisho Kikwete, former President of Tanzania</b></h4>
                                                                <h5><b>Patron, YouLead Africa</b></h5>
                                                            </div>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                Excellencies, distinguished young people and dear friends, it is my pleasure
                                                                to inform you that this year's YouLead Summit will be held from 12th -16th December
                                                                in Arusha - Tanzania.
                                                            </Typography>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                It is heartwarming indeed to know that the 2022 Summit will also be available to an
                                                                Africa-wide audience via virtual platforms. This year's theme is "From Policies To Results, Equality, Education, Digital Access & Future of Work"
                                                            </Typography>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                I would like to take this opportunity to invite you all to participate actively. I call
                                                                upon development partners, the private sector, media, civil society, and other stakeholders
                                                                to be part of and support the East African Community in convening this year's YouLead Summit.
                                                                We look forward to welcoming you all to the Summit
                                                            </Typography>
                                                        </CardContent>
                                                    </Box>
                                                    <CardMedia
                                                        className='px-5 pt-5 pb-3'
                                                        component="img"
                                                        sx={{ width: widthImages }}
                                                        image={coverner3}
                                                        alt=""
                                                    />
                                                </Card>

                                                <Card ref={addToRefs} sx={{
                                                    display: 'flex', flexWrap: {
                                                        xs: 'wrap', sm: 'wrap', md: 'wrap',
                                                        lg: 'nowrap', xl: 'nowrap'
                                                    },
                                                    backgroundImage: `url(${youLeadBackgroundFull})`,
                                                    color: 'white'
                                                }}>
                                                    <CardMedia
                                                        className='px-5 pt-5 pb-3'
                                                        component="img"
                                                        sx={{ width: widthImages }}
                                                        image={coverner1}
                                                        alt=""
                                                    />
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                                        textAlign: 'justify',
                                                        backgroundImage: `url(${waves})`,
                                                        backgroundSize: 'cover',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'center'
                                                    }}>
                                                        <CardContent sx={{ flex: '1 0 auto' }} className='me-3'>
                                                            <div className='mt-3 d-flex flex-column justify-content-start' style={{ width: '100%' }}>
                                                                <h4><b>Hon. Dr. Peter Mathuki</b></h4>
                                                                <h5><b>Secretary General</b></h5>
                                                                <h5><b>East African Community</b></h5>
                                                            </div>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                The treaty establishing the East African Community is people-driven and the youth form not
                                                                only part of the population but constitute the largest number. That is to say, the community
                                                                belongs to the youth. Therefore, as the EAC, we feel it is important to hold this youth summit.
                                                                We want the youth to know the community belongs to them, encourage them to take responsibility,
                                                                and let them know that now is the time to do so.
                                                            </Typography>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                We are hosting the YouLead Summit 2022 and call upon all youth on the continent to attend. The
                                                                Summit will be co-hosted by His Excellency Jakaya Mrisho Kikwete, the patron of YouLead Africa,
                                                                and myself, the Secretary General of the EAC. We call upon stakeholders, development partners,
                                                                and the private sector to come out and support this event so that together, we can strengthen
                                                                the voice of the youth. We welcome you to Arusha.
                                                            </Typography>
                                                        </CardContent>
                                                        {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src={logoTransparent} className='img-fluid' alt="" style={{ maxWidth: '210px' }} />
                                                </Box> */}
                                                    </Box>
                                                </Card>

                                                <Card ref={addToRefs} sx={{
                                                    display: 'flex', flexWrap: {
                                                        xs: 'wrap', sm: 'wrap', md: 'wrap',
                                                        lg: 'nowrap', xl: 'nowrap'
                                                    },
                                                    backgroundImage: `url(${youLeadBackgroundFull})`,
                                                    color: 'white'
                                                }}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                                        textAlign: 'justify'
                                                    }}>
                                                        <CardContent sx={{ flex: '1 0 auto' }} className='ms-3'>
                                                            <div className='mt-3 d-flex flex-column justify-content-start' style={{ width: '100%' }}>
                                                                <h4><b>Makena Mwobobia </b></h4>
                                                                <h5><b>Executive Director</b></h5>
                                                                <h5><b>MSTCDC</b></h5>
                                                            </div>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                Africa is the richest continent in resources (including human resource), we are fortunate to be able to
                                                                draw on the resourcefulness and creativity of the more than 1.2 billion young people, this demographic
                                                                advantage therefore compels key actors' accountability for practicability of young peoples' engagement
                                                                in political and economic governance.  Governments must invest in their capacity in issues that affect
                                                                young people, stakeholders should keep mobilizing supporters to mobilize young people as an important
                                                                sphere of African development.
                                                            </Typography>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                We must say that the YouLead program has proven that the meaningful engagement, adequate resourcing,
                                                                capacity building, effective leadership and governance, and mentorship of young people can be appropriately
                                                                addressed. To that end, I call upon a greater partnership with our development partners, private sector
                                                                and governments, CSO's and the young people's constituency to make Africa's development a reality by
                                                                bringing together our vast youth resource through the YouLead Summit 2022.
                                                            </Typography>
                                                        </CardContent>
                                                        {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src={logo} className='img-fluid' alt="" />
                                                </Box> */}
                                                    </Box>
                                                    <CardMedia
                                                        className='px-5 pt-5 pb-3'
                                                        component="img"
                                                        sx={{ width: widthImages }}
                                                        image={coverner2}
                                                        alt=""
                                                    />
                                                </Card>

                                                <Card ref={addToRefs} sx={{
                                                    display: 'flex', flexWrap: {
                                                        xs: 'wrap', sm: 'wrap', md: 'wrap',
                                                        lg: 'nowrap', xl: 'nowrap'
                                                    },
                                                    backgroundImage: `url(${youLeadBackgroundFull})`,
                                                    color: 'white'
                                                }}>
                                                    <CardMedia
                                                        className='px-5 pt-5 pb-3'
                                                        component="img"
                                                        sx={{ width: widthImages }}
                                                        image={coverner4}
                                                        alt=""
                                                    />
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                                        textAlign: 'justify',
                                                        backgroundImage: `url(${waves})`,
                                                        backgroundSize: 'cover',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'center'
                                                    }}>
                                                        <CardContent sx={{ flex: '1 0 auto' }} className='me-3'>
                                                            <div className='mt-3 d-flex flex-column justify-content-start' style={{ width: '100%' }}>
                                                                <h4><b>Ivan Atuyambe</b></h4>
                                                                <h5><b>Summit Director, 2022</b></h5>
                                                            </div>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                Conceived as a pilgrimage journey for youth to the EAC Secretariat, the YouLead summit has grown to
                                                                embody more than just a gathering of youth to become the place <b>Where dreams are Born and Youth
                                                                    Become Leaders.</b>
                                                            </Typography>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >

                                                                In the 6th year of the summit, we are excited to receive, for the second time, an Africa-wide delegation
                                                                of youth leaders and policy makers, in governance, business and community for 5 days of benchmarking,
                                                                strategizing and evaluation of achievements and shortfalls since last year's summit.

                                                            </Typography>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                I would like to appreciate our conveners, H.E Jakaya M. Kikwete, Former President of the United Republic
                                                                of Tanzania and YouLead Patron, Hon Dr. Peter Mathuki, EAC Secretary General, Ms Makena Mwobobia,
                                                                Executive Director of MS Training Centre Development Cooperation, the East African Business Council;
                                                                and all our strategic partners who have made it possible to have this year's summit. Great appreciation
                                                                goes also to the African youth without whom there would be no such summit.
                                                            </Typography>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                <b>Welcome to YouLead Summit 2022!</b>
                                                            </Typography>
                                                        </CardContent>
                                                        {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src={logoTransparent} className='img-fluid' alt="" style={{ maxWidth: '210px' }} />
                                                </Box> */}
                                                    </Box>
                                                </Card>
                                            </AutoPlaySwipeableViews>
                                            <MobileStepper
                                                steps={maxSteps}
                                                position="static"
                                                activeStep={activeStep}
                                                nextButton={
                                                    <Button
                                                        size="small"
                                                        onClick={handleNext}
                                                        disabled={activeStep === maxSteps - 1}
                                                    >
                                                        <b>
                                                            Next
                                                            {theme.direction === 'rtl' ? (
                                                                <KeyboardArrowLeft />
                                                            ) : (
                                                                <KeyboardArrowRight />
                                                            )}
                                                        </b>
                                                    </Button>
                                                }
                                                backButton={
                                                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                                        <b>
                                                            {theme.direction === 'rtl' ? (
                                                                <KeyboardArrowRight />
                                                            ) : (
                                                                <KeyboardArrowLeft />
                                                            )}
                                                            Back
                                                        </b>
                                                    </Button>
                                                }
                                            />
                                        </Box>
                                    </CardContent>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <Box>
                <Grid className='my-0' container direction="row" spacing={3} style={{
                    textAlign: 'justify',
                    backgroundImage: `url(${youLeadBackgroundFull})`,
                    color: 'white'
                }}>
                    <div className='container'>
                        <Typography variant='h5' className='mt-3' style={{ textAlign: 'center' }} >
                            <b>The Theme of YouLead Summit 2022</b>
                        </Typography>
                        <Typography className='mt-3 mx-4' style={{ textAlign: 'justify' }} >
                        The selection of this year’s theme particularly seeks to take stock of The Transformational Role of Education in Africa’s development agenda and re-position education as a pre-eminent public good, examine efforts to recover from pandemic-related learning, employment and income losses, renew policy and business leaders’ collective commitment to fighting inequality
                        </Typography>
                        <Typography className='mt-4 mx-4' style={{ textAlign: 'justify' }} >
                            Towards achieving the aspirations for “the Africa We Want,” the young generation should be equipped  with robust and diverse
                            skill sets and knowledge that align and speak to shifting geopolitics, the evolution of commerce, the ongoing digital
                            revolution, and increasingly important capacities like post-conflict reconstruction, climate justice, leadership development,
                            and public policy advocacy. With this newly-acquired or improved capacity, Africa's young people will be able to marshal
                            their individual and collective efforts towards a common development agenda.  Their enhanced understanding of their roles
                            specifically as  young people, women, communities,  and marginalized groups will hasten the crafting and delivery of
                            Africa-led solutions towards the Africa We Want.
                        </Typography>
                        <Typography className='mt-4 mx-4' style={{ textAlign: 'justify' }} >
                            As a bonus point, reckoning that the Summit theme for 2022 was by a majority vote of the young people, the aftermath of the
                            Summit will  shepherd emerging development and investment opportunities in the areas of  human capital development,
                            entrepreneurship, public infrastructure, and policy alternatives. .  All these are inspired by the African Union Agenda 2063
                            and the UN Sustainable Development Goals (SDGs).
                        </Typography>
                        <Typography className='mt-4 mx-4' style={{ textAlign: 'justify' }} >
                            Under YouLead Africa's popular programmatic pillars,  exciting fora will be held through interactive and participatory
                            approaches.
                        </Typography>
                    </div>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        marginY: 4
                    }}>
                        <div style={{ width }}>
                            <YouTubePlayer key={'mFS8Fm6C2To'} videoId={'mFS8Fm6C2To'} videoPlayList={['mFS8Fm6C2To']} autoplay={0} controls={0} mute={false} loop={0} disablekb={1} />
                        </div>
                        {/* </div> */}
                    </Box>
                </Grid>
            </Box>
            <div className="container mt-5 pt-3">
                <div className='mb-4 d-flex justify-content-center' style={{ width: '100%', color: '#690808' }}>
                    <h5><b>2022 Summit Forums</b></h5>
                </div>
                <Box sx={{ width: '100%', borderLeft: '1px solid #690808', borderBottom: '3px solid #690808', borderRight: '1px solid #690808' }}>
                    <Stepper nonLinear activeStep={activeStepAlt} alternativeLabel sx={{ flexWrap: 'wrap' }}>
                        {steps.map((label, index) => (
                            <Step key={label} completed={completed[index]}>
                                <StepButton color="inherit" onClick={handleStep(index)}>
                                    <b style={{ color: '#690808' }}>{label}</b>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                                {
                                    activeStepAlt === 0 ? (
                                        <>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>The Under 40 Political Leaders Forum</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    This will examine the extent to which the existing policy and institutional environment is enabling youth participation in
                                                    leadership as well as whether or not current leadership is responsive to youth apparent needs and aspirations and explores
                                                    what it will take for Africa's youth to become the vanguards  for the Africa we want
                                                </Typography>
                                            </Alert>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>Policy Makers and Development Partners' Roundtable</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    Examines the role of policy makers and development partners in creating an enabling environment for Young People's
                                                    Participation in Governance, Leadership and Development. The Summit possesses and offers a platform for policy makers and
                                                    development assistance providers to showcase their work with youth, as well as highlight their successes and the challenges
                                                    they face in working with youth.  It also serves as the platform for the development partners and policy makers to take a
                                                    pulse on the priorities of youth.
                                                </Typography>
                                            </Alert>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>The SDG Workshop</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    Shall examine the SDGs, the pace of their implementation and propose ways in which the youth can influence the implementation
                                                    of the same. It shall be the platform upon which we showcase youth interventions towards the achievement of SDGs.
                                                </Typography>
                                            </Alert>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>The Presidential address</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    Co-convened by H.E. Dr. Jakaya Mrisho Kikwete, former President of the United Republic of Tanzania, and Hon. Dr. Peter Mathuki,
                                                    Secretary-General of the East African Community.
                                                </Typography>
                                            </Alert>
                                        </>
                                    ) : activeStepAlt === 1 ? (
                                        <>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>The Under 40 Business Leaders Forum</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    Gathers young and enterprising leaders in business, policymakers and decision makers in government, and development
                                                    practitioners to find solutions to pressing issues in the region's business environment. Cardinal to the Forum's existence is
                                                    tackling youth unemployment through nurturing enterprise and encouraging innovation. The establishment of a lucrative
                                                    environment for commerce is at the heart of the forum's discourse, whose ultimate goal is a skilled, competitive workforce that
                                                    is able to keep ahead of the curve in entrepreneurship, innovation, and job creation. The 2022 Business Leaders Forum is
                                                    dedicated to discussions on the African Continental Free Trade Agreement (AfCFTA), exploring the Negotiations, Principles,
                                                    Objectives, Institutional Framework and the place and role of young people in trade inspired by the AU theme of the Year 2022
                                                    “Acceleration of the AfCFTA Implementation”
                                                </Typography>
                                            </Alert>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>The East Africa Youth Enterprise Awards (YEA)</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    In response to the current post-pandemic period, “building forward stronger with youth”, in 2020 is a worthwhile engagement and
                                                    investment. YouLead introduced the inaugural East Africa Youth Enterprise Acceleration  Awards (YEA). YEA Awards sought to
                                                    recognize youth-led businesses across the East African Community (EAC) Partner States that are resilient, innovative, and are
                                                    simultaneously taking deliberate, innovative actions towards decent livelihoods and youth unemployment. Compelling  ideas
                                                    will be those demonstrating a strong angle on SDGs, including growth and economic opportunities, reduced gender-based
                                                    economic inequality, and a vibrant civil society (and private sector) that contributes towards the attainment of the
                                                    foregoing objectives
                                                </Typography>
                                                <Typography className='mt-3' style={{ textAlign: 'justify' }} >
                                                    YEA Awards will be given in two categories; Innovative business ideas requiring startup capital and existing businesses
                                                    requiring scale up capital.
                                                </Typography>
                                            </Alert>
                                        </>
                                    ) : activeStepAlt === 2 ? (
                                        <>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>Arusha Peace Forum</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                Youth Forum on Peace Building and Conflict Prevention - how young people can intervene in solving conflicts in their communities, reflect on peace and security related success and challenges of the for 2022 Kenya General Elections.
                                                </Typography>
                                            </Alert>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>Gender Equality Forum</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                Discusses African Youth priorities towards the Commission on Status of Women 67 - Innovation and technological change, and education in the digital age for achieving gender equality and the empowerment of all women and girls.
                                                </Typography>
                                            </Alert>
                                        </>
                                    ) : activeStepAlt === 3 ? (
                                        <>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>Post-Summit Agenda of Action</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    Is a proactive mechanism to ensure that the life of summit discussions go beyond the Summit reports. It is focused on
                                                    actively transforming regional business and politics by providing the right environment at both policy and program level,
                                                    in partnership with the YouLead Network of organisations and formations by advocating for implementation of summit
                                                    recommendations.
                                                </Typography>
                                            </Alert>
                                        </>
                                    ) : activeStepAlt === 4 ? (
                                        <>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>More Than Just The Arusha Experience</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                Before, during, and after the summit, international and national participants can seize the opportunity to explore the many features and recreational opportunities that Arusha and its surroundings offer. This infusion has been carefully crafted by the Organisers as “the Arusha Experience”
                                                </Typography>
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                Options include visits and excursions in any of the many national parks in the region.
                                                </Typography>
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                Business/Industry excursion to any of the many medium to large businesses in the Arusha area and region.
                                                </Typography>
                                            </Alert>
                                        </>
                                    ) : null
                                }
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', py: 2 }}>
                                <Button size="small" onClick={handleBackStep} sx={{ mr: 1 }} disabled={activeStepAlt === 0}>
                                    <b>
                                        {theme.direction === 'rtl' ? (
                                            <KeyboardArrowRight />
                                        ) : (
                                            <KeyboardArrowLeft />
                                        )}
                                        Back
                                    </b>
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button
                                    size="small"
                                    onClick={handleNextStep}
                                    sx={{ mr: 1 }}
                                >
                                    <b>
                                        Next
                                        {theme.direction === 'rtl' ? (
                                            <KeyboardArrowLeft />
                                        ) : (
                                            <KeyboardArrowRight />
                                        )}
                                    </b>
                                </Button>
                            </Box>
                        </React.Fragment>
                    </div>
                </Box>
            </div>
            <div className="container mb-4">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <div sx={{ display: 'flex' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify',
                                    // backgroundImage: `url(${waves})`,
                                    // backgroundSize: 'cover',
                                    // backgroundRepeat: 'no-repeat',
                                    // backgroundPosition: 'center'
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <div className='mt-3 d-flex justify-content-center' style={{ width: '100%', color: '#690808' }}>
                                            <h5><b>OUR PARTNERS</b></h5>
                                        </div>
                                        <div className='mt-4 d-flex justify-content-center d-none d-md-block' style={{ width: '100%' }}>
                                            <img src={partners} className='img-fluid animate__animated animate__fadeInLeft' alt="" style={{ width: '100%' }} />
                                        </div>
                                        <div className='mt-4 d-flex justify-content-center d-md-none' style={{ width: '100%' }}>
                                            <img src={partners2} className='img-fluid animate__animated animate__fadeInLeft' alt="" style={{ width: '100%' }} />
                                        </div>
                                    </CardContent>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </div >
    );
}

export default YouLeadSummit22;
