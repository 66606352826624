import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardCover from '@mui/joy/CardCover';
import Typography from '@mui/joy/Typography';
import { CssVarsProvider } from '@mui/joy/styles';
import {
    Grid,
    Box,
    Button,
    MobileStepper,
    useTheme
} from '@mui/material';
import { API_URL } from 'helpers/apiHelper';
import React, { useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const Speakers = ({ speakers }) => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const theme = useTheme();

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = speakers.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
            <AutoPlaySwipeableViews
                interval={6000}
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                <CssVarsProvider>
                    {speakers && speakers.map((speaker) => {
                        return (
                            <Grid container direction="row" spacing={5} style={{ width: '100%' }}>
                            <Grid item lg={3} md={4} sm={6} xs={12} style={{ width: '100%' }}>
                                <Card sx={{ minHeight: '400px', width: '100%' }}>
                                    <CardCover>
                                        <img
                                            src={API_URL + speaker.person.photoPath}
                                            srcSet={API_URL + speaker.person.photoPath}
                                            loading="lazy"
                                            alt=""
                                            style={{
                                                objectFit: 'cover',
                                                objectPosition: 'top center', // Keeps the top of the image in view
                                            }}
                                        />
                                    </CardCover>
                                    <CardCover
                                        sx={{
                                            background:
                                                'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                        }}
                                    />
                                    <CardContent sx={{ justifyContent: 'flex-end' }}>
                                        <Typography level="title-lg" textColor="#fff">
                                            {speaker.person.firstName}
                                        </Typography>
                                        <Typography
                                            textColor="neutral.300"
                                        >
                                            {speaker.person.post}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12} style={{ width: '100%' }}>
                                    <Card sx={{ minHeight: '400px', width: '100%' }}>
                                        <CardCover>
                                            <img
                                                src={API_URL + speaker.person.photoPath}
                                                srcSet={API_URL + speaker.person.photoPath}
                                                loading="lazy"
                                                alt=""
                                                style={{
                                                    objectFit: 'cover',
                                                    objectPosition: 'top center', // Keeps the top of the image in view
                                                }}
                                            />
                                        </CardCover>
                                        <CardCover
                                            sx={{
                                                background:
                                                    'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                            }}
                                        />
                                        <CardContent sx={{ justifyContent: 'flex-end' }}>
                                            <Typography level="title-lg" textColor="#fff">
                                                {speaker.person.firstName}
                                            </Typography>
                                            <Typography
                                                textColor="neutral.300"
                                            >
                                                {speaker.person.post}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12} style={{ width: '100%' }}>
                                    <Card sx={{ minHeight: '400px', width: '100%' }}>
                                        <CardCover>
                                            <img
                                                src={API_URL + speaker.person.photoPath}
                                                srcSet={API_URL + speaker.person.photoPath}
                                                loading="lazy"
                                                alt=""
                                                style={{
                                                    objectFit: 'cover',
                                                    objectPosition: 'top center', // Keeps the top of the image in view
                                                }}
                                            />
                                        </CardCover>
                                        <CardCover
                                            sx={{
                                                background:
                                                    'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                            }}
                                        />
                                        <CardContent sx={{ justifyContent: 'flex-end' }}>
                                            <Typography level="title-lg" textColor="#fff">
                                                {speaker.person.firstName}
                                            </Typography>
                                            <Typography
                                                textColor="neutral.300"
                                            >
                                                {speaker.person.post}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12} style={{ width: '100%' }}>
                                    <Card sx={{ minHeight: '400px', width: '100%' }}>
                                        <CardCover>
                                            <img
                                                src={API_URL + speaker.person.photoPath}
                                                srcSet={API_URL + speaker.person.photoPath}
                                                loading="lazy"
                                                alt=""
                                                style={{
                                                    objectFit: 'cover',
                                                    objectPosition: 'top center', // Keeps the top of the image in view
                                                }}
                                            />
                                        </CardCover>
                                        <CardCover
                                            sx={{
                                                background:
                                                    'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                                            }}
                                        />
                                        <CardContent sx={{ justifyContent: 'flex-end' }}>
                                            <Typography level="title-lg" textColor="#fff">
                                                {speaker.person.firstName}
                                            </Typography>
                                            <Typography
                                                textColor="neutral.300"
                                            >
                                                {speaker.person.post}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>)
                    })}
                </CssVarsProvider>
            </AutoPlaySwipeableViews>
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        <b>
                            Next
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </b>
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        <b>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            Back
                        </b>
                    </Button>
                }
            />
        </Box>
    );
}

export default Speakers;
