import React, { useEffect } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Typography from '@mui/material/Typography';
import visionMission from 'assets/images/home/Page-Heager.png';
import { Backdrop, Box, CardActions, CardContent, CircularProgress, Collapse, Divider, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import logo from 'assets/images/home/YouLead-Africa-Logo-Transparent.png';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEvents } from 'store/event/eventSlice';
import { API_URL } from 'helpers/apiHelper';
import { compareDates, formatDate } from 'helpers/utilityHelpers';
import { AlternateEmail, Facebook, Instagram, NearbyError, Twitter, YouTube } from '@mui/icons-material';
import waves from 'assets/images/home/paint.svg';
import wavesGrey from 'assets/images/home/waves-grey.svg';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const Events = () => {
    const [expanded, setExpanded] = React.useState({});
    const events = useSelector(state => state.event.events)
    const eventStatus = useSelector(state => state.event.status)
    // const error = useSelector(state => state.event.error)
    const dispatch = useDispatch()

    React.useEffect(() => {
        if (eventStatus === 'idle') {
            dispatch(fetchEvents())
        }
    }, [eventStatus, dispatch])

    React.useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (events && events.length > 0) {
            let obj = {}
            events.map((event) => {
                obj['event' + event.event.id] = false
            })
            setExpanded({ ...obj });
        }
    }, [events])

    const handleExpandClick = (eventId) => {
        let obj = { ...expanded }
        Object.keys(obj).map((key) => {
            if (key === ('event' + eventId)) {
                obj[key] = obj[key] !== true
            } else {
                obj[key] = false
            }
        })
        setExpanded({ ...obj });
    };

    return (
        <div className="App">
            <Box>
                <Grid className=' mt-4 py-5' container direction="row" spacing={3} style={{
                    textAlign: 'justify',
                    backgroundImage: `url(${visionMission})`,
                }}>
                    <Grid item md={6}></Grid>
                    <Grid item md={6} sm={12}>
                        <Box className='my-5 ms-5 pb-3 d-flex flex-column text-light' sx={{
                            background: 'linear-gradient(to top, rgba(0,0,0,0.2), rgba(0,0,0,0)), linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 300px)'
                        }}>
                            <h1 className='mt-2 me-5 text-center'>YouLead Events</h1>
                            <Typography className='me-5' style={{ textAlign: 'center' }} >
                                Be a part of our events. Be a part of YouLead.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Timeline position="right" sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.2,
                    },
                }} className='ms-0' >
                    {events && events.length > 0 ? (
                        events.map((event, index) => {
                            return (
                                <TimelineItem key={index} className='ms-0'>
                                    <TimelineOppositeContent
                                        sx={{ m: 'auto 0' }}
                                        align="right"
                                        variant="body2"
                                        color="primary"
                                    >
                                        <h6 className='d-none d-md-block'><b>{formatDate(event.event.startTimestamp)}</b></h6>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineConnector />
                                        <TimelineDot color='primary'>
                                            <EventAvailableIcon />
                                        </TimelineDot>
                                        <h6 style={{ color: '#690808' }} className='d-md-none'><b>{formatDate(event.event.startTimestamp)}</b></h6>
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                                        <Card sx={{ maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '70%' }, borderBottom: '3px solid #690808', borderRight: '1px solid #690808', borderLeft: '1px solid #690808', borderTop: '1px solid #690808' }} style={{
                                            backgroundImage: `url(${waves})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center'
                                        }}>
                                            <CardHeader
                                                className='ms-0 ps-0 mb-0 pb-0'
                                                // avatar={
                                                //     <Avatar className='d-none d-md-block ms-3' sx={{ bgcolor: '#690808' }} aria-label="recipe">
                                                //         {/* <img src={logo} className='img-fluid' alt="" /> */}
                                                //     </Avatar>
                                                // }
                                                title={<h5 className='ms-3' style={{ color: '#690808' }}><b>{event.event.name}</b></h5>}
                                                subheader={<>
                                                    <div className='ms-3 d-flex justify-content-between align-items-center'>
                                                        <div className='d-flex justify-content-between align-items-center flex-wrap'>
                                                            <div className='me-5'>
                                                                <h6><b><span style={{ fontSize: 'smaller', fontWeight: '800' }}>Applications Open:</span></b></h6>
                                                                <h6><b><span className='text-success mt-2'>{formatDate(event.event.applicationOpenTimestamp)}</span></b></h6>
                                                            </div>
                                                            <div className='me-5'>
                                                                <h6><b><span style={{ fontSize: 'smaller', fontWeight: '800' }}>Applications Close:</span></b></h6>
                                                                <h6><b><span className='text-danger mt-2'>{formatDate(event.event.applicationCloseTimestamp)}</span></b></h6>
                                                            </div>
                                                            {/* <h6 style={{ color: '#690808' }}><b>{event.numberOfApplicants} Applicants</b></h6> */}
                                                        </div>
                                                        <div className='d-none d-lg-inline'>
                                                            <div className='d-flex flex-column justify-content-between align-items-center'>
                                                                {compareDates(new Date(), event.event.applicationCloseTimestamp) > 0 ? (
                                                                    <button className='btn btn-sm btn-danger'>Applications Closed</button>
                                                                ) : (
                                                                    <button className='btn btn-sm' style={{ backgroundColor: '#690808', color: 'white', width: '100%' }} onClick={() =>
                                                                        window.open('https://portal.youlead.africa/public/events', '_blank')}>Apply Now</button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>}
                                            />
                                            <CardContent className='d-lg-none'>
                                                <div className='d-flex justify-content-center align-items-center flex-wrap'>
                                                    <div>
                                                        {compareDates(new Date(), event.event.applicationCloseTimestamp) > 0 ? (
                                                            <button className='btn btn-sm btn-danger'>Applications Closed</button>
                                                        ) : (
                                                            <button className='btn btn-sm btn-success' style={{ backgroundColor: '#690808' }} onClick={() =>
                                                                window.open('https://portal.youlead.africa/public/events', '_blank')}>Apply Now</button>
                                                        )}
                                                    </div>
                                                </div>
                                            </CardContent>
                                            <CardMedia
                                                component="img"
                                                className='px-5'
                                                sx={{
                                                    maxHeight: '550px',
                                                    // borderBottom: '1px solid #690808',
                                                    // borderLeft: '1px solid #690808', 
                                                    // borderRadius:'2%',
                                                    // background:
                                                    //     'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 500px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 550px)',
                                                }}
                                                // height="250"
                                                image={API_URL + event.event.eventBannerPath}
                                                alt="event Banner"
                                            />
                                            <CardActions disableSpacing>
                                                <IconButton aria-label="Instagram" color='primary' onClick={() =>
                                                    window.open('https://www.instagram.com/oneyoungafrica/?hl=en', '_blank')}>
                                                    <Instagram />
                                                </IconButton>
                                                <IconButton aria-label="Youtube" color='primary' onClick={() =>
                                                    window.open('https://www.youtube.com/@youleadafrica', '_blank')}>
                                                    <YouTube />
                                                </IconButton>
                                                <IconButton aria-label="Twitter" color='primary' onClick={() =>
                                                    window.open('https://twitter.com/OneYoungAfrica?ref_src=twsrc%5Etfw', '_blank')}>
                                                    <Twitter />
                                                </IconButton>
                                                <IconButton aria-label="Facebook" color='primary' onClick={() =>
                                                    window.open('https://www.facebook.com/OneYoungAfrica/', '_blank')}>
                                                    <Facebook />
                                                </IconButton>
                                                <IconButton aria-label="Email" color='primary' onClick={() =>
                                                    window.open('mailto:youlead@youlead.africa', '_blank')}>
                                                    <AlternateEmail />
                                                </IconButton>
                                                <ExpandMore
                                                    expand={expanded['event' + event.event.id]}
                                                    onClick={() => { handleExpandClick(event.event.id) }}
                                                    aria-expanded={expanded['event' + event.event.id]}
                                                    aria-label="show more"
                                                >
                                                    <h6 className='btn btn-sm' style={{ backgroundColor: '#690808', color: 'white' }}>{expanded['event' + event.event.id] === false ? (<span className='d-none d-md-inline'>Read more</span>) : null} <ExpandMoreIcon /></h6>
                                                </ExpandMore>
                                            </CardActions>
                                            <Collapse in={expanded['event' + event.event.id]} timeout="auto" unmountOnExit>
                                                <CardContent style={{
                                                    backgroundImage: `url(${wavesGrey})`,
                                                }}>
                                                    <hr className='mt-0' />
                                                    <Typography paragraph dangerouslySetInnerHTML={{ __html: event.event.description }}>
                                                    </Typography>
                                                    <div className='d-flex justify-content-end align-items-end'>
                                                        <ExpandMore
                                                            expand={expanded['event' + event.event.id]}
                                                            onClick={() => { handleExpandClick(event.event.id) }}
                                                            aria-expanded={expanded['event' + event.event.id]}
                                                            aria-label="show more"
                                                        >
                                                            <h6 className='badge' style={{ backgroundColor: '#690808' }}><ExpandMoreIcon /></h6>
                                                        </ExpandMore>
                                                    </div>
                                                </CardContent>
                                            </Collapse>
                                        </Card>
                                    </TimelineContent>
                                </TimelineItem>)
                        })
                    ) : (
                        <Card variant="elevation" >
                            <div className="my-5 d-flex flex-column justify-content-center align-items-center">
                                <NearbyError className='mt-5 mb-3' fontSize='large' />
                                <h5><b className="text-danger">No Events To Show At The Moment.</b></h5>
                            </div>
                        </Card>
                    )}
                </Timeline>
            </Box>

            <Backdrop
                sx={{ color: '#fff', zIndex: 99998 }}
                open={eventStatus === 'loading'}
            >
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <CircularProgress color="inherit" />
                    <span className="mt-2">Please wait...</span>
                </div>
            </Backdrop>
        </div>
    );
}
export default Events;