import { SelfImprovement } from '@mui/icons-material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card, CardContent, CardMedia, Grid,
    MobileStepper,
    Step,
    StepButton,
    Stepper,
    Typography,
    useMediaQuery, useTheme
} from '@mui/material';
import visionMission from 'assets/images/summit/group-image-banner.png';
import youLeadBackgroundFull from 'assets/images/home/YouLeadBackground.png';
import waves from 'assets/images/home/waves-opacity-20.svg';
import coverner1 from 'assets/images/summit/coverner-1.png';
import coverner2 from 'assets/images/summit/coverner-2.png';
import coverner3 from 'assets/images/summit/coverner-3.png';
import coverner4 from 'assets/images/summit/coverner-4.png';
import partners from 'assets/images/summit/partners.png';
import partners2 from 'assets/images/summit/partners2.png';
import summit from 'assets/images/summit/summit.png';
import YouTubePlayer from 'common/youtubePlayer';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { fetchSpeakers } from 'store/summit/summitSlice';
import Speakers from './Speakers';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const YouLeadSummit24 = () => {
    const speakers = useSelector(state => state.summit.speakers)
    const summitStatus = useSelector(state => state.summit.status)
    // const error = useSelector(state => state.summit.error)
    const dispatch = useDispatch()

    React.useEffect(() => {
        if (summitStatus === 'idle') {
            dispatch(fetchSpeakers())
        }
    }, [summitStatus, dispatch])

    const refs = useRef([]);

    const addToRefs = (el) => {
        if (el && !refs.current.includes(el)) {
            refs.current.push(el);
        }
    };

    useEffect(() => {
        console.log(speakers)
    }, [speakers])

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate__animated', 'animate__fadeInRight');
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1,
            }
        );

        refs.current.forEach((ref) => {
            observer.observe(ref);
        });

        return () => {
            refs.current.forEach((ref) => {
                observer.unobserve(ref);
            });
        };
    }, []);

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
    const isXl = useMediaQuery(theme.breakpoints.up('xl'));

    let width = '100%';  // Default
    let widthImages = '100%';  // Default

    if (isXs) {
        width = '100%';
        widthImages = '100%';
    } else if (isSm) {
        width = '90%';
        widthImages = '100%';
    } else if (isMd) {
        width = '70%';
        widthImages = '60%';
    } else if (isLg) {
        width = '60%';
        widthImages = '40%';
    } else if (isXl) {
        width = '50%';
        widthImages = '40%';
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = 4;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const steps = ['4th November 2024', '5th November 2024', '6th November 2024', '7th November 2024', '8th November 2024'];
    const [activeStepAlt, setActiveStepAlt] = React.useState(0);
    const [completed, setCompleted] = React.useState({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStepAlt === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNextStep = () => {
        const newActiveStepAlt =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStepAlt + 1;
        setActiveStepAlt(newActiveStepAlt);
    };

    const handleBackStep = () => {
        setActiveStepAlt((prevActiveStepAlt) => prevActiveStepAlt - 1);
    };

    const handleStep = (step) => () => {
        setActiveStepAlt(step);
    };

    return (
        <div className="App">
            <div className="container mt-5 pt-3">
                <img src={summit} className='img-fluid animate__animated animate__fadeInDown' alt="" style={{ width: '100%', maxHeight: '95vh' }} />
            </div>
            <Box>
                <Grid className='my-0' container direction="row" spacing={3} style={{
                    textAlign: 'justify',
                    backgroundImage: `url(${visionMission})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}>
                    <Grid item md={5}></Grid>
                    <Grid item md={7} sm={12}>
                        <div className='my-5 ms-5 d-flex flex-column text-light' >
                            <div className='ms-4'>
                                <Typography variant='h5' className='me-5 mb-3' style={{ textAlign: 'justify' }} >
                                    <b>The YouLead Summit 2024 is here!</b>
                                </Typography>
                                <Typography className='me-5' style={{ textAlign: 'justify' }} >
                                    Africa's largest and most diverse youth forum co-hosted by the East African Community and MS Training Centre for Development
                                    Cooperation under the Patronage of H.E. Jakaya Mrisho Kikwete returns under the theme <b>Igniting the Potential of Africa's
                                        Youthful Population: Reinstating Education as an Eminent Public Good.</b>
                                </Typography>
                                <Typography className='me-5 mt-3' style={{ textAlign: 'justify' }} >
                                    It holds the prospects to bring together young people in the African continent, youth-led and youth serving organizations, governments,
                                    development partners and relevant stakeholder to deliberate on the state of affairs of African education and its relevance. The theme
                                    will draw nexus to latest trends linked to its signature Forums to examine emerging issues related to socioeconomic development,
                                    evaluation of traditional practices and leveraging on traditional/Pan-African knowledge to stimulate home-grown solutions.
                                </Typography>
                                <Typography className='me-5 mt-3' style={{ textAlign: 'justify' }} >
                                    The outcomes of the Summit will serve as an invaluable resource for key actors as they consider the structure of Africa's curricula,
                                    development plans and major education concerns that affect the continent in-depth. These will include methodologies, motivation,
                                    literacy, and professional development for the young population on the African continent.
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <div className="container">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <div sx={{ display: 'flex' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify',
                                    // backgroundImage: `url(${waves})`,
                                    // backgroundSize: 'cover',
                                    // backgroundRepeat: 'no-repeat',
                                    // backgroundPosition: 'center'
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <div className='mt-3 d-flex justify-content-center' style={{ width: '100%', color: '#690808' }}>
                                            <h5><b>Welcome Messages</b></h5>
                                        </div>
                                        {/* <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
                                            <AutoPlaySwipeableViews
                                                interval={6000}
                                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                index={activeStep}
                                                onChangeIndex={handleStepChange}
                                                enableMouseEvents
                                            >
                                                <Card ref={addToRefs} sx={{
                                                    display: 'flex', flexWrap: {
                                                        xs: 'wrap', sm: 'wrap', md: 'wrap',
                                                        lg: 'nowrap', xl: 'nowrap'
                                                    },
                                                    backgroundImage: `url(${youLeadBackgroundFull})`,
                                                    color: 'white'
                                                }}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                                        textAlign: 'justify'
                                                    }}>
                                                        <CardContent sx={{ flex: '1 0 auto' }} className='ms-3'>
                                                            <div className='mt-3 d-flex flex-column justify-content-start' style={{ width: '100%' }}>
                                                                <h4><b>H.E. Dr. Jakaya Mrisho Kikwete, former President of Tanzania</b></h4>
                                                                <h5><b>Patron, YouLead Africa</b></h5>
                                                            </div>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                Excellencies, distinguished young people and dear friends, it is my pleasure
                                                                to inform you that this year's YouLead Summit will be held from 20th -24th November
                                                                in Nairobi - Kenya.
                                                            </Typography>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                It is heartwarming indeed to know that the 2024 Summit will also be available to an
                                                                Africa-wide audience via virtual platforms. This year's theme is "Youth Empowerment
                                                                Through Capacity Building: Revitalizing Youth Voice and Agency towards the Africa We Want"
                                                            </Typography>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                I would like to take this opportunity to invite you all to participate actively. I call
                                                                upon development partners, the private sector, media, civil society, and other stakeholders
                                                                to be part of and support the East African Community in convening this year's YouLead Summit.
                                                                We look forward to welcoming you all to the Summit
                                                            </Typography>
                                                        </CardContent>
                                                    </Box>
                                                    <CardMedia
                                                        className='px-5 pt-5 pb-3'
                                                        component="img"
                                                        sx={{ width: widthImages }}
                                                        image={coverner3}
                                                        alt=""
                                                    />
                                                </Card>

                                                <Card ref={addToRefs} sx={{
                                                    display: 'flex', flexWrap: {
                                                        xs: 'wrap', sm: 'wrap', md: 'wrap',
                                                        lg: 'nowrap', xl: 'nowrap'
                                                    },
                                                    backgroundImage: `url(${youLeadBackgroundFull})`,
                                                    color: 'white'
                                                }}>
                                                    <CardMedia
                                                        className='px-5 pt-5 pb-3'
                                                        component="img"
                                                        sx={{ width: widthImages }}
                                                        image={coverner1}
                                                        alt=""
                                                    />
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                                        textAlign: 'justify',
                                                        backgroundImage: `url(${waves})`,
                                                        backgroundSize: 'cover',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'center'
                                                    }}>
                                                        <CardContent sx={{ flex: '1 0 auto' }} className='me-3'>
                                                            <div className='mt-3 d-flex flex-column justify-content-start' style={{ width: '100%' }}>
                                                                <h4><b>Hon. Dr. Peter Mathuki</b></h4>
                                                                <h5><b>Secretary General</b></h5>
                                                                <h5><b>East African Community</b></h5>
                                                            </div>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                The treaty establishing the East African Community is people-driven and the youth form not
                                                                only part of the population but constitute the largest number. That is to say, the community
                                                                belongs to the youth. Therefore, as the EAC, we feel it is important to hold this youth summit.
                                                                We want the youth to know the community belongs to them, encourage them to take responsibility,
                                                                and let them know that now is the time to do so.
                                                            </Typography>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                We are hosting the YouLead Summit 2024 and call upon all youth on the continent to attend. The
                                                                Summit will be co-hosted by His Excellency Jakaya Mrisho Kikwete, the patron of YouLead Africa,
                                                                and myself, the Secretary General of the EAC. We call upon stakeholders, development partners,
                                                                and the private sector to come out and support this event so that together, we can strengthen
                                                                the voice of the youth. We welcome you to Arusha.
                                                            </Typography>
                                                        </CardContent>
                                                    </Box>
                                                </Card>

                                                <Card ref={addToRefs} sx={{
                                                    display: 'flex', flexWrap: {
                                                        xs: 'wrap', sm: 'wrap', md: 'wrap',
                                                        lg: 'nowrap', xl: 'nowrap'
                                                    },
                                                    backgroundImage: `url(${youLeadBackgroundFull})`,
                                                    color: 'white'
                                                }}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                                        textAlign: 'justify'
                                                    }}>
                                                        <CardContent sx={{ flex: '1 0 auto' }} className='ms-3'>
                                                            <div className='mt-3 d-flex flex-column justify-content-start' style={{ width: '100%' }}>
                                                                <h4><b>Makena Mwobobia </b></h4>
                                                                <h5><b>Executive Director</b></h5>
                                                                <h5><b>MSTCDC</b></h5>
                                                            </div>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                Africa is the richest continent in resources (including human resource), we are fortunate to be able to
                                                                draw on the resourcefulness and creativity of the more than 1.2 billion young people, this demographic
                                                                advantage therefore compels key actors' accountability for practicability of young peoples' engagement
                                                                in political and economic governance.  Governments must invest in their capacity in issues that affect
                                                                young people, stakeholders should keep mobilizing supporters to mobilize young people as an important
                                                                sphere of African development.
                                                            </Typography>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                We must say that the YouLead program has proven that the meaningful engagement, adequate resourcing,
                                                                capacity building, effective leadership and governance, and mentorship of young people can be appropriately
                                                                addressed. To that end, I call upon a greater partnership with our development partners, private sector
                                                                and governments, CSO's and the young people's constituency to make Africa's development a reality by
                                                                bringing together our vast youth resource through the YouLead Summit 2024.
                                                            </Typography>
                                                        </CardContent>
                                                    </Box>
                                                    <CardMedia
                                                        className='px-5 pt-5 pb-3'
                                                        component="img"
                                                        sx={{ width: widthImages }}
                                                        image={coverner2}
                                                        alt=""
                                                    />
                                                </Card>

                                                <Card ref={addToRefs} sx={{
                                                    display: 'flex', flexWrap: {
                                                        xs: 'wrap', sm: 'wrap', md: 'wrap',
                                                        lg: 'nowrap', xl: 'nowrap'
                                                    },
                                                    backgroundImage: `url(${youLeadBackgroundFull})`,
                                                    color: 'white'
                                                }}>
                                                    <CardMedia
                                                        className='px-5 pt-5 pb-3'
                                                        component="img"
                                                        sx={{ width: widthImages }}
                                                        image={coverner4}
                                                        alt=""
                                                    />
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                                        textAlign: 'justify',
                                                        backgroundImage: `url(${waves})`,
                                                        backgroundSize: 'cover',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'center'
                                                    }}>
                                                        <CardContent sx={{ flex: '1 0 auto' }} className='me-3'>
                                                            <div className='mt-3 d-flex flex-column justify-content-start' style={{ width: '100%' }}>
                                                                <h4><b>Stella Agara</b></h4>
                                                                <h5><b>Summit Director, 2024</b></h5>
                                                            </div>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                Welcome to Nairobi, the only capital in the world with a National Park in it and the home of the YouLead Summit 2024!!!!
                                                            </Typography>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                This great gathering, the 7th Annual Africa Youth Leadership (YouLead) Summit, is finally here and will be held at the Bomas of Kenya; a stone throw from the park, a venue that deeply symbolizes the themes that YouLead stands for but most importantly one that bares the rich heritage of Kenya's rich culture and traditions.
                                                            </Typography>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                I cannot sufficiently express my excitement about the fact that we are finally hosting the YouLead Summit in Kenya, this being the first time the summit has been held outside The United Republic of Tanzania that has been the host of all six previous summits. We appreciate all the hosting we have received from The United Republic of Tanzania and challenge the Government of Kenya to match their hospitality now that the summit has landed at the doorstep of The Republic of Kenya.
                                                            </Typography>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                We are gathered to dialogue on Capacity building for the youth with intentions to re-invent youth leadership for the Africa We Want. I encourage all our guests and delegates to wear their thinking caps in anticipation of the task ahead of us. The aspirations of the youth of this continent for capacity building are upon our shoulders and I believe we are up to the task.
                                                            </Typography>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                I am absolutely excited about the prospects of what we will create in the coming days and the discussions we are going to have. The East Africa Community and the continent of Africa is lucky to have all of you as the thinkers tasked with thinking, planning and conspiring for our collective success.
                                                            </Typography>
                                                            <Typography className='mt-3' style={{
                                                                textAlign: 'justify'
                                                            }} >
                                                                <b>Welcome to YouLead Summit 2024!</b>
                                                            </Typography>
                                                        </CardContent>
                                                    </Box>
                                                </Card>
                                            </AutoPlaySwipeableViews>
                                            <MobileStepper
                                                steps={maxSteps}
                                                position="static"
                                                activeStep={activeStep}
                                                nextButton={
                                                    <Button
                                                        size="small"
                                                        onClick={handleNext}
                                                        disabled={activeStep === maxSteps - 1}
                                                    >
                                                        <b>
                                                            Next
                                                            {theme.direction === 'rtl' ? (
                                                                <KeyboardArrowLeft />
                                                            ) : (
                                                                <KeyboardArrowRight />
                                                            )}
                                                        </b>
                                                    </Button>
                                                }
                                                backButton={
                                                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                                        <b>
                                                            {theme.direction === 'rtl' ? (
                                                                <KeyboardArrowRight />
                                                            ) : (
                                                                <KeyboardArrowLeft />
                                                            )}
                                                            Back
                                                        </b>
                                                    </Button>
                                                }
                                            />
                                        </Box> */}
                                        <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
                                            <h4 className='text-center my-5'>Coming Soon</h4>
                                        </Box>
                                    </CardContent>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <Box>
                <Grid className='my-0' container direction="row" spacing={3} style={{
                    textAlign: 'justify',
                    backgroundImage: `url(${youLeadBackgroundFull})`,
                    color: 'white'
                }}>
                    <div className='container'>
                        <Typography variant='h5' className='mt-3' style={{ textAlign: 'center' }} >
                            <b>YouLead Summit 2024 Theme</b>
                        </Typography>
                        <Typography className='mt-3 mx-4' style={{ textAlign: 'justify' }} >
                            Re-affirmation of Africa's commitment to pedagogical strategies sets forth African Union Member States', Inter-governmental
                            Organizations', Governments', International Organizations' and Development Partners' paradigm shift of efforts and investment
                            towards progress to warrant the completion, and quality of education, with a focus to reduce out-of-school populace and improve
                            literacy rates.
                        </Typography>
                        <Typography className='mt-4 mx-4' style={{ textAlign: 'justify' }} >
                            Significantly, the demographic demand for transformational nature of African education defeats the transactional commodity-based
                            education glaringly increasing in the continent; which more than half of Africa's population seldom affords.  Education, therefore,
                            should align to thought and practice, depict a key facet to African ideologies and transit the mindsets of its youth in anti-poverty
                            and African civilization struggles.
                        </Typography>
                        <Typography className='mt-4 mx-4' style={{ textAlign: 'justify' }} >
                            The reassuring disposition of the wording <b><i>"Educate an African fit for the 21st Century"</i></b> poses yet another interlocking strategy to
                            reconstruct and rejuvenate the purpose of education on the African context to address, inter alia, the systems of democracy and
                            governance, business and innovation, gender equality, and peace and security.
                        </Typography>
                        <Typography className='mt-4 mx-4' style={{ textAlign: 'justify' }} >
                            The exposition towards <b><i>"Building resilient education systems for increased access to inclusive, lifelong, quality, and relevant
                                learning in Africa"</i></b> largely speak to the integration of young people into the labour market embarked on often ambitious reforms
                            to improve the provision of skills.
                        </Typography>
                        <Typography className='mt-4 mx-4' style={{ textAlign: 'justify' }} >
                            This premise involves principal efforts to rehabilitate technical and vocational education and training with the aim of making it
                            more flexible, practical and of a higher quality and capable of responding better and more rapidly to the needs of the modern world
                            advancements.
                        </Typography>
                        <Typography className='mt-4 mx-4' style={{ textAlign: 'justify' }} >
                            It is with these premises, and alignment with the African Union Theme of the Year, that YouLead Summit will center education in the
                            discussions during its signature Forums.
                        </Typography>
                    </div>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        marginY: 4
                    }}>
                        <div style={{ width }}>
                            <YouTubePlayer key={'mFS8Fm6C2To'} videoId={'mFS8Fm6C2To'} videoPlayList={['mFS8Fm6C2To']} autoplay={0} controls={0} mute={false} loop={0} disablekb={1} />
                        </div>
                        {/* </div> */}
                    </Box>
                </Grid>
            </Box>
            <div className="container mt-5 pt-3">
                <div className='mb-4 d-flex justify-content-center' style={{ width: '100%', color: '#690808' }}>
                    <h5><b>2024 Summit Forums</b></h5>
                </div>
                <Box sx={{ width: '100%', borderLeft: '1px solid #690808', borderBottom: '3px solid #690808', borderRight: '1px solid #690808' }}>
                    <Stepper nonLinear activeStep={activeStepAlt} alternativeLabel sx={{ flexWrap: 'wrap' }}>
                        {steps.map((label, index) => (
                            <Step key={label} completed={completed[index]}>
                                <StepButton color="inherit" onClick={handleStep(index)}>
                                    <b style={{ color: '#690808' }}>{label}</b>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                                {
                                    activeStepAlt === 0 ? (
                                        <>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>The Under 40 Political Leaders Forum</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    This will examine the extent to which the existing policy and institutional environment in relation to the
                                                    standard of education enables meaningful youth participation in leadership, the level of competence and skills
                                                    that are responsive to apparent needs and aspirations of the youth. This will further explore what can be done
                                                    to advance the quality and accessibility of Education in Africa in the course to make Africa's youth become
                                                    the vanguards for the Africa we want.
                                                </Typography>
                                            </Alert>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>Policy Makers and Development Partners' Roundtable</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    Examines the role of policymakers and development partners in creating an enabling environment for Young
                                                    People's Participation in Governance, Leadership and Development. The Summit has and offers a platform for
                                                    policymakers, including parliamentary committee on education, and development assistance providers to showcase
                                                    their work with youth and highlight their successes and challenges they face in working with youth. It also
                                                    serves as the platform for development partners and policymakers to pulse on youth's priorities on education.
                                                </Typography>
                                            </Alert>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>The SDG Workshop</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    Shall examine the SDGs, and the pace of their implementation and propose ways in which the youth can influence
                                                    the implementation of the same. It shall be the platform on which we showcase youth interventions towards
                                                    achieving SDGs, as well as contribution post Summit of the Future.
                                                </Typography>
                                            </Alert>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>The Presidential address</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    Co-convened by H.E. Dr. Jakaya Mrisho Kikwete, former President of the United Republic of Tanzania, and the
                                                    Secretary-General of the East African Community.
                                                </Typography>
                                            </Alert>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>The Fire-Side Chat</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    With H.E. Dr. Jakaya Mrisho Kikwete on education as a priority to achieve the African Union Agenda 2063
                                                    Aspirations.
                                                </Typography>
                                            </Alert>
                                        </>
                                    ) : activeStepAlt === 1 ? (
                                        <>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>The Under 40 Business Leaders Forum</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    Gathers young and enterprising leaders in business, policymakers and decision-makers in government, and
                                                    development practitioners to find solutions to pressing issues in the region's business environment.
                                                    Cardinal to the Forum's existence is tackling youth unemployment through nurturing enterprise and
                                                    encouraging innovation. The establishment of a lucrative environment for commerce is at the heart of the
                                                    forum's discourse, whose ultimate goal is a skilled, competitive workforce that can keep ahead of the
                                                    curve in entrepreneurship, innovation, and job creation. The 2024 Business Leaders Forum will be keen to
                                                    discuss the prospects education contributes to growth of innovation, business and entrepreneurship for
                                                    young business leaders in Africa.
                                                </Typography>
                                            </Alert>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>The EAC Youth Fellowship Business Track Pitching</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    Offers a platform for the II Cohort of the EAC Youth Fellows, under the business track, to pitch their start-up
                                                    and scale-up business ideas and receive feedback from a seasoned panel of leaders in both the public and private sectors.
                                                </Typography>
                                            </Alert>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>Regional Hackathon for Women and Youth Innovative Projects for Regional
                                                    Economic Integration Challenges</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    Hosted by GIZ-EAC features the pitching of women and youth CSO representatives' projects to contribute to regional
                                                    economic integration.
                                                </Typography>
                                            </Alert>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>Networking Cafè</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    As a cooling off opportunity for delegates to network and interact with private sector leaders and connect for greater
                                                    collaboration and engagement.
                                                </Typography>
                                            </Alert>
                                        </>
                                    ) : activeStepAlt === 2 ? (
                                        <>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>Arusha Peace Forum</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    Youth Forum on Peace Building and Conflict Prevention. Youth showcases models that promote the role of
                                                    youth in peace building and participation in conflict prevention as proactive measures to maintaining
                                                    peace and security in Africa. The discussions will center around the implementation of UNSCR 2250 on Youth,
                                                    Peace and Security to nurture proactive responses by young people in peacebuilding and conflict prevention.
                                                </Typography>
                                            </Alert>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>Gender Equality Forum</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    Discusses ways to dismantle the structural causes at the root of gender inequality and advances the Action
                                                    Coalition's Commitments. This shall also be the platform upon which the Arusha debate will be executed, to
                                                    be branded Nairobi Debates in recognition of the location of debates.
                                                </Typography>
                                            </Alert>
                                        </>
                                    ) : activeStepAlt === 3 ? (
                                        <>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b>Post-Summit Agenda of Action</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    Is a proactive mechanism to ensure that the life of summit discussions goes beyond the Summit reports. It
                                                    is focused on actively transforming regional business and politics by providing the right environment at
                                                    both policy and program levels, in partnership with the YouLead Network of organizations and formations
                                                    by advocating for the implementation of summit recommendations.
                                                </Typography>
                                            </Alert>
                                        </>
                                    ) : activeStepAlt === 4 ? (
                                        <>
                                            <Alert severity="success">
                                                <AlertTitle style={{ textAlign: 'start' }}><b><SelfImprovement />Networking</b></AlertTitle>
                                                <hr />
                                                <Typography className='' style={{ textAlign: 'justify' }} >
                                                    A cooling break after lengthy deliberations will offer young people an opportunity to digest the propositions
                                                    and outcomes from the Summit. Day 5 of the Summit will be a day for young people to interact with each other
                                                    and connect and develop channels that will complement personal and professional growth.
                                                </Typography>
                                            </Alert>
                                        </>
                                    ) : null
                                }
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', py: 2 }}>
                                <Button size="small" onClick={handleBackStep} sx={{ mr: 1 }} disabled={activeStepAlt === 0}>
                                    <b>
                                        {theme.direction === 'rtl' ? (
                                            <KeyboardArrowRight />
                                        ) : (
                                            <KeyboardArrowLeft />
                                        )}
                                        Back
                                    </b>
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button
                                    size="small"
                                    onClick={handleNextStep}
                                    sx={{ mr: 1 }}
                                >
                                    <b>
                                        Next
                                        {theme.direction === 'rtl' ? (
                                            <KeyboardArrowLeft />
                                        ) : (
                                            <KeyboardArrowRight />
                                        )}
                                    </b>
                                </Button>
                            </Box>
                        </React.Fragment>
                    </div>
                </Box>
            </div>
            <div className="container mb-4">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <div sx={{ display: 'flex' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify',
                                    // backgroundImage: `url(${waves})`,
                                    // backgroundSize: 'cover',
                                    // backgroundRepeat: 'no-repeat',
                                    // backgroundPosition: 'center'
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <div className='mt-3 d-flex justify-content-center' style={{ width: '100%', color: '#690808' }}>
                                            <h5><b>THIS YEAR'S SPEAKERS</b></h5>
                                        </div>
                                        {speakers && speakers.length > 0 ? (<Speakers speakers={speakers} />) : null}
                                    </CardContent>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className="container mb-4">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <div sx={{ display: 'flex' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify',
                                    // backgroundImage: `url(${waves})`,
                                    // backgroundSize: 'cover',
                                    // backgroundRepeat: 'no-repeat',
                                    // backgroundPosition: 'center'
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <div className='mt-3 d-flex justify-content-center' style={{ width: '100%', color: '#690808' }}>
                                            <h5><b>OUR PARTNERS</b></h5>
                                        </div>
                                        <div className='mt-4 d-flex justify-content-center d-none d-md-block' style={{ width: '100%' }}>
                                            <img src={partners} className='img-fluid animate__animated animate__fadeInLeft' alt="" style={{ width: '100%' }} />
                                        </div>
                                        <div className='mt-4 d-flex justify-content-center d-md-none' style={{ width: '100%' }}>
                                            <img src={partners2} className='img-fluid animate__animated animate__fadeInLeft' alt="" style={{ width: '100%' }} />
                                        </div>
                                    </CardContent>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </div >
    );
}

export default YouLeadSummit24;
