import React, { useEffect } from "react";

const TwitterContainer = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    document.getElementsByClassName("twitter-embed")[0].appendChild(script);
  }, []);
  return (
    <section className="twitterContainer my-2 me-2">
      <div className="twitter-embed">
        <a
          className="twitter-timeline"
          data-theme="dark"
          data-height="400"
          data-chrome="noheader nofooter noborders"
          data-aria-polite="assertive"
          href="https://twitter.com/OneYoungAfrica"
        >
          Tweets by OneYoungAfrica
        </a>
      </div>
    </section>
  );
};

export default TwitterContainer;