import {
    Box,
    MobileStepper,
    useTheme
} from '@mui/material';
import partners1 from 'assets/images/youLeadPartners/partner1.png';
import partners2 from 'assets/images/youLeadPartners/partner2.png';
import partners3 from 'assets/images/youLeadPartners/partner3.png';
import partnersm1 from 'assets/images/youLeadPartners/partnersm1.png';
import partnersm2 from 'assets/images/youLeadPartners/partnersm2.png';
import partnersm3 from 'assets/images/youLeadPartners/partnersm3.png';
import partnersm4 from 'assets/images/youLeadPartners/partnersm4.png';

import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const Partners = () => {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const [activeStepSm, setActiveStepSm] = React.useState(0);
    const maxSteps = 3;
    const maxStepsSm = 4;

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const handleStepChangeSm = (step) => {
        setActiveStepSm(step);
    };

    return (
        <div>
            <Box sx={{ maxWidth: '100%' }} className='d-none d-md-block'>
                <AutoPlaySwipeableViews
                    interval={6000}
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    <img src={partners1} className='img-fluid animate__animated animate__fadeInLeft' alt="" style={{ width: '100%' }} />
                    <img src={partners2} className='img-fluid animate__animated animate__fadeInLeft' alt="" style={{ width: '100%' }} />
                    <img src={partners3} className='img-fluid animate__animated animate__fadeInLeft' alt="" style={{ width: '100%' }} />
                </AutoPlaySwipeableViews>
                <div className='d-flex justify-content-center'>
                    <MobileStepper
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={null}
                        backButton={null}
                    />
                </div>
            </Box>
            <Box sx={{ maxWidth: '100%' }} className='d-md-none'>
                <AutoPlaySwipeableViews
                    interval={6000}
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStepSm}
                    onChangeIndex={handleStepChangeSm}
                    enableMouseEvents
                >
                    <img src={partnersm1} className='img-fluid animate__animated animate__fadeInLeft' alt="" style={{ width: '100%' }} />
                    <img src={partnersm2} className='img-fluid animate__animated animate__fadeInLeft' alt="" style={{ width: '100%' }} />
                    <img src={partnersm3} className='img-fluid animate__animated animate__fadeInLeft' alt="" style={{ width: '100%' }} />
                    <img src={partnersm4} className='img-fluid animate__animated animate__fadeInLeft' alt="" style={{ width: '100%' }} />
                </AutoPlaySwipeableViews>
                <div className='d-flex justify-content-center'>
                    <MobileStepper
                        steps={maxStepsSm}
                        position="static"
                        activeStep={activeStepSm}
                        nextButton={null}
                        backButton={null}
                    />
                </div>
            </Box>
        </div>
    );
}
export default Partners;