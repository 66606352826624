import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getEventReports } from 'helpers/backendHelper'

const initialState = {
    eventReports: [],
    status: 'idle',
    error: null
}

export const eventReportSlice = createSlice({
    name: 'eventReport',
    initialState,
    reducers: {
        eventReportsAdded: {
            reducer(state, action) {
                state.eventReports.push(action.payload)
            },
            prepare(title, content, userId) {
                // omit prepare logic
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchEventReports.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchEventReports.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched eventReports to the array
                state.eventReports = action.payload
            })
            .addCase(fetchEventReports.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

// Action creators are generated for each case reducer function
export const { eventReportsAdded } = eventReportSlice.actions

export default eventReportSlice.reducer

export const selectAllEventReports = state => state.eventReport.eventReports

export const selectEventReportById = (state, eventReportId) =>
    state.eventReport.eventReports.find(eventReport => eventReport.id === eventReportId)

export const fetchEventReports = createAsyncThunk('eventReports/fetchEventReports', getEventReports)