import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, CardMedia, Grid, Typography,
    useMediaQuery, useTheme
} from '@mui/material';
import visionMission from 'assets/images/home/Vision-Mission.png';
import youLeadBackgroundFull from 'assets/images/home/YouLeadBackground.png';
import binoculars from 'assets/images/home/binoculars.svg';
import blocks from 'assets/images/home/blocks.svg';
import graduation from 'assets/images/home/graduation.svg';
import waves from 'assets/images/home/paint.svg';
import whoWeAre from 'assets/images/home/whoWeAre.png';
import YouTubePlayer from 'common/youtubePlayer';
import Partners from 'pages/Partners';
import React, { useState } from 'react';

const Home = () => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
    const isXl = useMediaQuery(theme.breakpoints.up('xl'));

    let width = '100%';  // Default

    if (isXs) {
        width = '100%';
    } else if (isSm) {
        width = '100%';
    } else if (isMd) {
        width = '80%';
    } else if (isLg) {
        width = '70%';
    } else if (isXl) {
        width = '60%';
    }

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    React.useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className="App">
            <Box>
                <Grid container direction="row" spacing={3}>
                    <Grid item md={12} sm={12} xs={12} display={'flex'} justifyContent={'center'}>
                        <Box container className="container mt-5 pt-3" sx={{
                            width: { lg: '75%', md: '90%', sm: '90%', xs: '95%' }, padding: '0px',
                            borderRadius: '8px',
                            boxShadow: '0 0 8px maroon',
                            margin: 'auto',
                        }}>
                            <YouTubePlayer sx={{borderRadius:'20%'}} key={'avPwsW-nsR8'} videoId={'avPwsW-nsR8'} videoPlayList={['avPwsW-nsR8']} autoplay={1} controls={0} mute={true} loop={1} disablekb={1} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {/* <div className="container mt-5 pt-3" >
                <YouTubePlayer key={'avPwsW-nsR8'} videoId={'avPwsW-nsR8'} videoPlayList={['avPwsW-nsR8']} autoplay={1} controls={0} mute={true} loop={1} disablekb={1} />
            </div> */}
            <div className="container mt-1">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <Card sx={{ display: 'flex', justifyContent: 'center', flexWrap: { xs: 'wrap', sm: 'wrap', md: 'wrap', lg: 'nowrap' } }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify',
                                    backgroundImage: `url(${waves})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center'
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <div className='mt-3 d-flex justify-content-start' style={{ width: '100%', color: '#690808' }}>
                                            <h4><b>WHO WE ARE</b></h4>
                                        </div>
                                        <Typography className='mt-3' style={{
                                            textAlign: 'justify'
                                        }} >
                                            We are Africa's flagship Youth Leadership Program working to unlock youth leadership potential for
                                            a prosperous continent by identifying, recognizing, connecting and fostering cooperation among Africa's
                                            vibrant and influential young leaders and their initiatives.
                                        </Typography>
                                        <Typography className='mt-4' style={{ textAlign: 'justify' }} >
                                            YouLead is a joint initiative of MS Training Centre for Development Cooperation (MSTCDC) and the East
                                            African Community (EAC) in Arusha, Tanzania. H.E. Dr. Jakaya Mrisho Kikwete, former President of Tanzania is
                                            YouLead Africa's current Patron.
                                        </Typography>
                                    </CardContent>
                                </Box>
                                <CardMedia
                                    className='pe-3 pt-3 pb-4 d-none d-md-block'
                                    component="img"
                                    sx={{ width: '60%' }}
                                    image={whoWeAre}
                                    alt=""
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <Box>
                <Grid className='my-0' container direction="row" spacing={3} style={{
                    textAlign: 'justify',
                    backgroundImage: `url(${visionMission})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}>
                    <Grid item md={6}></Grid>
                    <Grid item md={6} sm={12}>
                        <div className='my-5 ms-5 d-flex flex-column text-light' >
                            <div>
                                <img src={binoculars} className='img-fluid' style={{ maxWidth: '10%' }} alt="" />
                                <h4 className='mt-2'>YouLead's Vision</h4>
                                <Typography className='me-5' style={{ textAlign: 'justify' }} >
                                    A One Young Africa where dreams are born and youth become Leaders
                                </Typography>
                            </div>
                            <Box className='mt-4' sx={{ display: 'flex', justifyContent: 'start', alignItems: 'end' }}>
                                <img src={graduation} className='img-fluid p-1' style={{ maxWidth: '10%' }} alt="" />
                            </Box>
                            <h4 className='mt-2'>YouLead's Mission</h4>
                            <Typography className='me-5' style={{ textAlign: 'justify' }} >
                                Towards Solidarity, Voice, Participation, Access to Economic Opportunities and Decent work for
                                African Youth
                            </Typography>
                            <Box className='mt-4' sx={{ display: 'flex', justifyContent: 'start', alignItems: 'end' }}>
                                <img src={blocks} className='img-fluid' style={{ maxWidth: '10%' }} alt="" />
                            </Box>
                            <h4 className='mt-2'>YouLead's Values</h4>
                            <Typography className='me-5' style={{ textAlign: 'justify' }} >
                                Apolitical Intentional gender mainstreaming Intersectionality Integrity
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <div className="container">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <div sx={{ display: 'flex' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify',
                                    // backgroundImage: `url(${waves})`,
                                    // backgroundSize: 'cover',
                                    // backgroundRepeat: 'no-repeat',
                                    // backgroundPosition: 'center'
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <div className='mt-3 d-flex justify-content-center' style={{ width: '100%', color: '#690808' }}>
                                            <h5><b>WORD FROM THE PATRON</b></h5>
                                        </div>
                                        <Typography className='mt-3 mx-4' style={{
                                            textAlign: 'center'
                                        }} >
                                            Excellencies, distinguished young people and my dear friends. It is heart-warming for
                                            me to share this welcome message in my capacity as YouLead's Patron. It is indeed, my
                                            singular honour to serve in this capacity.
                                        </Typography>
                                        <Typography className='mt-4 mx-4' style={{ textAlign: 'center' }} >
                                            <b>
                                                H.E. Dr. Jakaya Mrisho Kikwete
                                            </b>
                                            <br />
                                            <b>
                                                Former President of the United Republic of Tanzania
                                            </b>
                                        </Typography>

                                        <div className='mt-4 d-flex justify-content-center' style={{ width: '100%' }}>
                                            <div style={{ width }}>
                                                <YouTubePlayer key={'abhF7JiGSuc'} videoId={'abhF7JiGSuc'} videoPlayList={['abhF7JiGSuc']} autoplay={0} controls={0} mute={false} loop={0} disablekb={1} />
                                            </div>
                                        </div>
                                    </CardContent>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <Box>
                <Grid className='my-0' container direction="row" spacing={3} style={{
                    textAlign: 'justify',
                    backgroundImage: `url(${youLeadBackgroundFull})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    color: 'white'
                }}>
                    <div className='container'>
                        <div className='mt-3 d-flex justify-content-center' style={{ width: '100%', color: 'white' }}>
                            <h5 className='mx-4'><b>PROGRAM PILLARS</b></h5>
                        </div>
                        <Typography className='mt-3 mx-4' style={{
                            textAlign: 'justify'
                        }} >
                            Our work is premised on our passion and apparent need for mobilising, skilling and tooling African
                            young women and men for effective participation in economic and political life.
                        </Typography>
                        <Typography className='mt-4 mx-4' style={{ textAlign: 'justify' }} >
                            Two Program Pillars form the foundations of our work and provide opportunities and entry points for
                            quick wins and long term impact partnerships through our signature multi-stakeholder youth programming
                            and engagement approach.
                        </Typography>
                    </div>
                    <Grid className='my-0 mx-4' container direction="row" spacing={3}>
                        <Grid item md={6} sm={12}>
                            <div className='mb-5'>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className='text-light' />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        sx={{ backgroundColor: '#690808', color: 'white' }}
                                    >
                                        <Typography sx={{ flexShrink: 0 }}>
                                            Pillar 1: Youth in Politics</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Explores models and solutions on youth participation in political life - how youth
                                            leadership in public sector governance can be re-invented and entrenched
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <hr />
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className='text-light' />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        sx={{ backgroundColor: '#690808', color: 'white' }}
                                    >
                                        <Typography sx={{ flexShrink: 0 }}>
                                            Pillar 2: Youth in Business</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Examines the "state of doing business environment" and promotes business policy dialogue
                                            between young businesses leaders and economic policy makers for better policy environment
                                            for youth led enterprises
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <div className='mb-5'>
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className='text-light' />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        sx={{ backgroundColor: '#690808', color: 'white' }}
                                    >
                                        <Typography>
                                            Ambition 1: Youth inclusion and Participation in Political Governance</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Explores models and solutions on youth participation in political life - how youth
                                            leadership in public sector governance can be re-invented and entrenched.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <hr />
                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className='text-light' />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        sx={{ backgroundColor: '#690808', color: 'white' }}
                                    >
                                        <Typography>
                                            Ambition 2: Youth inclusion and Participation in Economic Governance</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Enhance the capacity of youth in business, trade and enterprise to advocate for youth
                                            friendly economic policies and effectively pursue economic opportunities and decent work.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <div className="container mb-4">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <div sx={{ display: 'flex' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify',
                                    // backgroundImage: `url(${waves})`,
                                    // backgroundSize: 'cover',
                                    // backgroundRepeat: 'no-repeat',
                                    // backgroundPosition: 'center'
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <div className='mt-3 d-flex justify-content-center' style={{ width: '100%', color: '#690808' }}>
                                            <h5><b>OUR IMPACT & LEGACY</b></h5>
                                        </div>
                                        <Typography className='mt-3 mx-4' style={{
                                            textAlign: 'center'
                                        }} >
                                            YouLead Summit is YouLead's annual climax event and is now Africa's largest and most
                                            diverse forum of young leaders, mentors, government policy makers, development partners,
                                            private sector, academia, media and civil society from all over Africa to craft
                                            responses to the continent's rapidly growing youth population, their challenges and
                                            opportunities.
                                        </Typography>
                                        <div className='mt-4 d-flex justify-content-center' style={{ width: '100%' }}>
                                            <div style={{ width }}>
                                                <YouTubePlayer key={'0l6s6ymxm7A'} videoId={'0l6s6ymxm7A'} videoPlayList={['0l6s6ymxm7A']} autoplay={0} controls={0} mute={false} loop={0} disablekb={1} />
                                            </div>
                                        </div>
                                    </CardContent>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <Box className='mx-3 mb-5'>
                <Grid container direction="row" spacing={3}>
                    <Grid item md={4} sm={6} xs={12} style={{ width: '100%' }}>
                        <YouTubePlayer key={'Ewz46YPPVlE'} videoId={'Ewz46YPPVlE'} videoPlayList={['Ewz46YPPVlE']} autoplay={0} controls={0} mute={false} loop={0} disablekb={1} />
                        <h5 className='text-center mt-2' style={{ color: '#690808', fontWeight: 'bold' }}>Under 40 Political Leaders Forum 2021</h5>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} style={{ width: '100%' }}>
                        <YouTubePlayer key={'JrDszPhgcJE'} videoId={'JrDszPhgcJE'} videoPlayList={['JrDszPhgcJE']} autoplay={0} controls={0} mute={false} loop={0} disablekb={1} />
                        <h5 className='text-center mt-2' style={{ color: '#690808', fontWeight: 'bold' }}>The Inaugural Arusha Debates 2021</h5>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} style={{ width: '100%' }}>
                        <YouTubePlayer key={'I84Dd3ejozM'} videoId={'I84Dd3ejozM'} videoPlayList={['I84Dd3ejozM']} autoplay={0} controls={0} mute={false} loop={0} disablekb={1} />
                        <h5 className='text-center mt-2' style={{ color: '#690808', fontWeight: 'bold' }}>Under 40 Business Leaders Forum 2021</h5>
                    </Grid>
                </Grid>
            </Box>

            <div className="container mb-4">
                <Box>
                    <Grid container direction="row" spacing={3}>
                        <Grid item md={12} sm={12} style={{ width: '100%' }}>
                            <div sx={{ display: 'flex' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                    textAlign: 'justify'
                                }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <div className='mb-3 d-flex justify-content-center' style={{ width: '100%', color: '#690808' }}>
                                            <h5><b>OUR PARTNERS</b></h5>
                                        </div>
                                        <Partners />
                                    </CardContent>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </div >
    );
}

export default Home;
