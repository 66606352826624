import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getEvents } from 'helpers/backendHelper'

const initialState = {
    events: [],
    status: 'idle',
    error: null
}

export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        eventsAdded: {
            reducer(state, action) {
                state.events.push(action.payload)
            },
            prepare(title, content, userId) {
                // omit prepare logic
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchEvents.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchEvents.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched events to the array
                state.events = action.payload
            })
            .addCase(fetchEvents.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

// Action creators are generated for each case reducer function
export const { eventsAdded } = eventSlice.actions

export default eventSlice.reducer

export const selectAllEvents = state => state.event.events

export const selectEventById = (state, eventId) =>
    state.event.events.find(event => event.id === eventId)

export const fetchEvents = createAsyncThunk('events/fetchEvents', getEvents)