import moment from "moment/moment"

//Function To Convert text  to Title case
export function toTitleCase(str) {
  return str.toString().replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function compareDates(dateStr1, dateStr2) {
  if (dateStr1 === undefined || dateStr1 === null || dateStr1 === '' || dateStr2 === undefined || dateStr2 === null || dateStr2 === '') {
    return 0
  }
  const startDate = new Date(dateStr1);
  const endDate = new Date(dateStr2);

  // Compare the two dates
  if (startDate.getTime() === endDate.getTime()) {
    return 0
  } else if (startDate.getTime() < endDate.getTime()) {
    return -1
  } else {
    return 1
  }
}

//Function To Convert time-stamp date  to human readable form like 2022-02-12 would 12-FEB-2022
export function formatDate(date) {

  return moment(date).format('DD-MMM-YYYY [at] h:mm a')
  // return moment(date).fromNow()
  // return moment().format("Do MMMM YYYY")
  //return moment(date).format("DD-MMM-YYYY").fromNow()
}

export function formatDateOnly(date) {
  return moment(date).format('DD-MMM-YYYY')
}

export function formatTimeOnly(date) {
  return moment(date).format('h:mm a')
}

export function pickColor() {
  // Array containing colors
  var colors = [
    "#782878",
    "#00B998",
    "#FF9500",
    "#D51A52",
    "#7AB800",
    "#0F0BAB",
  ]

  // selecting random color
  var random_color = colors[Math.floor(Math.random() * colors.length)]
  return random_color
}

export function formatNumber(inputNumber) {
  let formetedNumber = Number(inputNumber)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
  let splitArray = formetedNumber.split(".")
  if (splitArray.length > 1) {
    formetedNumber = splitArray[0]
  }
  return formetedNumber
}


//=====Go To next input when Enter Key is pressed and the compoment is inside form tag(allow enter and tab)
export function handleEnter(event) {
  if (event.keyCode === 13 || event.keyCode === 9) {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }
}