import { configureStore } from '@reduxjs/toolkit'
import eventSlice from './event/eventSlice'
import photoGallerySlice from './photoGallery/photoGallerySlice'
import videoGallerySlice from './videoGallery/videoGallerySlice'
import blogSlice from './blog/blogSlice'
import eventReportSlice from './eventReport/eventReportSlice'
import researchPublicationSlice from './researchPublication/researchPublicationSlice'
import summitSlice from './summit/summitSlice'
import newsLetterSubscriptionsSlice from './newsLetterSubscriptions/newsLetterSubscriptionsSlice'
import projectSlice from './project/projectSlice'

export default configureStore({
  reducer: {
    event: eventSlice,
    summit: summitSlice,
    blog: blogSlice,
    photoGallery: photoGallerySlice,
    videoGallery: videoGallerySlice,
    eventReport: eventReportSlice,
    researchPublication: researchPublicationSlice,
    newsLetterSubscriptions: newsLetterSubscriptionsSlice,
    project: projectSlice
  }
})

