import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NearbyError } from '@mui/icons-material';
import { Backdrop, Box, CardContent, CircularProgress, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import visionMission from 'assets/images/home/Page-Heager.png';
import { API_URL } from 'helpers/apiHelper';
import { formatDateOnly } from 'helpers/utilityHelpers';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchProjects, setSelectedProjectById } from 'store/project/projectSlice';

const Projects = () => {
    const projects = useSelector(state => state.project.projects)
    const selectedProject = useSelector(state => state.project.selectedProject)
    const projectStatus = useSelector(state => state.project.status)
    // const error = useSelector(state => state.project.error)
    const [navigating, setNavigating] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate();

    React.useEffect(() => {
        if (projectStatus === 'idle') {
            dispatch(fetchProjects())
        }
    }, [projectStatus, dispatch])

    React.useEffect(() => {
        if (navigating && selectedProject && Object.keys(selectedProject).length > 0) {
            navigate('/project-single');
        }
    }, [selectedProject, navigating])

    React.useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const selectProject = (project) => {
        setNavigating(true);
        dispatch(setSelectedProjectById(project.id));
    }

    return (
        <div className="App">
            <Box>
                <Grid className=' mt-4 py-5' container direction="row" spacing={3} style={{
                    textAlign: 'justify',
                    backgroundImage: `url(${visionMission})`,
                }}>
                    <Grid item md={6}></Grid>
                    <Grid item md={6} sm={12}>
                        <Box className='my-5 ms-5 pb-3 d-flex flex-column text-light' sx={{
                            background: 'linear-gradient(to top, rgba(0,0,0,0.2), rgba(0,0,0,0)), linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 300px)'
                        }}>
                            <h1 className='mt-2 me-5 text-center'>YouLead Projects</h1>
                            <Typography className='me-5' style={{ textAlign: 'center' }} >
                                Get a feel of our impact.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                {projects && projects.length > 0 ? (
                    <Grid className=' mt-2 mb-5' container direction="row" spacing={3}>
                        {projects.map((project, index) => {
                            return (
                                <Grid item lg={4} md={6} sm={12}>
                                    <Card sx={{ maxWidth: '100%', textAlign: 'start' }}>
                                        <CardHeader
                                            action={
                                                <IconButton aria-label="settings" onClick={() => { selectProject(project) }}>
                                                    <FontAwesomeIcon color='#690808' icon={faEye} />
                                                </IconButton>
                                            }
                                            title={<h5 className='' style={{ color: '#690808' }}><b>{project.title}</b></h5>}
                                            subheader={formatDateOnly(project.projectStartDate)}
                                        />
                                        <CardMedia
                                            component="img"
                                            className='px-5'
                                            sx={{
                                                maxHeight: '300px',
                                            }}
                                            image={API_URL + project.image}
                                            alt="project Banner"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="text.secondary truncate"
                                                style={{ lineHeight: 1.5, height: 'calc(16px * 1.5 * 3)', overflow: 'hidden' }}>
                                                <div dangerouslySetInnerHTML={{ __html: project.summary }}></div>
                                            </Typography>
                                            <hr />
                                            <div className='d-flex justify-content-center'>
                                                <button className='btn btn-sm btn-primary' onClick={() => { selectProject(project) }} style={{ backgroundColor: '#690808', borderColor: '#690808' }}>View Project</button>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        })
                        }
                    </Grid>
                ) : (
                    <Card variant="elevation" >
                        <div className="my-5 d-flex flex-column justify-content-center align-items-center">
                            <NearbyError className='mt-5 mb-3' fontSize='large' />
                            <h5><b className="text-danger">No Projects To Show At The Moment.</b></h5>
                        </div>
                    </Card>
                )}
            </Box>

            <Backdrop
                sx={{ color: '#fff', zIndex: 99998 }}
                open={projectStatus === 'loading'}
            >
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <CircularProgress color="inherit" />
                    <span className="mt-2">Please wait...</span>
                </div>
            </Backdrop>
        </div >
    );
}
export default Projects;