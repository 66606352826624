import axios from "axios"

export const API_URL = "https://portal.youlead.africa";

// export const API_URL = "http://localhost:8000";


export const API_URL_EXPORT = API_URL

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.request.use(config => {
//   const token = getAccessToken();
//   if (token && token !== 'Bearer null') {
//     config.headers.Authorization = token;
//   }
  return config;
}, error => {
  return Promise.reject(error);
});

axiosApi.interceptors.response.use(
  response => response,
  error => {
    return Promise.reject(error);
  }
)

export async function getLiveCall(url) {
  return await axios.get(`${url}`).then(response => response.data)
}

export async function postNoAuth(url, data) {
  return await axios.post(`${API_URL}${url}`, data).then(response => response)
}

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}, spreadDataObject = true) {
  return await axiosApi
    .post(url, Array.isArray(data) ? data : (spreadDataObject ? { ...data } : data), { ...config })
    .then(response => response.data)
}