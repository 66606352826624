import { Backdrop, Box, CircularProgress, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import visionMission from 'assets/images/home/Page-Heager.png';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVideos } from 'store/videoGallery/videoGallerySlice';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';
import YouTubePlayer from 'common/youtubePlayer';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const VideoGallery = () => {
    const [expanded, setExpanded] = React.useState({});
    const videos = useSelector(state => state.videoGallery.videos)
    const videoStatus = useSelector(state => state.videoGallery.status)
    // const error = useSelector(state => state.videoGallery.error)
    const dispatch = useDispatch()

    const apiKey = "AIzaSyDg22-aH9AcHjnDCg408Wk7OcRax-dbfIU";
    const channelId = "UCw4-YcWS-Jd-6xjE80kxIdA";

    React.useEffect(() => {
        if (videoStatus === 'idle') {
            dispatch(fetchVideos({ channelId, apiKey }))
        }
    }, [videoStatus, dispatch])

    React.useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <div className="App">
            <Box>
                <Grid className=' mt-4 py-5' container direction="row" spacing={3} style={{
                    textAlign: 'justify',
                    backgroundImage: `url(${visionMission})`,
                }}>
                    <Grid item md={6}></Grid>
                    <Grid item md={6} sm={12}>
                        <Box className='my-5 ms-5 pb-3 d-flex flex-column text-light' sx={{
                            background: 'linear-gradient(to top, rgba(0,0,0,0.2), rgba(0,0,0,0)), linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 300px)'
                        }}>
                            <h1 className='mt-2 me-5 text-center'>YouLead Videos</h1>
                            <Typography className='me-5' style={{ textAlign: 'center' }} >
                                A taste of our Events.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ width: '100%', minHeight: 393 }}>
                    <Masonry className='ps-2' columns={{ xs: 1, sm: 2, md: 3 }} sx={{ width: '100%' }} spacing={2}>
                        {videos && videos.map((video) => (
                            <Item key={video.id.videoId} loading='lazy' >
                                <YouTubePlayer key={video.id.videoId} videoId={video.id.videoId} videoPlayList={[video.id.videoId]} autoplay={0} controls={0} mute={false} loop={0} disablekb={1} />
                            </Item>
                        ))}
                    </Masonry>
                </Box>
            </Box>

            <Backdrop
                sx={{ color: '#fff', zIndex: 99998 }}
                open={videoStatus === 'loading'}
            >
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <CircularProgress color="inherit" />
                    <span className="mt-2">Please wait...</span>
                </div>
            </Backdrop>
        </div>
    );
}
export default VideoGallery;