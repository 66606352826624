// theme.js

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      primary: {
        main: '#690808'
      },
      success: {
        main: '#4caf50'
      },
      warning: {
        main: '#F7941D'
      }
    },
    typography: {
      "fontFamily": `'open sans',Helvetica,Arial,sans-serif`,
      fontSize: 16,
      // h3: {
      //   fontWeight: 700,
      //   fontSize: '2.2rem'
      // },
      // h4: {
      //   fontWeight: 700,
      //   fontSize: '1.75rem'
      // },
      // h5: {
      //   fontWeight: 500
      // },
      // h6: {
      //   fontWeight: 500
      // }
    },
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: "contained" },
            style: {
              // backgroundColor: "primary.main",
              // fontFamily: "Sora",
              // fontWeight: "900",
              // fontSize: "1rem"
              // font
              textTransform: "capitalize",
            }
          },
          {
            props: { variant: "outlined" },
            style: {
              borderColor: "primary.main",
              textTransform: "capitalize",
              // fontSize: "1rem"
              "&.MuiButton-outlined": {
                "&:hover": {
                  backgroundColor: "#690808",
                  color: "white"
                }
              },
              "&.MuiButton-outlinedError": {
                "&:hover": {
                  backgroundColor: "red",
                  color: "white"
                }
              },
            }
          }
        ]
      },
      MuiTab: {
        styleOverrides: {
          
        },
        

      }
    }
  })

  export default theme;
