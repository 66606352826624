import React from "react"
import PropTypes from "prop-types"

const Authmiddleware = ({
  component: Component,
  isAuthProtected,
  layout: Layout,
  ...rest
}) => {

  return (
    <Layout>
      <Component {...rest}/>
    </Layout>
  )
}

Authmiddleware.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
