import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getVideos } from 'helpers/backendHelper'

const initialState = {
    videos: [],
    status: 'idle',
    error: null
}

export const videoGallerySlice = createSlice({
    name: 'videoGallery',
    initialState,
    reducers: {
        videosAdded: {
            reducer(state, action) {
                state.videos.push(action.payload.items)
            },
            prepare(title, content, userId) {
                // omit prepare logic
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchVideos.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchVideos.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched videoGallerys to the array
                state.videos = action.payload.items
            })
            .addCase(fetchVideos.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

// Action creators are generated for each case reducer function
export const { videosAdded } = videoGallerySlice.actions

export default videoGallerySlice.reducer

export const selectAllVideos = state => state.videoGallery.videos

export const fetchVideos = createAsyncThunk('videos/fetchVideos', getVideos)