import { BuildCircleRounded } from '@mui/icons-material'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getSpeakers } from 'helpers/backendHelper'

const initialState = {
    speakers: [],
    status: 'idle',
    error: null
}

export const summitSlice = createSlice({
    name: 'summit',
    initialState,
    reducers: {
        speakersAdded: {
            reducer(state, action) {
                state.speakers.push(action.payload)
            },
            prepare(title, content, userId) {
                // omit prepare logic
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchSpeakers.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchSpeakers.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched summits to the array
                state.speakers = action.payload
            })
            .addCase(fetchSpeakers.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

// Action creators are generated for each case reducer function
export const { summitsAdded } = summitSlice.actions

export default summitSlice.reducer

export const selectAllSpeakers = state => state.summit.speakers

export const selectSpeakerById = (state, speakerId) =>
    state.summit.speakers.find(speaker => speaker.id === speakerId)

export const fetchSpeakers = createAsyncThunk('summit/fetchSpeakers', getSpeakers)