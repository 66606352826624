import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getResearchPublications } from 'helpers/backendHelper'

const initialState = {
    researchPublications: [],
    status: 'idle',
    error: null
}

export const researchPublicationSlice = createSlice({
    name: 'researchPublication',
    initialState,
    reducers: {
        researchPublicationsAdded: {
            reducer(state, action) {
                state.researchPublications.push(action.payload)
            },
            prepare(title, content, userId) {
                // omit prepare logic
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchResearchPublications.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchResearchPublications.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched researchPublications to the array
                state.researchPublications = action.payload
            })
            .addCase(fetchResearchPublications.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

// Action creators are generated for each case reducer function
export const { researchPublicationsAdded } = researchPublicationSlice.actions

export default researchPublicationSlice.reducer

export const selectAllResearchPublications = state => state.researchPublication.researchPublications

export const selectResearchPublicationById = (state, researchPublicationId) =>
    state.researchPublication.researchPublications.find(researchPublication => researchPublication.id === researchPublicationId)

export const fetchResearchPublications = createAsyncThunk('researchPublications/fetchResearchPublications', getResearchPublications)