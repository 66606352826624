
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from "@mui/material";
import { publicRoutes } from 'routes';
import Authmiddleware from 'routes/route';
import theme from 'muiTheme';
import Layout from 'common/layout';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import 'animate.css';

const App = () => {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            {publicRoutes.map((route, idx) => (
              <Route
                key={idx}
                path={route.path}
                element={
                  <Authmiddleware
                    component={route.component}
                    isAuthProtected={false}
                    layout={Layout}
                  />
                }
              />
            ))}
          </Routes>
        </Router>
      </ThemeProvider>
    </React.Fragment>

  );
}

export default App;
