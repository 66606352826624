import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import background1 from 'assets/images/home/mainBackground.svg';
import background2 from 'assets/images/home/mainBackground2.svg';
import Footer from "./Footer";
import Header from "./Header";
import Fab from '@mui/material/Fab';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const Layout = (props) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  console.log(`Location: ${location}`)
  console.log(`IsMobile: ${isMobile}`)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled up to given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);
  const displayContent = () => {
    if (isLoading) {
      return (
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div id="layout-wrapper">
            <Header authorized={true} />
            <div style={{
              textAlign: 'justify',
              backgroundImage: `url(${background1})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'repeat'
            }}>{props.children}</div>
            <Footer />
            {isVisible && (
              <Fab
                color="primary"
                style={{ margin: 0, position: 'fixed', bottom: '40px', right: '40px' }}
                onClick={scrollToTop}
              >
                <ArrowUpwardIcon />
              </Fab>
            )}
          </div>
        </>
      );
    }
  };

  return (
    <React.Fragment>
      {displayContent()}
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  isPreloader: PropTypes.any,
};

export default Layout;
