import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getProjects } from 'helpers/backendHelper'

const initialState = {
    projects: [],
    selectedProject: {},
    status: 'idle',
    error: null
}

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        projectsAdded: {
            reducer(state, action) {
                state.projects.push(action.payload)
            },
            prepare(title, content, userId) {
                // omit prepare logic
            }
        },
        setSelectedProjectById: (state, action) => {
            const projectId = action.payload;
            let project = state.projects.find(project => project.id === projectId);
            state.selectedProject = (project === null ? {} : project);
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchProjects.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchProjects.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched projects to the array
                state.projects = action.payload
            })
            .addCase(fetchProjects.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

// Action creators are generated for each case reducer function
export const { projectsAdded, setSelectedProjectById } = projectSlice.actions

export default projectSlice.reducer

export const selectAllProjects = state => state.project.projects

export const selectProjectById = (state, projectId) =>
    state.project.projects.find(project => project.id === projectId)

export const fetchProjects = createAsyncThunk('projects/fetchProjects', getProjects)
