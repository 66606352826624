import {
    Box,
    Card, CardContent,
    CardMedia, Grid,
    Typography
} from '@mui/material';
import waves from 'assets/images/home/paint.svg';
import icoyaca1 from 'assets/images/projects/icoyaca1.png';
import * as React from 'react';

const ComingSoon = () => {

    React.useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <div className="container mt-5 my-5 pt-5">
            <Box>
                <Grid container direction="row" spacing={3}>
                    <Grid item md={12} sm={12} style={{ width: '100%' }}>
                        <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: { xs: 'wrap', sm: 'wrap', md: 'wrap', lg: 'nowrap' } }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{
                                textAlign: 'justify',
                                backgroundImage: `url(${waves})`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                            }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography variant='h5' className='mt-3' style={{
                                        textAlign: 'center', color: '#690808'
                                    }} >
                                        <b>Content Coming Soon</b>
                                    </Typography>
                                    <Typography variant='span' className='mt-3' style={{
                                        textAlign: 'center', color: '#690808'
                                    }} >
                                        <b>We are working on getting this content to you really soon</b>
                                    </Typography>
                                </CardContent>
                            </Box>
                            <CardMedia
                                className='pe-3 pt-3 pb-4'
                                component="img"
                                sx={{ width: '50%' }}
                                image={icoyaca1}
                                alt=""
                            />
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </div >
    );
}

export default ComingSoon;
