import MenuIcon from '@mui/icons-material/Menu';
import { Card, CardContent, CardMedia, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Popover from '@mui/material/Popover';
import Toolbar from '@mui/material/Toolbar';
import logo from 'assets/images/home/YouLead-Africa-Logo-Transparent.png';
import logo4 from 'assets/images/home/logo-4.png';
import youLeadBackgroundFull from 'assets/images/home/YouLeadBackground.png';
import youLeadBackground from 'assets/images/home/bg-pattern.png';
import logo2 from 'assets/images/home/sub-logo.png';
import whoWeAre from 'assets/images/home/theme-poster-3.jpg';
import waves from 'assets/images/home/paint.svg';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import CloseRounded from '@mui/icons-material/CloseRounded';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjects, setSelectedProjectById } from 'store/project/projectSlice';
import { API_URL } from 'helpers/apiHelper';

const drawerWidth = 240;

const Header = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedNavItemDropDownComponent, setSelectedNavItemDropDownComponent] = useState(<></>);
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  let zIndex = '';  // Default

  if (isXs) {
    zIndex = '';
  } else if (isSm) {
    zIndex = '';
  } else if (isMd) {
    zIndex = 1301;
  } else if (isLg) {
    zIndex = 1301;
  } else if (isXl) {
    zIndex = 1301;
  }

  const container = window !== undefined ? () => window().document.body : undefined;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const projects = useSelector(state => state.project.projects)
  const selectedProject = useSelector(state => state.project.selectedProject)
  const projectStatus = useSelector(state => state.project.status)
  const [navigating, setNavigating] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate();

  React.useEffect(() => {
    if (projectStatus === 'idle') {
      dispatch(fetchProjects())
    }
  }, [projectStatus, dispatch])

  React.useEffect(() => {
    if (navigating && selectedProject && Object.keys(selectedProject).length > 0) {
      navigate('/project-single');
    }
  }, [selectedProject, navigating])

  const selectProject = (project) => {
    setNavigating(true);
    dispatch(setSelectedProjectById(project.id));
  }

  const handleClick = (event, navItem) => {
    setSelectedNavItemDropDownComponent(navItem.dropDownComponent)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'mega-menu-popover' : undefined;

  const YouLeadSummitDropDown = () => {
    return (
      <Card className='px-3' sx={{ display: 'flex' }} style={{
        textAlign: 'justify',
        backgroundImage: `url(${waves})`,
        backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'center'
      }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Grid container direction="row" spacing={3}>
              <Grid item md={7} sm={12} style={{ width: '100%' }}>
                <div className='mt-3 d-flex justify-content-start' style={{ width: '100%', color: '#690808' }}>
                  <h4>
                    <b>Current Summit</b>
                    <hr className='mt-2 mb-0' />
                  </h4>
                </div>
                <Link to="/you-lead-summit" onClick={(e) => navigateAway("/you-lead-summit")}>
                  <Button
                    sx={{ fontSize: 'medium', fontWeight: 'bold' }}
                    className="text-capitalize text-start"
                    onClick={() => {
                      setSelectedMenuItem('YouLead Summit', '/you-lead-summit');
                      handleClose()
                      setMobileOpen(false)
                    }}
                  >
                    2024 YouLead Summit
                  </Button>
                </Link>
                <Typography className='mt-2 me-2' style={{ textAlign: 'justify' }} >
                  Africa's largest and most diverse youth forum co-hosted by the East African Community and MS Training Centre for Development
                  Cooperation under the Patronage of H.E. Jakaya Mrisho Kikwete returns under the theme <b>Igniting the Potential of Africa's
                    Youthful Population: Reinstating Education as an Eminent Public Good.</b>
                </Typography>
              </Grid>
              <Grid item md={5} sm={12} style={{ width: '100%' }}>
                <div className='mt-3 d-flex justify-content-start' style={{ width: '100%', color: '#690808' }}>
                  <h4>
                    <b>Past Summits</b>
                    <hr className='mt-2 mb-0' />
                  </h4>
                </div>
                <div className='d-flex flex-column align-items-start' style={{ width: '100%', color: '#690808' }}>
                  <Link to="/you-lead-summit-23" onClick={(e) => navigateAway("/you-lead-summit-23")}>
                    <Button
                      sx={{ fontSize: 'medium', fontWeight: 'bold' }}
                      className="text-capitalize text-start"
                      onClick={() => {
                        setSelectedMenuItem('YouLead Summit', '/you-lead-summit-23');
                        handleClose()
                        setMobileOpen(false)
                      }}
                    >
                      2023 YouLead Summit
                    </Button>
                  </Link>
                  <Link to="/you-lead-summit-22" onClick={(e) => navigateAway("/you-lead-summit-22")}>
                    <Button
                      sx={{ fontSize: 'medium', fontWeight: 'bold' }}
                      className="text-capitalize text-start"
                      onClick={() => {
                        setSelectedMenuItem('YouLead Summit', '/you-lead-summit-22');
                        handleClose()
                        setMobileOpen(false)
                      }}
                    >
                      2022 YouLead Summit
                    </Button>
                  </Link>
                  <Link to="/coming-soon" onClick={(e) => navigateAway("/coming-soon")}>
                    <Button
                      sx={{ fontSize: 'medium', fontWeight: 'bold' }}
                      className="text-capitalize text-start"
                      onClick={() => {
                        setSelectedMenuItem('YouLead Summit', '/coming-soon');
                        handleClose()
                      }}
                    >
                      2021 YouLead Summit
                    </Button>
                  </Link>
                  <Link to="/coming-soon" onClick={(e) => navigateAway("/coming-soon")}>
                    <Button
                      sx={{ fontSize: 'medium', fontWeight: 'bold' }}
                      className="text-capitalize text-start"
                      onClick={() => {
                        setSelectedMenuItem('YouLead Summit', '/coming-soon');
                        handleClose()
                      }}
                    >
                      2020 YouLead Summit
                    </Button>
                  </Link>
                  <Link to="/coming-soon" onClick={(e) => navigateAway("/coming-soon")}>
                    <Button
                      sx={{ fontSize: 'medium', fontWeight: 'bold' }}
                      className="text-capitalize text-start"
                      onClick={() => {
                        setSelectedMenuItem('YouLead Summit', '/coming-soon');
                        handleClose()
                      }}
                    >
                      2019 YouLead Summit
                    </Button>
                  </Link>
                </div>
              </Grid>
            </Grid>
          </CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={logo4} className='img-fluid' style={{ maxHeight: '85px' }} alt="" />
          </Box>
        </Box>
        <CardMedia
          className='px-5 pt-5 pb-3 d-none d-md-block text-center'
          component="img"
          sx={{ width: '30%' }}
          style={{ borderRadius: '20%' }}
          image={whoWeAre}
          alt=""
        />
      </Card>
    )
  }

  const YouLeadInterractiveDropDown = () => {
    return (
      <Card className='px-3' sx={{ display: 'flex' }} style={{
        textAlign: 'justify',
        backgroundImage: `url(${waves})`,
        backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'center'
      }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Grid container direction="row" spacing={3}>
              <Grid item md={7} sm={12} style={{ width: '100%' }}>
                <div className='mt-3 d-flex justify-content-start' style={{ width: '100%', color: '#690808' }}>
                  <h4>
                    {/* <b>Documents</b> */}
                    <hr className='mt-2 mb-0' />
                  </h4>
                </div>
                <div className='d-flex flex-column align-items-start' style={{ width: '100%', color: '#690808' }}>
                  <Link to="/interactive-reports" onClick={(e) => navigateAway("/interactive-reports")}>
                    <Button
                      sx={{ fontSize: 'medium', fontWeight: 'bold' }}
                      className="text-capitalize text-start"
                      onClick={() => {
                        setSelectedMenuItem('Publications & Media', '/interactive-reports');
                        handleClose()
                        setMobileOpen(false)
                      }}
                    >
                      Event Reports
                    </Button>
                  </Link>
                  <Link to="/interactive-publications" onClick={(e) => navigateAway("/interactive-publications")}>
                    <Button
                      sx={{ fontSize: 'medium', fontWeight: 'bold' }}
                      className="text-capitalize text-start"
                      onClick={() => {
                        setSelectedMenuItem('Publications & Media', '/interactive-publications');
                        handleClose()
                        setMobileOpen(false)
                      }}
                    >
                      Research Publications
                    </Button>
                  </Link>
                </div>
              </Grid>
              <Grid item md={5} sm={12} style={{ width: '100%' }}>
                <div className='mt-3 d-flex justify-content-start' style={{ width: '100%', color: '#690808' }}>
                  <h4>
                    <hr className='mt-2 mb-0' />
                  </h4>
                </div>
                <div className='d-flex flex-column align-items-start' style={{ width: '100%', color: '#690808' }}>
                  <Link to="/interactive-blogs" onClick={(e) => navigateAway("/interactive-blogs")}>
                    <Button
                      sx={{ fontSize: 'medium', fontWeight: 'bold' }}
                      className="text-capitalize text-start"
                      onClick={() => {
                        setSelectedMenuItem('Publications & Media', '/interactive-blogs');
                        handleClose()
                        setMobileOpen(false)
                      }}
                    >
                      YouLead Blog
                    </Button>
                  </Link>
                  {/* <Button
                    sx={{ fontSize: 'medium', fontWeight: 'bold' }}
                    className="text-capitalize text-start"
                  >
                    Opportunity Desk
                  </Button> */}
                  <Link to="/interactive-events" onClick={(e) => navigateAway("/interactive-events")}>
                    <Button
                      sx={{ fontSize: 'medium', fontWeight: 'bold' }}
                      className="text-capitalize text-start"
                      onClick={() => {
                        setSelectedMenuItem('Publications & Media', '/interactive-events');
                        handleClose()
                        setMobileOpen(false)
                      }}
                    >
                      YouLead Events
                    </Button>
                  </Link>
                  <Link to="/interactive-photos" onClick={(e) => navigateAway("/interactive-photos")}>
                    <Button
                      sx={{ fontSize: 'medium', fontWeight: 'bold' }}
                      className="text-capitalize text-start"
                      onClick={() => {
                        setSelectedMenuItem('Publications & Media', '/interactive-photos');
                        handleClose()
                        setMobileOpen(false)
                      }}
                    >
                      Photo Gallery
                    </Button>
                  </Link>
                  <Link to="/interactive-videos" onClick={(e) => navigateAway("/interactive-videos")}>
                    <Button
                      sx={{ fontSize: 'medium', fontWeight: 'bold' }}
                      className="text-capitalize text-start"
                      onClick={() => {
                        setSelectedMenuItem('Publications & Media', '/interactive-videos');
                        handleClose()
                        setMobileOpen(false)
                      }}
                    >
                      Video Gallery
                    </Button>
                  </Link>
                </div>
              </Grid>
            </Grid>
          </CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={logo4} className='img-fluid' style={{ maxHeight: '85px' }} alt="" />
          </Box>
        </Box>
        <CardMedia
          className='px-5 pt-5 pb-3 d-none d-md-block text-center'
          component="img"
          sx={{ width: '30%' }}
          style={{ borderRadius: '20%' }}
          image={whoWeAre}
          alt=""
        />
      </Card>
    )
  }

  const YouLeadProjectsAndEntitiesDropDown = useCallback(() => {
    return (
      <Card className='px-3' sx={{ display: 'flex' }} style={{
        textAlign: 'justify',
        backgroundImage: `url(${waves})`,
        backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'center'
      }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Grid container direction="row" spacing={3}>
              <Grid item md={7} sm={12} style={{ width: '100%' }}>
                <div className='mt-3 d-flex justify-content-start' style={{ width: '100%', color: '#690808' }}>
                  <h4>
                    <b>Projects <Link to="/projects" onClick={(e) => navigateAway("/projects")}> <button className='btn btn-sm btn-primary'
                      onClick={() => {
                        setSelectedMenuItem('Projects & Affiliated Entities', '/projects');
                        handleClose()
                      }} style={{ backgroundColor: '#690808', borderColor: '#690808' }}>View All</button></Link></b>
                    <hr className='mt-2 mb-0' />
                  </h4>
                </div>
                <div className='d-flex flex-column align-items-start' style={{ width: '100%', color: '#690808' }}>
                  {projects && projects.length > 0 ? (
                    projects.map((project, index) => {
                      if (index < 5) {
                        return (
                          <Link to="#" key={'project' + index}>
                            <Button
                              sx={{ fontSize: 'medium', fontWeight: 'bold' }}
                              className="text-capitalize text-start"
                              onClick={() => {
                                selectProject(project)
                              }}
                            >
                              {project.title}
                            </Button>
                          </Link>
                        )
                      }
                    })
                  ) : null}
                </div>
              </Grid>
              <Grid item md={5} sm={12} style={{ width: '100%' }}>
                <div className='mt-3 d-flex justify-content-start' style={{ width: '100%', color: '#690808' }}>
                  <h4>
                    <b>Affiliated Entities</b>
                    <hr className='mt-2 mb-0' />
                  </h4>
                </div>
                <div className='d-flex flex-column align-items-start' style={{ width: '100%', color: '#690808' }}>
                  <Link to="/entities-icoyaca" onClick={(e) => navigateAway("/entities-icoyaca")}>
                    <Button
                      sx={{ fontSize: 'medium', fontWeight: 'bold' }}
                      className="text-capitalize text-start"
                      onClick={() => {
                        setSelectedMenuItem('Projects & Affiliated Entities', '/entities-icoyaca');
                        handleClose()
                      }}
                    >
                      Independent Continental Youth Advisory Council (AfCFTA-ICOYACA)
                    </Button>
                  </Link>
                  <Link to="/entities-afybc" onClick={(e) => navigateAway("/entities-afybc")}>
                    <Button
                      sx={{ fontSize: 'medium', fontWeight: 'bold' }}
                      className="text-capitalize text-start"
                      onClick={() => {
                        setSelectedMenuItem('Projects & Affiliated Entities', '/entities-afybc');
                        handleClose()
                      }}
                    >
                      Afrikan Youth Business Council (AfYBC)
                    </Button>
                  </Link>
                  <Link to="/entities-eaypn" onClick={(e) => navigateAway("/entities-eaypn")}>
                    <Button
                      sx={{ fontSize: 'medium', fontWeight: 'bold' }}
                      className="text-capitalize text-start"
                      onClick={() => {
                        setSelectedMenuItem('Projects & Affiliated Entities', '/entities-eaypn');
                        handleClose()
                      }}
                    >
                      East African Youth Peace Network (EAYPN)
                    </Button>
                  </Link>
                </div>
              </Grid>
            </Grid>
          </CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={logo4} className='img-fluid' style={{ maxHeight: '85px' }} alt="" />
          </Box>
        </Box>
        <CardMedia
          className='px-5 pt-5 pb-3 d-none d-md-block text-center'
          component="img"
          sx={{ width: '30%' }}
          style={{ borderRadius: '20%' }}
          image={(projects && projects.length > 0 ? API_URL + projects[0].image : whoWeAre)}
          alt=""
        />
      </Card>
    )
  }, [projects])

  const DropDownContent = ({ component }) => {
    return (
      <>
        {component}
      </>
    )
  }
  const navArr = [
    {
      name: 'Home',
      path: '/',
      isDropDown: false,
      active: true
    },
    {
      name: 'YouLead Summit',
      path: '#',
      isDropDown: true,
      dropDownComponent: <YouLeadSummitDropDown />,
      active: false
    },
    {
      name: 'Publications & Media',
      path: '#',
      isDropDown: true,
      dropDownComponent: <YouLeadInterractiveDropDown />,
      active: false
    },
    {
      name: 'Projects & Affiliated Entities',
      path: '#',
      isDropDown: true,
      dropDownComponent: <YouLeadProjectsAndEntitiesDropDown />,
      active: false
    },
    // {
    //   name: 'Partners',
    //   path: '/partners',
    //   isDropDown: false,
    //   active: false
    // },
    // {
    //   name: 'The Team',
    //   path: '/team',
    //   isDropDown: false,
    //   active: false
    // }, 
    {
      name: 'Community',
      path: 'https://portal.youlead.africa',
      isDropDown: false,
      active: false
    }
  ]
  const [navItems, setNavItems] = useState(navArr)

  useEffect(() => {
    if (projects && projects.length > 0) {
      setNavItems(navArr)
    }
  }, [projects])

  useEffect(() => {
    let arr = navItems.map(item => {
      if (item.path === location.pathname) {
        item.active = true;
      } else {
        if (item.path === '#') {
          if (location.pathname.toLowerCase().includes('summit') && item.name.toLowerCase().includes('summit')) {
            item.active = true;
          } else if (location.pathname.toLowerCase().includes('interactive') && item.name.toLowerCase().includes('media')) {
            item.active = true;
          } else if ((location.pathname.toLowerCase().includes('project') || location.pathname.toLowerCase().includes('entities')) && item.name.toLowerCase().includes('project')) {
            item.active = true;
          } else if (location.pathname.toLowerCase().includes('entities') && item.name.toLowerCase().includes('entities')) {
            item.active = true;
          } else {
            item.active = false;
          }
        } else {
          item.active = false
        }
      }
      return item;
    })
    setNavItems(arr)
  }, [location])

  const setSelectedMenuItem = (selectedMenuItem, path) => {
    if (path !== '#') {
      let arr = navItems.map(item => {
        if (item.name === selectedMenuItem) {
          item.active = true;
        } else {
          item.active = false
        }
        return item;
      })
      setNavItems(arr)
    }
  }

  const navigateAway = (path) => {
    if (mobileOpen && path !== '#') {
      setMobileOpen(false)
    }
  };

  const drawer = (
    <Box sx={{ textAlign: 'center' }}>
      <div className='d-flex flex-column justify-content-between align-items-start' style={{
        height: '100vh',
        backgroundImage: `url(${youLeadBackgroundFull})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        zIndex
      }}>
        <div style={{ width: '100%' }}>
          <img src={logo} className='img-fluid text-center mb-3' style={{ maxHeight: '64px' }} alt="" />
          <Divider />
          <List>
            {navItems.map((item) => (
              <NavLink to={item.path} key={item.name} style={{ textDecoration: 'none' }}
                className="d-flex flex-column justify-content-start align-items-start">
                <Button
                  sx={{
                    color: '#fff',
                    fontSize: 'medium',
                    borderBottom: item.active ? '3px solid white' : 'initial'
                  }}
                  className="text-capitalize ms-1 mb-2 pb-0"
                  onClick={item.isDropDown ? (event) => {
                    setSelectedMenuItem(item.name, item.path)
                    handleClick(event, item)
                  } : (event) => {
                    setSelectedMenuItem(item.name, item.path)
                    handleDrawerToggle()
                  }}
                  onMouseOver={item.isDropDown ? (event) => {
                    handleClick(event, item)
                  } : handleClose
                  }
                >
                  {item.name}
                </Button>
              </NavLink>
            ))}
          </List>
        </div>
        <div style={{ width: '100%' }}>
          <Divider />
          <img src={logo2} className='img-fluid text-center' alt="" />
        </div>
      </div>
    </Box >
  );
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav" color="primary" style={{
        textAlign: 'center',
        backgroundImage: `url(${youLeadBackground})`,
        width: '100%', zIndex
      }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <div className='d-flex justify-content-between align-items-center' style={{ width: '100%' }}>
            <img src={logo} className='img-fluid' style={{ maxHeight: '64px' }} alt="" />
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
              {navItems.map((item) => (
                <NavLink to={item.path} key={item.name}
                >
                  <Button
                    sx={{
                      color: '#fff',
                      fontSize: 'medium',
                      borderBottom: item.active ? '3px solid white' : 'initial'
                    }}
                    className="text-capitalize me-2 pb-0"
                    onClick={item.isDropDown ? (event) => {
                      setSelectedMenuItem(item.name, item.path)
                      handleClick(event, item)
                    } : (event) => {
                      setSelectedMenuItem(item.name, item.path)
                    }}
                    onMouseOver={item.isDropDown ? (event) => {
                      handleClick(event, item)
                    } : handleClose
                    }
                  >
                    {item.name}
                  </Button>
                </NavLink>
              ))}
            </Box>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              onMouseLeave={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box style={{ width: '100vw' }}>
                <DropDownContent component={selectedNavItemDropDownComponent} />
                <IconButton onClick={handleClose} size="small" style={{ position: 'absolute', right: '8px', bottom: '8px', color: 'white' }}>
                  <CloseRounded fontSize="small" style={{ backgroundColor: '#690808', borderRadius: '50%' }} />
                </IconButton>
              </Box>
            </Popover>
          </div>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { md: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#690808' }
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Header;